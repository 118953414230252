import { Box, Typography } from "@mui/material";
import Business from "assets/contact/business.svg";
import ContactIcon from 'assets/icon/icon _call.svg';
import LocationIcon from 'assets/icon/icon_location.svg';
import EmailIcon from 'assets/icon/icon_sms.svg';
import UserIcon from 'assets/icon/icon_user.svg';
import ScrapIcon from 'assets/icon/scrap_delete.svg';
import TextInput from "components/Ui/TextInput/TextInput";
import _ from 'lodash';
import uuid4 from "uuid4";
import Styles from "./Overview.module.scss";
import QuoteTotal from "./QuoteTotal";
import { getFullAddress, getSafeValue } from "utils";

const ArchitecturalOverview = ({ job, contact, fromLongQuote = false }: any) => {
  const {
    services,
    building_type,
    service_type
  } = job;

  const { address, name, phone_number, email, company } = contact;

  let price: any = 0.00;

  services.forEach((service: any) => {
    price = service.discount ? (service.discount_price ? service.discount_price : 0.00) : service.price;
    price = price.toFixed(2)
  });

  return <>
    {fromLongQuote !== true &&
      <Box mt={3} className={Styles.contacInfo}>
        <Typography variant="subTitle1" >Contact Information</Typography>

        <Box py={3} className={Styles.flexWrapper}>
          <Box className={Styles.contactInfoWrapper}>
            <img src={UserIcon} alt='' />
            <Typography variant="title2" className={Styles.contactDetail}>{name}</Typography>
          </Box>

          {company &&
            <Box className={Styles.contactInfoWrapper}>
              <img src={Business} alt='' />
              <Typography variant="title2" className={Styles.contactDetail}>{company}</Typography>
            </Box>
          }

          <Box className={Styles.contactInfoWrapper}>
            <img src={ContactIcon} alt='' />
            <Typography variant="title2" className={Styles.contactDetail}>{phone_number !== null ? phone_number : '-'}</Typography>
          </Box>

          <Box className={Styles.contactInfoWrapper}>
            <img src={EmailIcon} alt='' />
            <Typography variant="title2" className={Styles.contactDetail}>{email !== null ? email : '-'}</Typography>
          </Box>
        </Box>

        {getSafeValue(contact, 'address', false) && 
          <Box className={Styles.flexWrapper}>
            <Box className={Styles.contactInfoWrapper}>
              <img src={LocationIcon} alt='' />
              <Typography variant="title2" className={Styles.contactDetail}>{getFullAddress(address)}</Typography>
            </Box>
          </Box>}
      </Box>}

    {service_type === 'Installation' &&
      <Box className={Styles.jobDetail}>
        <Box className={fromLongQuote === true ? Styles.jobDetailHeaderLongQuote : Styles.jobDetailHeader}>
          <Box className={Styles.jobTitle}>
            <Typography variant="subtitle1" className={Styles.subTitle1}>{building_type}</Typography>
            <Typography variant="subtitle2" className={Styles.subTitle2}>{service_type}</Typography>
          </Box>
          {fromLongQuote === true && <Typography variant="subtitle2" className={Styles.titleJobId}>Job #{job.id}</Typography>}
        </Box>

        <Box className={Styles.jobServiceType}>
          {services.map((service: any) => (<Box key={uuid4()}>
            {service.type === "Architectural Window Tinting" && <>
              <Typography variant="subtitle1" className={Styles.service_type}>Architectural Window Tinting</Typography>
              <Box className={Styles.jobServiceInfo}>
                <Typography variant="subtitle1" className={Styles.removalWrapper}>
                  {service.total_windows} Windows - {service.total_sqft} SQFT
                  {service.film_removal_option && <img src={ScrapIcon} alt='' />}
                </Typography>

                {service.film_type === "Kepler Window Film" ? (
                  <Typography variant="subtitle2" className={Styles.serviceFilm}>
                    {_.get(service, 'film_options[0].name', '')} {_.get(service, 'film_options[0].film_shade', '')}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" className={Styles.serviceFilm}>{service.film_type}</Typography>
                )}
              </Box>
            </>
            }
            {service.type === "Other" &&
              <Typography variant="subtitle1" className={Styles.service_type}>Other Service</Typography>
            }
          </Box>))}

          <QuoteTotal
            job={job}
            fromLongQuote={fromLongQuote}
          />
        </Box>
      </Box>
    }

    {service_type === 'Consultation' &&
      <Box className={Styles.jobDetail}>
        <Box className={fromLongQuote === true ? Styles.jobDetailHeaderLongQuote : Styles.jobDetailHeader}>
          <Box className={Styles.jobTitle}>
            <Typography variant="subtitle1" className={Styles.subTitle1}>
              {building_type} Project ({service_type})
            </Typography>
            {fromLongQuote === true && <Typography variant="subtitle2" className={Styles.titleJobId}>Job #{job.id}</Typography>}
          </Box>
        </Box>
        <Box className={Styles.jobServiceType}>
          {services.map((service: any) => (
            <Box key={`service_consultation_${service.id}`}>
              <Typography variant="title2" fontWeight={590}>Notes ({service.type})</Typography>
              <TextInput
                value={service?.notes?.text}
                name='notes'
                margin='dense'
                fullWidth
                multiline
                disabled
                minRows={2}
              />
            </Box>))}
        </Box>
      </Box>
    }
  </>
};

export default ArchitecturalOverview;