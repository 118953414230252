import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DefaultPaginationState from './defaultPaginationState';

const initialState = {
  ...DefaultPaginationState(),
  films: []
};

export const filmReducer = createSlice({
  name: "film",
  initialState,
  reducers: {
    getFilmsRequest: (state, action: PayloadAction<any>) => {
      state.films = action.payload;
      state.loading = false;
      state.errors = null;
      state.success = true;
    },
  },
});

export const { getFilmsRequest } = filmReducer.actions;

export default filmReducer.reducer;
