/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import ContactAPI from 'api/resources/contact';
import UserAPI from "api/resources/user";
import addIconBlue from "assets/add_square_blue.svg";
import SelectDropdown from 'components/FormInputs/Select/Index';
import InnerLoader from "components/Loading/InnerLoader";
import { useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { checkIncompleteAddress, getFullAddress, getSafeValue } from 'utils';
import Style from "./ProjectAddress.module.scss";

const AddressDropdown = ({ handleUpdateLocation, location, contact_id = '', is_mobile }: any) => {
  const [scheduleAddress, setScheduleAddress] = useState<any>(location)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [addNewAddress, setAddNewAddress] = useState<any>(false)
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(location.id ? location.id : (is_mobile ? getFullAddress(location) : 'Workshop'));
  const [allLocations, setAllLocations] = useState<any>([]);

  useEffect(() => {
    getLocation()
  }, [])

  const handleLocationUpdate = (location: any) => {
    setScheduleAddress(location)
    handleUpdateLocation(location)
  }

  const handleAddressChange = async (name: any, value: any) => {
    let location
    if (name === 'state_province') {
      if (value === null) {
        setCities([])
      } else {
        await getCities(value)
      }
      location = { ...scheduleAddress, [name]: value, city: '', mobile_install: true }
      setScheduleAddress(location)
    } else {
      location = { ...scheduleAddress, [name]: value, mobile_install: true }
      setScheduleAddress(location)
    }
    handleLocationUpdate(location)
  }

  const getLocation = async () => {
    setIsLoading(true)
    const storeLocations: any = await UserAPI.getBusinessLocations(contact_id)
    const allStoreLocations = [{
      name: (location.id || is_mobile) ? 'Customer Address' : 'Workshop',
      address_id: location.id ? location.id : (is_mobile ? getFullAddress(location) : 'Workshop'),
      address: location,
      organisation_id: location.organisation_id
    }, ...storeLocations?.data]

    setAllLocations(allStoreLocations)
    setIsLoading(false)

    const options: any = allStoreLocations.map((location: any) => ({
      value: location.address_id,
      label: (location.address_id === 'Workshop' || location.address_id === getFullAddress(location)) ? location.address_id : `(${location.name}) ${getFullAddress(location.address)}`,
    }));
    options.push({
      label: (
        <Box display={'flex'} gap={'5px'}>
          <img src={addIconBlue} alt="Add Icon" />
          <Typography variant='linkText'>Add New Contact Address</Typography>
        </Box>
      ),
      value: "new",
    });

    setLocationOptions(options);
  }

  const handleLocationChange = (event: any) => {
    setSelectedAddress(event.target.value)
    if (event.target.value === 'new') {
      setAddNewAddress(true)
    } else {
      setAddNewAddress(false)
      const location = allLocations.find((location: any) => location.address_id === event.target.value);
      setScheduleAddress(location?.address)
      handleLocationUpdate({ ...location?.address, mobile_install: location.name === 'Customer Address' })
    }
  }

  useEffect(() => {
    getState()
  }, [])

  const [state, setState] = useState([])
  const getState = async () => {
    const allState = await ContactAPI.getState()
    setState(allState.data)
  }

  const [cities, setCities] = useState([])
  const getCities = async (state: any) => {
    const allState = await ContactAPI.getCity(state)
    setCities(allState.data)
  }

  return (
    <Box>
      {isLoading && <InnerLoader height="auto" />}
      <Box className={Style.event}>
        <SelectDropdown
          className={Style.selectLocation}
          name="location"
          options={locationOptions}
          value={selectedAddress}
          onChange={handleLocationChange}
          renderValue={(value: any) => {
            let data
            let label
            let completeAddress = true
            if (value === "new") {
              data = 'Add Address'
              label = 'Add Address'
            } else if (value === "workshop") {
              data = 'Workshop'
              label = 'Workshop'
            } else {
              const location = locationOptions.find((location: any) => location.value === value);
              const locationAddress = allLocations.find((location: any) => location.address_id === value);
              completeAddress = checkIncompleteAddress(locationAddress?.address)
              console.log(locationAddress?.address, completeAddress)
              data = location?.label.length > 50 ? location?.label?.substring(0, 50 - 3) + '...' : location?.label
              label = location?.label
            }
            return <Box
              display={'flex'}
              alignItems={'center'}
              padding={'0 !important'}
              textTransform={'capitalize'}
              gap={'10px'}
              title={label}
            ><HiOutlineLocationMarker size={25} />{data} {completeAddress === false && <Typography variant="linkText" fontSize={'12px'} onClick={() => setAddNewAddress(true)}>Add Full Address</Typography>}</Box>
          }}
        />
        {addNewAddress &&
          <Grid container spacing={2} className={Style.customerInfoForm} mt={'2px'}>
            <Grid item xs={12}>
              <Typography variant="title1" fontWeight={600}>Add Address</Typography>
              <TextField
                fullWidth
                placeholder="Street Address"
                name="street_address"
                onChange={(e: any) => handleAddressChange(e.target.name, e.target.value)}
                value={scheduleAddress?.street_address}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                placeholder="2nd Line"
                name="second_line"
                onChange={(e: any) => handleAddressChange(e.target.name, e.target.value)}
                value={scheduleAddress?.second_line}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={state}
                getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                onChange={(_, value: any) => handleAddressChange('state_province', getSafeValue(value, 'name', value))}
                value={getSafeValue(scheduleAddress, 'state_province', '')}
                isOptionEqualToValue={(option: any, value: any) => option.name === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={Style.InputBorder}
                    size="small"
                    placeholder='State'
                  />
                )}
                fullWidth
                freeSolo
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={cities}
                getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                onChange={(_, value: any) => handleAddressChange('city', getSafeValue(value, 'name', value))}
                value={getSafeValue(scheduleAddress, 'city', '')}
                isOptionEqualToValue={(option: any, value: any) => option.name === value}
                renderInput={(params) => (
                  <TextField
                    className={Style.InputBorder}
                    {...params}
                    size="small"
                    placeholder='City'
                  />
                )}
                fullWidth
                freeSolo
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                placeholder="Zip"
                name="zipcode"
                onChange={(e: any) => handleAddressChange(e.target.name, e.target.value)}
                value={scheduleAddress?.zipcode}
              />
            </Grid>
          </Grid>
        }
      </Box>
    </Box>
  );
};

export default AddressDropdown;
