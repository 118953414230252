import { themeColor } from "./color";
export const themePalette = () => ({
  primary: {
    main: themeColor.primary,
  },
  secondary: {
    main: themeColor.secondary,
  },
  error: {
    main: themeColor.error,
  },
});
