import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectTabs } from "redux/reducers/leadProfileReducer";

export default function ArchitecturalProfile() {
  const formTabs = useAppSelector((state: any) => state.leadProfile.formsTabs);
  const activeTab = useAppSelector((state: any) => state.leadProfile.activeTab);
  const [screen, setScreen] = useState("desktop");
  const dispatch = useAppDispatch();
  const [serviceSelect, setServiceSelect] = useState("all");

  const handleClick = (item: any) => {
    dispatch(selectTabs(item.key));
  };

  useEffect(() => {
    dispatch(selectTabs("architectural"));
  }, []);
  return <div>ArchitecturalProfile</div>;
}
