import DatePicker from "react-datepicker";
import Style from "./CustomDatePicker.module.scss";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = (props: any) => {
  return ( <DatePicker 
    className={Style.datepicker} 
    {...props} 
    popperPlacement="bottom-start"
  />
  );
};


export default CustomDatePicker;