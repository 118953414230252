import { Typography } from "@mui/material";

type OptionData = {
    error: string;
};

function ErrorMessage({ error }: OptionData) {
    return error ? <Typography
        variant="title4"
        color="error"
        textAlign="start"
        display="block"
    >{error}</Typography> : <></>
}

export default ErrorMessage;
