import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import iconInvoiceSetting from 'assets/icon_invoice_setting.svg'
import iconInvoiceCustomization from 'assets/icon_invoice_customization.svg'

interface IFWorkState {
  meta: any;
  refresh: any;
  invoiceTabs: any;
  activeTab: any;
  members: any,
  userType: any
}

const initialState: IFWorkState = {
  refresh: false,
  meta: null,
  activeTab: { name: "Invoice Settings", key: 0, selected: false, image: null },
  invoiceTabs: [
    { name: "Invoice Settings", key: 0, selected: false, image: iconInvoiceSetting },
    { name: "Invoice Customization", key: 1, selected: false, image: iconInvoiceCustomization },
  ],
  members: {},
  userType: []
};

export const invoiceReducer = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      const key = action.payload;
      const index = state.invoiceTabs.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.invoiceTabs[index];
        state.invoiceTabs = state.invoiceTabs.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.invoiceTabs[0];
        state.invoiceTabs[0].selected = true;
      }
    }
  },
});

export const { 
  refreshRequest, 
  selectTabs
} = invoiceReducer.actions;

export default invoiceReducer.reducer;
