import apiClient from '../client';

export default class QuickQuoteAPI {
  static list(filters = {}) {
    return apiClient.get('/quick-quotes', {
      params: {
        ...filters
      }
    });
  }

  static listSnackCase(filters = {}) {
    return apiClient.get('/quick-quotes', {
      params: {
        ...filters,
        is_snake_case: 1
      }
    });
  }

  static create(data: {}) {
    return apiClient.post('/quick-quotes?is_snake_case=true', data);
  }

  static update(id: number, data: {}) {
    return apiClient.post(`/quick-quotes/${id}/projects?is_snake_case=true`, data);
  }

  static listNotes(id: number) {
    return apiClient.get(`/quick-quotes/${id}/notes`);
  }

  static createNote(id: number, data: {}) {
    return apiClient.post(`/quick-quotes/${id}/notes`, data);
  }

  static updateNote(quoteId: number, noteId: number, data: {}) {
    return apiClient.put(`/quick-quotes/${quoteId}/notes/${noteId}`, data);
  }

  static book(id: number, data: {}) {
    return apiClient.post(`/quick-quotes/${id}/appointments`, data);
  }

  static deposit(id: number, data: {}) {
    return apiClient.post(`/quick-quotes/${id}/deposit`, data);
  }

  static getAppointment(id: number) {
    return apiClient.get(`/quick-quotes/${id}/appointments`);
  }

  static createProposal(id: number, data: {}) {
    return apiClient.post(`/quick-quote/${id}/proposals-send`, data);
  }

  static complete(id: number) {
    return apiClient.post(`/quick-quotes/${id}/complete`, { bookAppointments: true });
  }

  static deleteRoom(project_id: any, room_id: any) {
    return apiClient.delete(`/projects/${project_id}/measurement-groups/${room_id}`);
  }

  static getQuickQuoteProject(project_id: any) {
    return apiClient.get(`/projects/${project_id}/get-project`);
  }
}
