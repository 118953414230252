/* eslint-disable react-hooks/exhaustive-deps */
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Typography } from '@mui/material';
import LocationIcon from 'assets/icon/icon_location.svg';
import AssigneeDropdown from "components/AssigneeDropdown/AssigneeDropdown";
import CustomDatePicker from "components/CustomDatePicker";
import CustomTimeRangePicker from 'components/CustomTimeRangePicker';
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import _ from 'lodash';
import moment from 'moment';
import ProjectAddress from 'pages/SchedulePages/ScheduleAppointment/ProjectAddress/Index';
import { useEffect, useState } from 'react';
import { HiOutlineChevronDown } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import uuid4 from 'uuid4';
import ArchitecturalOverview from './ArchitecturalOverview';
import AutomotiveOverview from './AutomotiveOverview';
import Styles from "./ScheduleAppointment.module.scss";
import Warning from 'components/Modal/WarningModal/Warning';
import { getFullAddress, getSafeValue } from 'utils';
import AddressDropdown from 'pages/SchedulePages/ScheduleAppointment/ProjectAddress/AddressDropdown';

function ScheduleAppointment({
  state,
  handleUpdate,
  handleAppointmentUpdate,
  resetQuickQuoteModel,
  appointment,
  fromLongQuote = false,
  validationError,
  isPastDateAllowed,
  setIsPastDateAllowed,
  handleScheduleAppointment
}: any) {
  const scheduleBooking = state;
  const { jobs, contact } = scheduleBooking;
  const navigate = useNavigate();
  const [openProjectLocationModal, setOpenProjectLocationModal] = useState<boolean>(false)
  const [appointmentData, setAppointmentData] = useState(appointment)
  const isMobileJob = getSafeValue(scheduleBooking, 'jobs[0].mobile_install', false)

  const handleChange = (name: any, value: any) => {
    handleAppointmentUpdate(name, value);
  };

  const onTimeChange = (name: string, value: any) => {
    handleAppointmentUpdate(name, moment(value, 'hh:mm A').format('YYYY-MM-DDTHH:mm'))
  }

  useEffect(() => {
    if ((jobs[0]?.type === 'Architectural' && jobs[0]?.service_type === 'Consultation') || isMobileJob === true) {
      setAppointmentData({ ...appointment, location: _.get(contact, 'address', {}) });
    } else {
      setAppointmentData(appointment);
    }
  }, [appointment]);

  const handleViewCalendar = () => {
    resetQuickQuoteModel();
    navigate(`/schedule/calendar?project_id=${jobs[0]?.id}`)
  }

  const handleProjectLocation = (data: any) => {
    handleUpdate(0, 'mobile_install', data.mobile_install)
    handleAppointmentUpdate('location', data.location);
  }

  const handleAppointmentChange = (address: any) => {
    handleUpdate(0, 'mobile_install', getSafeValue(address, 'mobile_install', false))
    handleAppointmentUpdate('location', address);
  }

  return (
    <>
      {fromLongQuote === false ?
        <Box className={Styles.scheduleWrapper} mt={2}>
          <Box className={Styles.dialogContentCardHeader} display={'flex'} justifyContent={'space-between'}>
            <Typography variant="subTitle1" >Schedule Appointment</Typography>
            <Button className={Styles.calendarLink} onClick={handleViewCalendar}>
              View Calendar
              <KeyboardArrowRightIcon />
            </Button>
          </Box>
          <Box className={Styles.scheduleTiming}>
            <Box className={Styles.scheduleDuration}>
              <CustomDatePicker
                selected={new Date(appointmentData.date)}
                dateFormat="dd MMM yyyy"
                onChange={(e: any) => handleChange('date', moment(e).format('YYYY-MM-DDTHH:mm'))}
                minDate={new Date(appointmentData.date)}
              />
              <Box marginLeft={'16px'}>
                <CustomTimeRangePicker
                  startTime={appointmentData.start_time}
                  endTime={appointmentData.end_time}
                  onStartTimeChange={(time: any) => onTimeChange('start_time', time)}
                  onEndTimeChange={(time: any) => onTimeChange('end_time', time)}
                />
                <ErrorMessage error={_.get(validationError, ['startsAt'], '')} />
                <ErrorMessage error={_.get(validationError, ['endsAt'], '')} />
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <AssigneeDropdown teamMember={appointmentData?.assignees}
                  onChange={(value: any) => handleChange('assignees', value)} />
                <ErrorMessage error={_.get(validationError, ['assignees["0"]'], '')} />
              </Box>
            </Box>
          </Box>

          <Box className={Styles.flexWrapper}>
            <AddressDropdown
              handleUpdateLocation={(location: any) => {
                handleAppointmentChange(location)
              }}
              location={getSafeValue(appointmentData, 'location', {})}
              contact_id={contact.id}
              is_mobile={isMobileJob}
            />
          </Box>
        </Box> : <Box>
          <Box className={Styles.dialogContentCardHeader}>
            <Typography variant="subTitle1">Schedule Appointment</Typography>
          </Box>
          <Box display={'flex'} justifyContent={'start'} gap={'8px'} marginTop={'18px'}>
            <Box display={'flex'} flexDirection={'column'}>
              <AssigneeDropdown teamMember={appointmentData?.assignees} onChange={(value: any) => handleChange('assignees', value)} marginLeft={'0'} />
              <ErrorMessage error={_.get(validationError, ['assignees["0"]'], '')} />
            </Box>
            <CustomDatePicker
              selected={new Date(appointmentData.date)}
              dateFormat="dd MMM yyyy"
              onChange={(e: any) => handleChange('date', moment(e).format('YYYY-MM-DDTHH:mm'))}
              minDate={new Date(appointmentData.date)}
            />
            <Box display={'flex'} flexDirection={'column'}>
              <CustomTimeRangePicker
                startTime={appointmentData.start_time}
                endTime={appointmentData.end_time}
                onStartTimeChange={(time: any) => onTimeChange('start_time', time)}
                onEndTimeChange={(time: any) => onTimeChange('end_time', time)}
              />
              <ErrorMessage error={_.get(validationError, ['startsAt'], '')} />
              <ErrorMessage error={_.get(validationError, ['endsAt'], '')} />
            </Box>
            <Button className={Styles.calendarLink} onClick={handleViewCalendar}>
              View Calendar
              <KeyboardArrowRightIcon />
            </Button>
          </Box>
          <Box display={'flex'} marginTop={'8px'}>
            <Box
              className={Styles.appointmentLocation}
              sx={{
                padding: '12px',
                color: '#1F1F1F',
                fontSize: '14px',
                fontWeight: '590',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minWidth: '390px',
                cursor: 'pointer',
                flex: 1
              }}
              maxWidth={'100% !important'}
              onClick={() => setOpenProjectLocationModal(true)}
            >
              <Box display={'flex'} alignItems={'center'} gap={'15px'}>
                <img src={LocationIcon} alt="location icon" />
                {isMobileJob === true ? getFullAddress(getSafeValue(appointmentData, 'location', false)) : 'Workshop Location'}
                {/* {appointmentData?.location?.sameAsContactAddress === true ? 'Same As Customer Address' : (appointmentData?.location?.street_address ? getFullAddress(appointmentData?.location) : 'Workshop Location')} */}
              </Box>
              <HiOutlineChevronDown />
            </Box>
          </Box>
        </Box>}

      {jobs.map((job: any, index: number) =>
        <div key={uuid4()}>
          {job.type === 'Automotive' &&
            <AutomotiveOverview job={job} contact={contact} index={index} fromLongQuote={fromLongQuote} />
          }
          {job.type === 'Architectural' &&
            <ArchitecturalOverview job={job} contact={contact} index={index} fromLongQuote={fromLongQuote} />
          }
        </div>
      )}
      <Warning
        title="Warning"
        content="You are booking for a date that has already passed. Are you sure you want to book this job for a past date?"
        open={isPastDateAllowed}
        close={() => setIsPastDateAllowed(false)}
        handle={() => handleScheduleAppointment(false)}
        darkButton={true}
      />
      {openProjectLocationModal === true &&
        <ProjectAddress
          onPopupClose={() => setOpenProjectLocationModal(false)}
          handleUpdateLocation={handleProjectLocation}
          location={appointmentData?.location}
          contact_id={contact.id}
        />}
    </>
  );
}

export default ScheduleAppointment;
