import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DefaultPaginationState from './defaultPaginationState';

const initialState = {
  ...DefaultPaginationState(),
  makes: [],
  models: [],
  years: [],
  carTypes: [
    'None',
    '2 Door Hatchback',
    '4 Door Hatchback',
    '2 Door Sedan',
    '4 Door Sedan',
    '2 Door Convertible',
    '4 Door Convertible',
    'Super Car',
    'SUV',
    'Pick Up Truck',
    'Van / Truck',
    'Mini Van',
    'Mini Bus',
    'Campervan'
  ]
};

export const carDetailsReducer = createSlice({
  name: "carDetails",
  initialState,
  reducers: {
    getCarMakesRequest: (state, action: PayloadAction<any>) => {
      state.makes = action.payload;
      state.loading = false;
      state.errors = null;
      state.success = true;
    },
    getCarModelsRequest: (state, action: PayloadAction<any>) => {
      state.models = action.payload;
      state.loading = false;
      state.errors = null;
      state.success = true;
    },
    getCarYearsRequest: (state, action: PayloadAction<any>) => {
      state.years = action.payload;
      state.loading = false;
      state.errors = null;
      state.success = true;
    },
  },
});

export const { getCarMakesRequest, getCarModelsRequest, getCarYearsRequest } = carDetailsReducer.actions;

export default carDetailsReducer.reducer;
