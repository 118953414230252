import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function AddServiceIcon(props) {
  return (
    <SvgIcon {...props} viewBox='0 0 22 24'>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.46094 11.7656H13.8498
         M6.46094 15.9883H11.0843
         M8.57205 5.22222H12.7943C14.9054 5.22222 14.9054 4.16667 14.9054 3.11111C14.9054 1 13.8498 1 12.7943 1H8.57205C7.51649 1 6.46094 1 6.46094 3.11111C6.46094 5.22222 7.51649 5.22222 8.57205 5.22222Z
         M14.9019 3.13086C18.4169 3.32086 20.1797 4.61919 20.1797 9.44306V15.7764C20.1797 19.9986 19.1241 22.1097 13.8464 22.1097H7.51302C2.23524 22.1097 1.17969 19.9986 1.17969 15.7764V9.44306C1.17969 4.62975 2.94247 3.32086 6.45747 3.13086"
        strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default AddServiceIcon;
