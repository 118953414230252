import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function OverviewIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 30">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.47656 23.3236L9.69929 25.8186C10.1151 26.2344 11.0507 26.4423 11.6745 26.4423H15.6249C16.8724 26.4423 18.2239 25.5067 18.5357 24.2592L21.0307 16.6702C21.5505 15.2148 20.6149 13.9673 19.0555 13.9673H14.8972C14.2734 13.9673 13.7537 13.4475 13.8576 12.7198L14.3774 9.39314C14.5853 8.45751 13.9616 7.41793 13.0259 7.10606C12.1943 6.79418 11.1547 7.21002 10.7389 7.83376L6.47656 14.1752
      M1.17969 23.3232V13.1354C1.17969 11.6799 1.80344 11.1602 3.25885 11.1602H4.29843C5.75385 11.1602 6.3776 11.6799 6.3776 13.1354V23.3232C6.3776 24.7787 5.75385 25.2985 4.29843 25.2985H3.25885C1.80344 25.2985 1.17969 24.7787 1.17969 23.3232Z
      M12.1797 1L12.1797 4
      M6.17969 3L8.0386 5.35467
      M17.9375 2.86719L16.1817 5.29972"
        stroke="#757575" fill="#fff" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default OverviewIcon;
