import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

interface EventState {
  calendarEvents: any
  calendarTask: any
}

const initialState: EventState = {
  calendarEvents: [],
  calendarTask: [],
};

export const eventReducer = createSlice({
  name: "events",
  initialState,
  reducers: {
    eventRequest: (state, action: PayloadAction<any>) => {
      state.calendarEvents = action.payload.events;
      state.calendarTask = action.payload.tasks;
    },
    updateEvent: (state, action: PayloadAction<any>) => {
      const { payload } = action;

      const { id } = payload.data;
      state.calendarEvents = state.calendarEvents.map((event: any) => (
        (event.id === id)
          ? {
            ...event,
            start: moment(payload.data.starts_at).toDate(),
            end: moment(payload.data.ends_at).toDate()
          } : event
      ));
    }
  }
});

export const {
  eventRequest,
  updateEvent
} = eventReducer.actions;

export default eventReducer.reducer;
