import { Avatar, Badge, Box, Button, useMediaQuery } from "@mui/material";
import styles from "./Header.module.scss";
import { themeColor } from "theme/color";
import { styled, useTheme } from "@mui/material/styles";
import { constant } from "utils/constant";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { closeMenu, toggle, openMenu } from "redux/reducers/uiReducer";
import UserMenu from "./UserMenu";
import UnreadLeads from "./UnreadLeads";
import Notifications from "./Notifications";
import QuickQuoteDialog from "../../pages/QuickQuote";
import { useEffect, useState } from "react";
import LeadApi from "api/resources/leads";
import AuthAPI from "api/resources/auth";
import { HasPermission } from "components/HasPermission/HasPermission";
import { toast } from "react-toastify";

const HeaderButton = styled(Button)(({ theme }) => ({
  color: themeColor.black,
  backgroundColor: themeColor.white,
  borderRadius: "15px",
  padding: "10px 23px",
  fontSize: "14px",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: themeColor.gray,
    color: themeColor.white,
  },
}));
const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("lg"));
  const [unRead, setunRead] = useState([]);
  const [notification, setNotification] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [unreadLeadCount, setUnreadLeadCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    dispatch(toggle());
  };
  useEffect(() => {
    if (media) {
      dispatch(closeMenu());
    } else {
      dispatch(openMenu());
    }
  }, [media]);

  const handleNavigation = async (id: number, projectId: number) => {
    await LeadApi.markRead(projectId);
    setRefresh(!refresh);
    navigate(`/leads/contacts/projects/project/${projectId}/service?id=${id}`)
  };

  useEffect(() => {
    (async () => {
      try {
        const unReadResponse = await LeadApi.getUnreadLeads("");
        const notificationResponse = await AuthAPI.getNotifications();
        setUnreadLeadCount(unReadResponse?.data?.meta?.total || 0)
        setunRead(unReadResponse?.data?.data || []);
        setNotification(notificationResponse?.data?.data || []);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [refresh]);

  const clearNotifications = async () => {
    try {
      setLoading(true)
      await AuthAPI.clearNotifications();
      toast.success("All Notifications Cleared!");
      setNotification([])
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      toast.error("Error in Notifications Clear");
    }
  }

  return (
    <Box className={`${styles.header} header`} sx={{ height: constant.height }}>
      <Box
        className={styles.logoBox}
        sx={{ minWidth: constant.sideMenu, height: constant.height }}
      >
        <Link to="/">
          <img
            width={41}
            src="/assets/images/logo/kepler-logo.png"
            alt="logo"
          />
        </Link>
      </Box>
      <Box className={styles.headerInner}>
        <Box className={styles.headerLeft}>
          <Box className={styles.menuIcon} onClick={() => handleToggle()}>
            <img
              width={34}
              src="/assets/images/icons/menu.svg"
              alt="menu icon"
            />
          </Box>
          <HasPermission permission={['enable_quick_quoting']}>
            <QuickQuoteDialog />
          </HasPermission>
        </Box>
        <Box className={styles.headerRight}>
          <Box
            className={styles.headerIcon}
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <UnreadLeads data={unRead} handleNavigation={handleNavigation} unreadCount={unreadLeadCount} />
            <Notifications data={notification} clearNotifications={clearNotifications} />
          </Box>
          <UserMenu />
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
