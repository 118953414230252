import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface CounterState {
  contactId: any;
  entities: any;
  refresh: any;
  make: any;
  models: any;
  bodyType: any;
  year: any;
  projectTypes: any;
}

const initialState: CounterState = {
  contactId: null,
  entities: null,
  refresh: false,
  make: [],
  models: [],
  bodyType: [],
  year: [],
  projectTypes: [],
};

export const newProjectReducer = createSlice({
  name: "newProject",
  initialState,
  reducers: {
    handleProjectTypes: (state, action: PayloadAction<any>) => {
      const value = action.payload.map((item: any, index: any) => ({
        key: item.toLowerCase(),
        value: item,
      }));
      state.projectTypes = value;
    },
    handleMakes: (state, action: PayloadAction<any>) => {
      const value = action.payload.map((item: any, index: any) => ({
        key: item.id,
        value: item.make,
      }));
      state.make = value;
    },
    handleModels: (state, action: PayloadAction<any>) => {
      const value = action.payload.map((item: any, index: any) => ({
        key: item.id,
        value: item.model,
      }));
      state.models = value;
    },
    handleYear: (state, action: PayloadAction<any>) => {
      console.log("Value", action.payload);
      const value = action.payload.map((item: any, index: any) => ({
        key: item.id,
        value: item.year,
      }));
      state.year = value;
    },
    handleBodyType: (state, action: PayloadAction<any>) => {
      const value = action.payload.data.map((item: any, index: any) => ({
        key: item.id,
        value: item.value,
      }));
      state.bodyType = value;
    },
  },
});

export const {
  handleProjectTypes,
  handleMakes,
  handleModels,
  handleBodyType,
  handleYear,
} = newProjectReducer.actions;

export default newProjectReducer.reducer;