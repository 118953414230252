import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFTaskState {
  meta: any;
  refresh: any;
  tasks: any;
}

const initialState: IFTaskState = {
  refresh: false,
  meta: null,
  tasks: {},
};

export const tasksReducer = createSlice({
  name: "task",
  initialState,
  reducers: {
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    setTasks: (state, action: PayloadAction<any>) => {
      state.tasks = action.payload
    }
  }
});

export const { refreshRequest, setTasks } = tasksReducer.actions;

export default tasksReducer.reducer;
