import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DefaultPaginationState from './defaultPaginationState';

const initialState = {
  ...DefaultPaginationState(),
  users: []
};

export const userReducer = createSlice({
  name: "users",
  initialState,
  reducers: {
    getAllUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload.data.map((member: any) => ({
        value: member.id,
        label: `${member.first_name} ${member.last_name}`,
        icon: member.profile_image,
      }));
      state.loading = false;
      state.errors = null;
      state.success = true;
    },
  },
});

export const { getAllUsers } = userReducer.actions;

export default userReducer.reducer;
