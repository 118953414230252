import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { changeBgColor } from "redux/reducers/uiReducer";
import Style from "./LeadProfile.module.scss";
import { getSafeValue } from "utils";

function ProfileLayout() {
  const dispatch = useAppDispatch();
  const color = useAppSelector((state) => state.leadProfile.primaryColor);
  const publicData = useAppSelector((state) => state.leadProfile.publicData);
  const navigate = useNavigate();
  const isPath = true

  const [projectData, setProjectData] = useState<any>({
    status: 'Active',
    created_at: '04.02.23',
    name: 'Honda Accord (2019)',
    type: 'Automotive Window Tinting',
    contact_name: 'Cliff Richard'
  })
  useEffect(() => {
    dispatch(changeBgColor("#f6f6f6"));
  }, []);

  useEffect(() => {
    setProjectData({
      name: `${getSafeValue(publicData, 'project.type_details.make', '---')} ${getSafeValue(publicData, 'project.type_details.model', '---')} (${getSafeValue(publicData, 'project.type_details.year', '---')})`,
      contact_name: getSafeValue(publicData, 'contact.name', '')
    })
  }, [publicData]);

  return (
    <Box>
      <Box className={Style.header}>
        <Container className={Style.flexContainer}>
          <Box className={Style.headFlex}>
            {isPath && (
              <>
                <Box
                  className={`${Style.flexBox} cursor`}
                  onClick={() => navigate(-1)}
                >
                  <LiaAngleLeftSolid color="#fff" />
                  <Typography className={Style.backText}>Back</Typography>
                </Box>
                <Box>
                  <Typography className={Style.titleHead} color={color}>
                    {projectData.name}{" "}
                  </Typography>
                  <Typography className={Style.userName} color={color}>
                    {projectData.contact_name}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box>
            <img src="/assets/images/leadProfile/logo.png" alt="Logo" />
          </Box>
        </Container>
      </Box>
      <Container className={Style.containerHeight}>
        <Box className={Style.container}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
}

export default ProfileLayout;
