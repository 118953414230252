/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, FormControlLabel, Grid, Typography } from '@mui/material';
import ContactAPI from 'api/resources/contact';
import LockIcon from 'assets/icon_lock.svg';
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import _ from 'lodash';
import SuggestedContactPopup from 'pages/QuickQuote/SuggestedContactPopup';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { refreshRequest } from 'redux/reducers/contactReducer';
import { setContact, updateContact, updateQuoteJob } from 'redux/reducers/quickQuoteReducer';
import { getQuickQuote } from 'redux/selectors/quickQuoteSelector';
import { getSafeValue } from 'utils';
import BpCheckbox from "../../../components/Ui/CheckBox/BpCheckbox";
import TextInput from "../../../components/Ui/TextInput/TextInput";
import { Roles } from '../../../utils/constant';
import styles from "./Contact.module.scss";

interface contactDetailsProps {
  errors: any;
}

function ContactDetails({ errors: validationError }: contactDetailsProps) {
  const [suggestedContacts, setSuggestedContacts] = useState<any>(null);
  const [suggestedContact, setSuggestedContact] = useState<any>(null);
  const dispatch = useAppDispatch();

  const quickQuote: any = useAppSelector((state) => getQuickQuote(state));
  const { jobs, contact } = quickQuote

  const [address, setAddress] = useState(false);

  const phoneNumber = getSafeValue(contact, 'phone_number', '')
  const phoneNumberSmsNotification = getSafeValue(contact, 'phone_number_receive_sms', '')
  const receiveEmail = getSafeValue(contact, 'receive_email', '')
  const email = getSafeValue(contact, 'email', '')

  const job = jobs[0]
  const role = useAppSelector((state: any) => state.auth.entities?.role);
  const isGoldMember = Roles.GoldAdmin === role;
  const mobile_install = getSafeValue(job, 'mobile_install', false)

  useEffect(() => {
    setAddress(job.type === 'Architectural');
    getState()
  }, []);

  const handleChange = (e: any, isAddress: any = false) => {
    if (isAddress === true) {
      const newAddress = { ...contact.address, [e.target.name]: e.target.value }
      dispatch(updateContact({ name: 'address', value: newAddress }))
      return
    }
    const { name, value } = e.target
    dispatch(updateContact({ name, value }))
  };

  const handleAddressChange = async (name: string, value: any) => {
    let newAddress = { ...contact.address, [name]: value }
    if (name === 'state_province') {
      newAddress = { ...newAddress, country: _.get(contact, 'address.country', 'United States') };
      if (value === null) {
        setCities([])
      } else {
        await getCities(value)
      }
    }
    dispatch(updateContact({ name: 'address', value: newAddress }))
  };

  const onSelectContactChange = (event: any) => {
    const selectedContact = suggestedContacts.find((item: any) => item.id === event.target.value);
    setSuggestedContact(selectedContact);
    dispatch(updateContact({ name: 'address', value: _.get(selectedContact, 'address', {}) }))
  };

  const handleUseContact = () => {
    const contact = {
      ...suggestedContact,
      first_name: suggestedContact.first_name,
      last_name: suggestedContact.last_name,
      discoveries: suggestedContact.discovery,
      address: _.get(suggestedContact, 'address', {})
    };
    dispatch(setContact(contact))
    dispatch(updateQuoteJob({
      index: 0,
      name: 'address',
      value: _.get(suggestedContact, 'address', {})
    }))
    setSuggestedContact(null);
  };

  const handleSuggest = async () => {
    dispatch(refreshRequest());
    const params: any = {
      page: 1,
      per_page: 10,
      filter: {}
    };

    if (email) {
      params['filter']['search_email'] = email
    }

    if (phoneNumber) {
      params['filter']['search_phone_number'] = phoneNumber
    }

    const contact_id = _.get(contact, 'id', false)
    if (contact_id !== false) {
      params.contact_id = contact_id
    }

    if (email || phoneNumber) {
      try {
        const response = await ContactAPI.list(params, 'contacts')
        setSuggestedContacts(response?.data?.data);
        if (response?.data?.data.length > 0) {
          setSuggestedContact(getSafeValue(response, 'data.data[0]', null));
          const address = _.defaultTo(_.get(response?.data?.data, '[0].address', {}), {})
          dispatch(updateContact({ name: 'address', value: address }))
        }
        dispatch(refreshRequest());
      } catch (error) {
        console.log({ error });
        dispatch(refreshRequest());
      }
    } else {
      dispatch(refreshRequest());
    }
  };

  const handleCheckBoxChecked = (name: any, checked: any) => {
    dispatch(updateContact({ name, value: checked }))
  }

  const [state, setState] = useState([])
  const getState = async () => {
    const allState = await ContactAPI.getState()
    setState(allState.data)
  }

  const [cities, setCities] = useState([])
  const getCities = async (state: any) => {
    const allState = await ContactAPI.getCity(state)
    setCities(allState.data)
  }

  return (
    <Box className={styles.sectionWrappers}>
      <Typography variant='subTitle1'>
        Quote Delivery Information
      </Typography>
      <Grid container spacing={1} className={styles.textFieldWrapper}>
        <Grid item xs={6}>
          <TextInput
            onChange={handleChange}
            value={phoneNumber}
            name="phone_number"
            placeholder="Phone Number"
            margin="dense"
            size="small"
            variant="outlined"
            className={styles.input}
            fullWidth
          />
          <ErrorMessage error={_.get(validationError, ['contact.phone_number'], '')} />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            onChange={handleChange}
            value={email}
            name="email"
            placeholder="Email Address"
            size="small"
            margin="dense"
            variant="outlined"
            onBlur={handleSuggest}
            className={styles.input}
            fullWidth
          />
          <ErrorMessage error={_.get(validationError, ['contact.email'], '')} />
        </Grid>

        {(mobile_install === true && !address) &&
          <Grid item xs={6}>
            <TextInput
              onChange={(e) =>
                handleAddressChange(e.target.name, e.target.value)
              }
              value={_.get(contact, 'address.city', '')}
              name="city"
              placeholder="Mobile Install City"
              size="small"
              margin="dense"
              className={styles.textField2}
              fullWidth
            />
          </Grid>
        }
      </Grid>

      <Grid pt={1} container spacing={1} className={styles.textFieldWrapper}>
        <Grid item xs={6}>
          <FormControlLabel
            control={<BpCheckbox checked={phoneNumberSmsNotification}
              value={phoneNumberSmsNotification}
              disabled={!isGoldMember}
              onChange={e => handleCheckBoxChecked('phone_number_receive_sms', e.target.checked)}
              className={!isGoldMember ? styles.roleCheckbox : ''}
            />}
            label={
              <div className={styles.selectBoxIcon}>
                {!isGoldMember && <img src={LockIcon} alt='' />}
                <span>Receive SMS</span>
              </div>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={<BpCheckbox checked={receiveEmail}
              value={receiveEmail}
              onChange={(e: any) => handleCheckBoxChecked('receive_email', e.target.checked)} />}
            label='Receive Email'
          />
        </Grid>
      </Grid>

      <Grid pt={1} container spacing={1} className={styles.textFieldWrapper}>
        <Grid item xs={6}>
          <FormControlLabel
            control={<BpCheckbox
              checked={address}
              value={address}
              onChange={(e: any) => setAddress(e.target.checked)} />}
            label='Add Address'
          />
        </Grid>
      </Grid>

      {(address === true) && (
        <Box pt={2}>
          <Typography variant='subTitle1'>
            Address
          </Typography>
          <Grid container spacing={1} className={styles.textFieldWrapper}>
            <Grid item xs={12}>
              <TextInput
                size="small"
                margin="dense"
                value={_.get(contact, 'address.street_address', '')}
                onChange={(e) =>
                  handleAddressChange(e.target.name, e.target.value)
                }
                name="street_address"
                placeholder="Street Address"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                size="small"
                margin="dense"
                value={_.get(contact, 'address.second_line', '')}
                onChange={(e) =>
                  handleAddressChange(e.target.name, e.target.value)
                }
                name="second_line"
                placeholder="2nd Line"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={state}
                getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                onChange={(_, value: any) => handleAddressChange('state_province', getSafeValue(value, 'name', value))}
                value={getSafeValue(contact, 'address.state_province', '')}
                isOptionEqualToValue={(option: any, value: any) => option.name === value}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    size="small"
                    placeholder='State'
                    margin="dense"
                  />
                )}
                fullWidth
                freeSolo
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={cities}
                getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                onChange={(_, value: any) => handleAddressChange('city', getSafeValue(value, 'name', value))}
                value={getSafeValue(contact, 'address.city', '')}
                isOptionEqualToValue={(option: any, value: any) => option.name === value}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    size="small"
                    placeholder='City'
                    margin="dense"
                  />
                )}
                fullWidth
                freeSolo
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                size="small"
                margin="dense"
                value={_.get(contact, 'address.zipcode', '')}
                onChange={(e) =>
                  handleAddressChange(e.target.name, e.target.value)
                }
                name="zipcode"
                placeholder="Zip"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <SuggestedContactPopup
        setSuggestedContact={setSuggestedContact}
        suggestedContact={suggestedContact}
        suggestedContacts={suggestedContacts}
        onSelectContactChange={onSelectContactChange}
        handleUseContact={handleUseContact}
        buttonText="Merge Lead"
      />
    </Box>
  );

}

export default ContactDetails;
