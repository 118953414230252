/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unsafe-optional-chaining */
import { values } from "lodash";
import moment from "moment";
import { createSelector } from "reselect";
import { getSafeValue } from "utils";
// Select entities from state
export const getContacts = (state: any) => state.contact.entities;
export const getContactsState = (state: any) => state.contact;
export const getViewContactState = (state: any) => state.viewContact;

//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

const formateDate = (date: any) => {
  if (date == null) {
    return "";
  }

  return moment(date).format("MMM DD, h:mma");
};

export const getContactInfo = createSelector(getContacts, (contacts) =>
  values(contacts).map((contact) => ({
    id: contact.id,
    name: `${contact.first_name || '--'} ${contact.last_name || '--'}`,
    customer: {
      name: `${contact.first_name || '--'} ${contact.last_name || '--'}`,
      date: formateDate(contact.created_at),
    },
    info: {
      phone_number: contact.phone_number,
      email: contact.email,
    },
    location: {
      type: contact.project && (getSafeValue(contact, 'project.is_mobile', false)) ? "Mobile" : "Workshop",
      location: contact.address,
    },
    projectType: contact.project?.type,
    TotalSpend: contact.total_spent,
    recent: {
      status: contact.last_tag_status,
      acquisition: contact.discovery,
    },
    action: {
      id: contact.id,
      projectId: contact.project_id,
    },
  }))
);

export const getContactMeta = createSelector([getContactsState, getViewContactState], (contact, viewContact) => {
  return {
    meta: contact.meta,
    refresh: viewContact.refresh
  }
})

export const getContactRefresh = createSelector(getContactsState, (contact) => {
  return {
    refresh: contact.refresh
  }
})