import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface CounterState {
  entities: any;
  meta: any;
  refresh: any;
  activeTab: any;
  queue: any;
}

const initialState: CounterState = {
  entities: null,
  meta: null,
  refresh: false,
  activeTab: null,
  queue: null,
};

export const campaignsReducer = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    campaignsRequest: (state, action: PayloadAction<any>) => {
      state.entities = action.payload;
    },
    queueRequest: (state, action: PayloadAction<any>) => {
      state.queue = action.payload.data;
      state.meta = action.payload.meta;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    deleteQueue: (state, action: PayloadAction<any>) => {
      state.queue = state.queue.filter((queue: any) => queue.id !== action.payload);
    },
  },
});

export const { campaignsRequest, refreshRequest, queueRequest, deleteQueue } =
  campaignsReducer.actions;

export default campaignsReducer.reducer;
