import { toast } from "react-toastify";
import store from "redux/reducers";
import { handleInnerLoading } from "redux/reducers/loaderReducer";

export const httpService = async (
  apiMethod: any,
  action: any,
  allowInnerLoading: boolean = true
) => {
  allowInnerLoading === true && store.dispatch(handleInnerLoading(true));
  try {
    const response = await apiMethod();
    let data
    if (response.data) {
      store.dispatch(action(response.data));
      data = response.data
    } else {
      store.dispatch(action(response));
      data = response
    }
    allowInnerLoading === true && store.dispatch(handleInnerLoading(false));
    return { success: true, data };
  } catch (e: any) {
    if (e.response) {
      if (e.response.data.error) {
        toast.error(e.response.data.error);
      }
      if (e.response.data.message) {
        toast.error(e.response.data.message);
      }
      if (e.response.data.errors) {
        const errorKeys = Object.keys(e.response.data.errors)
        toast.error(e?.response?.data?.errors[errorKeys[0]][0]);
      }
    }
    allowInnerLoading === true && store.dispatch(handleInnerLoading(false));

    return { success: false };
  }
};
