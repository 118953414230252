import React from 'react';
import PropTypes from 'prop-types';
import styles from "./CustomizedStepper.module.scss";
import { styled } from "@mui/material/styles";
import clsx from 'clsx';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import OverviewIcon from './OverviewIcon';
import AddContactIcon from './AddContactIcon';
import AddJobIcon from './AddJobIcon';
import AddServiceIcon from './AddServiceIcon';
import AddContactDetailIcon from './AddContactInfoIcon';
import './QuickQuote.scss'
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateActiveStep } from 'redux/reducers/quickQuoteReducer';
import { selectQuickQuoteInfo } from 'redux/selectors/quickQuoteSelector';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  alternativeLabel: {
    top: 32,
  },
  active: {
    '& $line': {
      backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 1,
  },
}));

interface ColorlibStepIconProps {
  active: boolean;
  completed: boolean;
  active_step: number;
  icon: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

interface IconsMap {
  [key: string]: JSX.Element;
}

function ColorlibStepIcon(props: ColorlibStepIconProps) {
  const { active, completed, icon, setActiveStep } = props;

  const handleClick = () => {
    if (completed && !active) setActiveStep(icon - 1);
  };

  const icons: IconsMap = {
    1: <AddContactIcon />,
    2: <AddJobIcon viewBox='0 0 21 22' />,
    3: <AddServiceIcon />,
    4: <AddContactDetailIcon />,
    5: <OverviewIcon />,
  };

  const className = active === true ? styles.active : (completed === true ? styles.completed : '')

  return (
    <div
      className={clsx(styles.root, className)}
      onClick={handleClick}
    >
      {icons[String(icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
};

interface CustomizedSteppersProps {
  getStepContents: Function;
  steps: string[];
}

export default function CustomizedSteppers({
  getStepContents,
  steps,
}: CustomizedSteppersProps) {
  const { completed_steps, active_step } = useAppSelector((state) => selectQuickQuoteInfo(state));

  const dispatch = useAppDispatch();
  const setActiveStep = (step: number) => {
    dispatch(updateActiveStep(step))
  }

  return (
    <div className={styles.root2}>
      <Stepper
        className={`${styles.stepper} stepper`}
        nonLinear
        activeStep={active_step}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, i) => (
          <Step key={i} className={`${completed_steps[i] ? 'stepCompleted' : 'stepPedning'} ${i === active_step ? 'stepActive' : ''}`} >
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon
                  {...props}
                  active={active_step === i}
                  completed={completed_steps[i]}
                  active_step={active_step}
                  setActiveStep={setActiveStep}
                />
              )}
            />
          </Step>
        ))}
      </Stepper>
      <div>
        <div className={styles.stepContents}>
          {getStepContents(active_step)?.component}
        </div>
      </div>
    </div>
  );
}
