import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import uiReducer from "./reducers/uiReducer";
import loaderReducer from "./reducers/loaderReducer";
import leadsReducer from "./reducers/leadsReducer";
import eventReducer from "./reducers/eventReducer";
import contactReducer from "./reducers/contactReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import analyticsReducer from "./reducers/analyticsReducer";
import quickQuoteReducer from "./reducers/quickQuoteReducer";
import viewContactReducer from "./reducers/viewContactReducer";
import workReducer from "./reducers/workReducer";
import formsReducer from "./reducers/formsReducer";
import carDetailsReducer from "./reducers/carDetailsReducer";
import createFormReducer from "./reducers/createFormReducer";
import optionReducer from "./reducers/optionReducer";
import filmReducer from "./reducers/filmReducer";
import campaignsReducer from "./reducers/campaignsReducer";
import teamReducers from "./reducers/teamReducers";
import leadProfileReducer from "./reducers/leadProfileReducer";
import tasksReducer from "./reducers/tasksReducer";
import newProjectReducer from "./reducers/newProjectReducer";
import invoiceReducer from "./reducers/invoiceReducer";
import invoicePageReducer from "./reducers/invoicePageReducer";
import userReducer from "./reducers/userReducer";
import permissionReducer from "./reducers/permissionReducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    loading: loaderReducer,
    ui: uiReducer,
    leads: leadsReducer,
    events: eventReducer,
    contact: contactReducer,
    forms: formsReducer,
    leadProfile: leadProfileReducer,
    dashboard: dashboardReducer,
    newProject: newProjectReducer,
    analytics: analyticsReducer,
    quickQuote: quickQuoteReducer,
    viewContact: viewContactReducer,
    carDetails: carDetailsReducer,
    works: workReducer,
    createForm: createFormReducer,
    options: optionReducer,
    film: filmReducer,
    campaigns: campaignsReducer,
    teams: teamReducers,
    task: tasksReducer,
    invoice: invoiceReducer,
    invoicePage: invoicePageReducer,
    users: userReducer,
    permission: permissionReducer
  },
  devTools: true,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
