import apiClient from "../client";

export default class ContactAPI {
  static list(para: any, type: any) {
    return apiClient.get(`/${type}`, { params: para });
  }

  static listAll(filters: any) {
    return apiClient.get("/contacts", {
      params: {
        paginate: 0,
        ...filters,
      },
    });
  }

  static view(id: any) {
    return apiClient.get(`/contacts/${id}`);
  }

  static create(data: any) {
    return apiClient.post("/contacts", data);
  }

  static update(id: any, data: any) {
    return apiClient.put(`/contacts/${id}`, data);
  }

  static delete(id: any) {
    return apiClient.delete(`/contacts/${id}`);
  }

  static export(filters: any) {
    return apiClient.get("/contacts/export", {
      responseType: "blob",
      params: filters,
    });
  }

  static overview(id: any) {
    return apiClient.get(`/contacts/${id}/get-contact-overview`);
  }

  // static import(data) {
  //   data.skip_existing = data.skip_existing ? 1 : 0;
  //   data.has_headers = data.has_headers ? 1 : 0;
  //   return apiClient.post('/contacts/import', serialize(data), {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   });
  // }

  static timelineHistories(id: any) {
    return apiClient.get(`/contacts/${id}/timeline-histories`);
  }
  static leadCampaigns(id: any) {
    return apiClient.get(`/contacts/${id}/lead-campaigns-emails`);
  }
  static leadCampaignsView(id: any) {
    return apiClient.get(`/projects/${id}/lead-campaigns-emails`);
  }

  static merge(id: any, data: any) {
    return apiClient.post(`/contacts/${id}/merge`, data);
  }
  static assignBehaviorTag(id: any, data: any) {
    return apiClient.post(`/contacts/${id}/assign-behavior-tag`, data);
  }

  static searchContact(search: string) {
    return apiClient.get(`/get-contacts`, {
      params: { filter: { search_exact_name: search }, per_page: 3 },
    });
  }

  static getTabDetails() {
    return apiClient.get("/get-contacts-tab-details");
  }

  static projectTypes() {
    return apiClient.get("/get-project-types");
  }
  static makes() {
    return apiClient.get("/makes");
  }
  static models(makeId: any) {
    return apiClient.get(`/models/${makeId}`);
  }
  static bodyType(type: any) {
    return apiClient.get(`/install-area-types?project_type=${type}`);
  }
  static years(modelId: any) {
    return apiClient.get(`/years/${modelId}`);
  }

  static getContactInvoices(contactId: any) {
    return apiClient.get(`/contacts/${contactId}/project-invoices`);
  }

  static getContactInvoiceDetail(projectId: any) {
    return apiClient.get(`projects/${projectId}/invoice`);
  }

  static updateContactInvoiceDetail(projectId: any, data: any) {
    return apiClient.put(`projects/${projectId}/invoice`, data);
  }

  static contactInvoicePDFDetail(projectId: any) {
    return apiClient.get(`projects/${projectId}/invoice/pdf`, {
      responseType: "blob",
    });
  }

  static add_notes(data: any) {
    return apiClient.post(`notes`, data);
  }

  static updateNotes(id: any, data: any) {
    return apiClient.put(`notes/${id}`, data);
  }
  static getNotes(data: any) {
    return apiClient.get(`notes?${data}`);
  }
  static addTag(id: any, data: any) {
    return apiClient.post(`tags/${id}/store-tags`, data);
  }

  static contactInvoicePayment(projectId: any, data: any) {
    return apiClient.post(`projects/${projectId}/invoice/payments`, data);
  }

  static contactSendInvoice(projectId: any, data: any) {
    return apiClient.post(`projects/${projectId}/invoice`, data);
  }

  static contactNoShowCustomer(projectId: any, data: any) {
    return apiClient.post(`projects/${projectId}/no-show-project`, data);
  }

  static contactCustomer(contactId: any, data: any) {
    return apiClient.post(`events/${contactId}/contact-customer`, data);
  }
  static projectList(data: any) {
    return apiClient.get(`/projects?${data}`);
  }
  static reviewLinks() {
    return apiClient.get(`/review-links`);
  }
  static review() {
    return apiClient.get(`/reviews`);
  }
  static getReview(id: any) {
    return apiClient.get(`/reviews?filter[contact_id]=${id}`);
  }

  static contactTimeLineHistory(contact_id: any) {
    return apiClient.get("/get-timeline-histories", { params: { filter: { contact_id }, per_page: 4 } });
  }

  static getState() {
    return apiClient.get("/states", { params: { paginate: 0 } });
  }

  static getCity(state: string) {
    return apiClient.get("/cities", { params: { filter: { 'states.name': state }, paginate: 0 } });
  }
}
