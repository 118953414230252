import apiClient from '../client';

export default class FilmApi {
  static list(page = 1, filter = {}, sort = {}) {
    return apiClient.get('/films', {
      params: {
        page,
        filter,
        sort
      },
    });
  }

  static listAll() {
    return apiClient.get('/films', {
      params: {
        paginate: 0
      }
    });
  }

  static view(id: number) {
    return apiClient.get(`/films/${id}`);
  }

  static create(data: any) {
    return apiClient.post('/films', data);
  }

  static update(id: number, data: any) {
    return apiClient.put(`/films/${id}`, data);
  }

  static delete(id: number) {
    return apiClient.delete(`/films/${id}`);
  }

  static getFilmsByProject(projectType: any) {
    return apiClient.get(`/films/project-type/${projectType}`);
  }
}
