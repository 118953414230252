export const themeColor = {
  primary: "#902F8C",
  primaryGray: "linear-gradient(270deg, #912f8c, #67348d)",
  secondary: "#2A4496",
  dark: "#242424",
  bodyBg: "#000",
  error: "#EC2527",
  delete: "#cb0000",
  gray: "#6E6F72",
  black: "#000",
  white: "#fff",
  mainContainer: "#F5F5F7",
  darkGray: "#898989",
  blue: "#2A4496",
  link: "#0085DD",
  success: "#41AB1C",

  upIcon: "#00CD39",
  downIcon: "#EC2527",
  //Text 
  textMenu: "#2E2E2E",
  navLink: "#B9B9B9",
  textGray: "#777",
  textGray2: "#595959",
  textGray3: "#8b8989",
  // Tags Color
  default: "#E9F0FF",
  new: "#6FC7B4",
  approved: "hsla(111, 65%, 48%, 1)",
  progress: "#FF5C00",
  draft: "#FF5A5A",
  message: "#6FC7B4",
  quoted: "#892D8E",
  sent: "#892D8E",
  opened: "#892D8E",
  consultScheduled: "#BAAE00",
  scheduled: "#2A4496",
  completed: "#5DC1F7",
  cancelled: "#CB0000",
  noShow: "#CB0000",
  comeback: "#CB0000",
  upsell: "#FF7800",
  tagGray: "#686868",
  unPaid: "#FF7A00",
  paid: "#43CC2B",
  active: "#43cc2b",
  inActive: "#F26468",
  seaGreen: "#2dd5c4",
  overdue: "#EC2227",

  //Table Status Color
  waiting: "#3e8bff",
  processing: "#ff9e44",
  complete: "#28cc90",
  tableHeadBg: "#F6F6F6",
  headColor: "#545454;",
  grayText: "#8B8989",
  dueText: "#3e8bff",

  borderGray: "#C5C5C5",
  grayBg: "#F2F2F3",

  placeholder: "rgba(132, 132, 132, 0.5)",

  inputColor: "#242424",
  activeStar: "#FFA51F",
  inActiveStar: "#ADADAD",
  calendarPageBg: "#F6F6F6",
  invoicePageBg: "#FFFFFF",
  analyticsPageBg: "#F6F6F6",

  modifyBtn: "linear-gradient(270deg, #912F8C 0%, #67348D 100%)",
  completeBtn: "#28CC90",
  redBtn: "#D40506",
  orangeBtn: "#FF9E44",
  darkBtn: "#1F1F1F",

  linkColor: "#3E8BFF"
};
