/* eslint-disable react-hooks/exhaustive-deps */
import { HasPermission } from 'components/HasPermission/HasPermission';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetQuoteState, updateAppointment, updateQuoteJob } from 'redux/reducers/quickQuoteReducer';
import { getQuickQuote } from 'redux/selectors/quickQuoteSelector';
import { getNextQuarterTime } from 'utils/getCurrentTime';
import uuid4 from 'uuid4';
import ArchitecturalOverview from './ArchitecturalOverview';
import AutomotiveOverview from './AutomotiveOverview';
import ScheduleAppointment from './ScheduleAppointment';
import { getSafeValue } from 'utils';

function Overview({
  scheduleAppointment,
  errors: validationError
}: any) {
  const userData: any = useAppSelector((state) => state.auth.entities);
  const organizationTimeZone = _.get(userData, 'organisation.timezone', 'UTC')

  const [appointment, setAppointment] = useState<any>({
    allDay: false,
    date: moment().format('YYYY-MM-DDTHH:mm'),
    start_time: getNextQuarterTime(organizationTimeZone),
    end_time: moment(getNextQuarterTime(organizationTimeZone)).add(15, 'minutes').format('YYYY-MM-DDTHH:mm'),
    assignee: userData.id,
    is_mobile_visit: false,
    location: {},
  })

  const quickQuote: any = useAppSelector((state) => getQuickQuote(state))
  const { contact, jobs } = quickQuote
  const dispatch = useAppDispatch();
  const isMobileJob = getSafeValue(quickQuote, 'jobs[0].mobile_install', false)
  const appointmentAddress = ((jobs[0]?.type === 'Architectural' && jobs[0]?.service_type === 'Consultation') || isMobileJob === true) ? getSafeValue(contact, 'address', {}) : getSafeValue(quickQuote, 'appointment.location', {})

  useEffect(() => {
    dispatch(updateAppointment({ name: 'address', value: appointmentAddress }))
  }, [])

  const handleAppointmentChange = (name: any, value: any) => {
    dispatch(updateAppointment({ name, value }))
  }

  const handleJobUpdate = (index: any, name: any, value: any) => {
    dispatch(updateQuoteJob({ index, name, value }))
  }

  const resetQuickQuoteModel = () => {
    dispatch(resetQuoteState(false));
  }

  useEffect(() => {
    setAppointment({ ...appointment, ...quickQuote.appointment, location: appointmentAddress });
  }, [quickQuote]);

  if (scheduleAppointment) {
    return (<HasPermission permission={['enable_scheduling']}>
      <ScheduleAppointment state={quickQuote}
        handleUpdate={handleJobUpdate}
        resetQuickQuoteModel={resetQuickQuoteModel}
        handleAppointmentUpdate={handleAppointmentChange}
        appointment={appointment}
        validationError={validationError}
      />
    </HasPermission>
    );
  }

  return (
    <>
      {
        jobs.map((job: any, index: number) => <div key={uuid4()}>
          {job.type === 'Automotive' &&
            <AutomotiveOverview job={job} contact={contact} index={index} />
          }

          {job.type === 'Architectural' &&
            <ArchitecturalOverview job={job} contact={contact} index={index} />
          }
        </div>
        )}
    </>
  );
}

export default Overview;
