import { Box } from "@mui/material";
import { ButtonBox } from "components/Ui/Button/Button";
import ActiveBox from "../Updates/ActiveBox";
import Highlights from "./Highlights";

function Projects({ isPublic }: any) {
  return (
    <Box>
      <Highlights />
      <Box pt={2}>
        <ActiveBox isPublic={isPublic} />
      </Box>
      <Box pt={2}>
        <ButtonBox fullWidth className="SeaGreen">
          Get Another Quote
        </ButtonBox>
      </Box>
    </Box>
  );
}

export default Projects;
