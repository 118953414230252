import moment from 'moment-timezone'

export const getNextQuarterTime = (organizationTimeZone: string = '') => {
    // Get the current date and time 
    const now = moment.tz(organizationTimeZone)
    
    // Calculate the number of minutes until the next quarter-hour
    const minutesUntilNextQuarter = 15 - (now.minutes() % 15)
    
    // Create a new Moment object for the next quarter-hour
    const nextQuarterHour = moment.tz(organizationTimeZone).add(minutesUntilNextQuarter, 'minutes')

    
    // Format the date to display the hour and minute only
    const formattedTime = nextQuarterHour.format('YYYY-MM-DDTHH:mm')
    
    return formattedTime
}

export const convertTimeInUTC = (time: any, timezone: string, format: string = 'YYYY-MM-DD HH:mm:ss') => {
    const organizationTimeZoneDate = moment.tz(moment(time).format('YYYY-MM-DD HH:mm:ss'), timezone)
    const utcTime = moment(organizationTimeZoneDate).tz('UTC').format(format)
    return utcTime
}

export const convertTimeInTimeZone = (time: any, timezone: string, format: string = 'YYYY-MM-DD HH:mm:ss') => {
    const organizationTimeZoneDate = moment.tz(moment(time).format('YYYY-MM-DD HH:mm:ss'), 'UTC')
    const utcTime = moment(organizationTimeZoneDate).tz(timezone).format(format)
    return utcTime
}

// Function to get the current date and time in the timezone as a Date object
export const getCurrentOrganizationTime = (timezone: string) => {
  // Get the current date and time
  let now = new Date();

  // Define the options for formatting
  let options: any = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  // Format the current date and time as a string
  let formattedDate = now.toLocaleString('en-US', options);

  // Parse the formatted date string back into a Date object
  let [month, day, year]: any = formattedDate.split(', ')[0].split('/');
  let [hour, minute, second]: any = formattedDate.split(', ')[1].split(':');
  let losAngelesTime = new Date(year, month - 1, day, hour, minute, second);

  return losAngelesTime;
}
