import { Box, Typography } from "@mui/material";
import Style from "./Contact.module.scss";
import { formatPhoneNumber, getFullAddress, getSafeValue } from "utils";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

function Contact({ isPublic }: any) {
  const [contactInfo, setContactInfo] = useState<any>({
    website: 'blackedouttinting.com',
    name: 'Cliff Richards',
    phone_number: '(909) 514-1234',
    email: 'cliffrichard@gmail.com',
    address: getFullAddress({
      city: 'California',
      country: '',
      second_line: 'Suite U',
      state_province: 'Ontario',
      street_address: '320 S Milliken Ave',
      zipcode: '91761'
    })
  })

  const publicData = useAppSelector((state) => state.leadProfile.publicData);
  useEffect(() => {
    const organisation = getSafeValue(publicData, 'organisation', false)
    if (organisation !== false) {
      setContactInfo({
        website: organisation.business_website,
        name: organisation.name,
        phone_number: formatPhoneNumber(organisation.contact_number),
        email: organisation.business_email,
        address: getFullAddress(organisation.address)
      })
    }
  }, [publicData])

  return (
    <Box>
      <Box className={Style.container}>
        <Typography className={Style.title}>
          Contact Blacked Out Tinting
        </Typography>
        <Box>
          <Box className={Style.gridFlex}>
            <Typography className={Style.lable}>Website:</Typography>
            <Typography className={Style.data}>{getSafeValue(contactInfo, 'website', '---')}</Typography>
          </Box>
          <Box className={Style.gridFlex}>
            <Typography className={Style.lable}>Phone:</Typography>
            <Typography className={Style.data}>{getSafeValue(contactInfo, 'phone_number', '---')}</Typography>
          </Box>
          <Box className={Style.gridFlex}>
            <Typography className={Style.lable}>Email:</Typography>
            <Typography className={Style.data}>{getSafeValue(contactInfo, 'email', '---')}</Typography>
          </Box>
          {contactInfo.address &&
            <Box className={Style.gridFlex}>
              <Typography className={Style.lable}>Address:</Typography>
              <Typography className={Style.data}>{contactInfo.address}</Typography>
            </Box>}
        </Box>
      </Box>
    </Box>
  );
}

export default Contact;
