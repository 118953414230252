import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton, MenuItem, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArchitecturalApplicationDetail from './ArchitecturalApplicationDetail';
import styles from "./Application.module.scss";
import TextInput from "../../../components/Ui/TextInput/TextInput";
import SquareIcon from 'assets/icon/add_square.svg';
import { get } from 'lodash';
import { useState } from 'react';
import { PrimaryButton, DarkButton } from 'components/Ui/Button/Button';
import QuickQuoteAPI from "api/resources/quickQuote";
import { httpService } from "helper/httpService";
import { setLoading, toggleQuickQuotePopup, updateQuoteJob, updateQuoteRequest } from "redux/reducers/quickQuoteReducer";
import { useAppSelector } from 'redux/hooks';
import { useNavigate } from "react-router-dom";
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import _ from 'lodash'
import { useDispatch } from 'react-redux';
import { getQuickQuote, selectQuickQuoteInfo } from 'redux/selectors/quickQuoteSelector';

interface ApplicationDetailsProps {
  job: any,
  index: number,
  validationError?: any,
  messageForCustomer?: any,
  showEditLink?: any
  onEditJob?: any
}

function ArchitecturalApplicationDetails({ job, index, validationError, messageForCustomer, showEditLink, onEditJob }: ApplicationDetailsProps) {
  const navigate = useNavigate();
  const { services, service_type } = job;
  const [detailQuote, setDetailQuote] = useState(false);

  const { contact, jobs, message_for_customer, id } = useAppSelector((state) => getQuickQuote(state));
  const { loading } = useAppSelector((state) => selectQuickQuoteInfo(state));

  const dispatch = useDispatch();
  const handleUpdate = (name: string, value: any) => {
    dispatch(updateQuoteJob({ index, name, value }))
  };

  const getTotalSqftArea = (measurementsArray: any) => {
    return measurementsArray.reduce((acc: any, measurement: any) => acc + measurement.sqft, 0);
  };

  const getTotalWindows = (measurementsArray: any) => {
    return measurementsArray.reduce((acc: any, measurement: any) => acc + Number(measurement.number_of_windows), 0);
  };

  const getTotalRemovalSqft = (measurementsArray: any) => {
    return measurementsArray.reduce((acc: any, measurement: any) => {
      if (measurement.removal_required) {
        acc.sqft = acc.sqft + measurement.sqft
        acc.windows = acc.windows + measurement.number_of_windows
      }

      return acc
    }, { sqft: 0, windows: 0 });
  };

  const handleAddService = (type: any) => {
    const updatedServices = [...services, {
      type, price_per_sqft: 0, measurements: [{
        number_of_windows: 1,
        width: 0,
        height: 0,
        removal_required: false,
        sqft: 0,
      }], film_options: [], service_title: '', price: 0, multi_price_option: false
    }];
    handleUpdate('services', updatedServices);
  };

  const handleRemoveService = (index: number) => {
    const updatedServices = services.filter((_: any, i: number) => i !== index);
    handleUpdate('services', updatedServices);
  };

  const handleUpdateService = async (serviceIndex: number, name: string, value: any) => {
    const updatedServices: any = services.map((service: any, i: number) => (i === serviceIndex ? { ...service, [name]: value } : service));

    if (service_type !== 'Consultation') {
      updatedServices.forEach((service: any, i: number) => {
        if (service.type === 'Architectural Window Tinting') {
          let total_sqft = getTotalSqftArea(service.measurements).toFixed(2)
          total_sqft = service.round_up_sqft ? Math.round(Number(total_sqft)) : total_sqft;

          const removalDetails = getTotalRemovalSqft(service.measurements);
          let removalSqftData = Number((removalDetails.sqft)?.toFixed(2));
          removalSqftData = service.round_up_sqft ? Math.round(Number(removalSqftData)) : removalSqftData;
          const price = (get(service, 'price_per_sqft', 0) * total_sqft)?.toFixed(2)
          const discount_price = (get(service, 'discount_price_per_sqft', 0) * total_sqft)?.toFixed(2)

          const _service = {
            ...service,
            total_sqft: total_sqft,
            total_windows: getTotalWindows(service.measurements),
            discount: _.get(service, 'discount', false),
            discount_price: discount_price === "0.00" ? null : discount_price,
            price: price,
            removal_sqft: removalSqftData,
            removal_windows: Number(removalDetails.windows),
            total_price_with_discount: get(service, 'discount', false) === true ? discount_price : price,
            total_price_without_discount: price
          }

          updatedServices[i] = _service;
        }
      });
    }
    handleUpdate('services', updatedServices);
  };

  const getFormattedPayload = () => {
    const isFound = contact.discoveries.some((element: any) => {
      if (element.id) {
        return true;
      }
      return false;
    });

    return {
      contact: {
        ...contact,
        name: contact?.name,
        discoveries: isFound
          ? contact.discoveries.map((e: any) => e.value)
          : contact.discoveries
      },
      jobs: jobs.map((job: any, jobIndex: number) => (jobIndex === index ? {
        ...job,
        is_detailed: detailQuote,
        services: services
      } : job)),
      message_for_customer: message_for_customer
    };
  };

  const goToDetailedQuote = async () => {
    const data = getFormattedPayload();
    dispatch(setLoading(true))
    await httpService(() => QuickQuoteAPI.update(id, data), updateQuoteRequest);
    dispatch(toggleQuickQuotePopup(false))
    dispatch(setLoading(false))
    navigate(`/leads/contacts/projects?id=${contact.id}&type=detailed_architectural`);
  }

  const switchToConsult = async () => {
    const data = getFormattedPayload();
    const consultData = {
      ...data,
      jobs: jobs.map((job: any) => ({ ...job, service_type: 'Consultation' }))
    };
    dispatch(setLoading(true))
    await httpService(() => QuickQuoteAPI.update(id, consultData), updateQuoteRequest);
    dispatch(setLoading(false))
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.section}>
        <div className={styles.sectionInfo}>
          <Typography variant="subTitle1">{job.building_type} Project ({service_type})</Typography>
          {showEditLink === true && <Typography variant='linkText' className='cursor' ml={'10px'} onClick={onEditJob}>Edit</Typography>}
          {service_type === 'Installation' &&
            <Button className={styles.detailQuoteLink} onClick={() => switchToConsult()}>
              Switch to Consult
            </Button>
          }
        </div>

        <div className={styles.serviceSelection}>
          <div className={styles.inputWrapper}>
            {services.map((service: any, i: number) =>
              <div key={i} className={styles.flexWrapper}>
                <div className={styles.serviceTag}>
                  <Typography variant='subtitle2' fontWeight={600} color={'#000'}>
                    {service.type}
                  </Typography>
                  <IconButton onClick={() => handleRemoveService(i)} className={styles.CloseIcon}>
                    <CloseIcon />
                  </IconButton>
                </div>
                {serviceTypes.length !== services.length &&
                  <img src={SquareIcon} alt=''
                    className={styles.cursorPointer}
                    onClick={() => handleAddService((service.type === 'Architectural Window Tinting') ? 'Other' : 'Architectural Window Tinting')} />
                }
              </div>
            )}

            {services.length === 0 && (
              <TextInput
                onChange={(e) => handleAddService(e.target.value)}
                name='service_type'
                label={'Add Service'}
                size='small'
                value={''}
                defaultValue={''}
                margin='dense'
                select
                className={styles.serviceField}
                fullWidth
              >
                {
                  serviceTypes.map((type, i) => (
                    <MenuItem
                      value={type.value}
                      key={i}
                      disabled={services?.some((service: any) => service.type === type.value)}
                    >
                      {type.name}
                    </MenuItem>
                  ))}
              </TextInput>
            )}
          </div>
          {(service_type === 'Installation' && services.some((service: any) => service.type.value !== 'Other')) &&
            <Button className={styles.detailQuoteLink} onClick={() => setDetailQuote(true)}>
              Switch To Detailed Quote
            </Button>
          }
        </div>

        <Dialog
          open={detailQuote}
          onClose={() => setDetailQuote(false)}
          className={styles.dialog}
          disableEnforceFocus
          scroll="paper"
          PaperProps={{ sx: { borderRadius: "10px", width: '558px' } }}
        >
          <Box className={styles.confirmDialog}>
            <Grid>
              <CloseIcon className={styles.closeIcon} onClick={() => setDetailQuote(false)} />
            </Grid>
            <DialogContent className={styles.dialogContent} sx={{ padding: '0' }}>
              <Typography className={styles.detailHeader}>Switching To Detailed Quote</Typography>
              <Typography className={styles.detailContent}>
                You are switching this project to a detailed quote which allows for more detailed experience
              </Typography>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <div className={styles.btnWrapper}>
                <DarkButton
                  className={styles.btnBack}
                  onClick={() => setDetailQuote(false)}>
                  Go Back
                </DarkButton>
                <PrimaryButton
                  className={styles.detailBtn}
                  onClick={goToDetailedQuote}
                  disabled={loading}>
                  Detailed Quote
                </PrimaryButton>
              </div>
            </DialogActions>
          </Box>
        </Dialog>
        <ErrorMessage error={_.get(validationError, ['jobs[0].services'], '')} />
      </Box>
      {
        services.map((service: any, i: number) =>
          <ArchitecturalApplicationDetail
            key={i}
            index={i}
            handleUpdateService={handleUpdateService}
            handleUpdateJob={handleUpdate}
            job={job}
            validationError={validationError}
            messageForCustomer={messageForCustomer}
          />)
      }
    </Box >
  );
}

export default ArchitecturalApplicationDetails;

const serviceTypes = [
  {
    name: 'Architectural Window Tinting',
    value: 'Architectural Window Tinting'
  },
  {
    name: 'Other Service',
    value: 'Other'
  }
];
