/* eslint-disable no-new-wrappers */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Dialog,
  Typography,
} from "@mui/material";
import CloseIcon from 'assets/icon/close.svg';
import _ from "lodash";
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import uuid4 from 'uuid4';
import Styles from "./Application.module.scss";

const PriceBreakdown = (props: any) => {
  const userData: any = useAppSelector((state) => state.auth.entities);
  const taxRate = _.get(userData, 'organisation.configuration.tax_rate', 0)
  const tax_type = _.get(userData, 'organisation.configuration.tax_type', '')

  const [totals, setTotals] = useState<any>({
    subTotal: 0.00,
    discountAmount: 0.00,
    grandTotal: 0.00,
    salesTax: 0.00,
    hasDiscount: false
  })

  const {
    priceBreakdown,
    setPriceBreakdown,
    mobile_install,
    mobile_price,
    job
  } = props

  useEffect(() => {
    let subTotal = 0.00
    let discountAmount = 0.00
    let grandTotal = 0.00
    let salesTax = 0.00
    let hasDiscount = false

    let film_removal_option = false
    let removal_windows = 0
    let removal_sqft = 0
    let removalPrice = 0
    let removalDiscountPrice = 0

    for (const service of job.services) {
      subTotal += Number(service.price)
      grandTotal += (service.discount === true) ? Number(service.discount_price) : Number(service.price)
      discountAmount += (service.discount === true) ? (Number(service.price) - Number(service.discount_price)) : 0.00
      if (hasDiscount === false) {
        hasDiscount = _.get(service, 'discount', false)
      }

      if (film_removal_option === false) {
        film_removal_option = _.get(service, 'film_removal_option', false)
      }

      if (service.film_removal_option === true) {
        removal_windows += Number(service.removal_windows)
        removal_sqft += Number(service.removal_sqft)

        removalPrice += Number(service.removal_sqft) * Number(service.price_per_sqft)
        removalDiscountPrice += Number(service.removal_sqft) * Number(service.discount_price_per_sqft)
      }
    }

    // Adding mobile price if available
    if (mobile_install === true) {
      subTotal += Number(mobile_price)
      grandTotal += Number(mobile_price)
    }

    if (tax_type !== 'no_tax_business') {
      salesTax = (grandTotal * taxRate) / 100
      if (tax_type === 'add_tax') {
        grandTotal += salesTax
      }
    }

    const totals = { subTotal, discountAmount, grandTotal, salesTax, hasDiscount, film_removal_option, removal_windows, removal_sqft, removalPrice, removalDiscountPrice }

    setTotals(totals)
  }, [priceBreakdown])

  const formatPrice = (price: any) => {
    const formattedPrice = parseFloat(price).toFixed(2)
    return _.isNaN(new Number(formattedPrice)) ? '0.00' : formattedPrice
  }

  return <Dialog
    open={priceBreakdown}
    onClose={e => setPriceBreakdown(false)}
    maxWidth='md'
    fullWidth={true}
    className={Styles.dialog}
    scroll="paper"
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "700px",
          padding: '37px 33px'
        },
      },
    }}
  >
    <Box className={Styles.dialogContent}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography fontSize={'24px'} fontWeight={'600'}>
          Price Breakdown
        </Typography>
        <span className={Styles.modelCloseIcon} onClick={e => setPriceBreakdown(false)}>
          <img src={CloseIcon} alt="model Close icon" />
        </span>
      </Box>
      <Box pt={2}>
        <Box className={Styles.breakDownHeading}>
          <Box>
            <Typography className={Styles.brTitle1} style={{ textAlign: 'left' }}>Item</Typography>
          </Box>
          <Box className={Styles.breakDownPrcingTitle} style={{ justifyContent: totals.hasDiscount ? 'space-between' : 'end' }}>
            {totals.hasDiscount &&
              <>
                <Typography className={Styles.brTitle1}>Before Price</Typography>
                <Typography className={Styles.brTitle1}>Discount</Typography>
              </>
            }
            <Typography className={Styles.brTitle1}>Line Total</Typography>
          </Box>
        </Box>

        {job.services.map((service: any, index: number) =>
          <Box className={Styles.breakDownContent} key={uuid4()}>
            <Box>
              {job.type === 'Automotive' ?
                <>
                  <Typography variant='subtitle2' fontWeight={600} className={Styles.serviceName}>{service.type}</Typography>
                  <ul className={Styles.listItem}>
                    {service.applications.map((item: any, i: number) =>
                      <li key={uuid4()}>
                        <Box className={Styles.listArea} display={'flex'}>
                          <Typography className={Styles.brTitle3}>{item.area} {item.film_removal && <span style={{ color: '#F00' }}>+ Removal</span>}</Typography>
                          <Typography className={Styles.brTitle4}> - {item?.films[0]?.name}</Typography>
                        </Box>
                      </li>
                    )}
                  </ul>
                </>
                : <>
                  <Typography variant='subtitle2' fontWeight={600} className={Styles.serviceName}>{service.type !== 'Other' ? `${job.building_type} Window Tinting` : `${service.type}`}</Typography>
                  {service.type !== 'Other' &&
                    <ul className={Styles.listItem}>
                      <li>
                        <Box className={Styles.listArea}>
                          <Typography className={Styles.brTitle3}>{service.total_windows} Windows - {service.film_options[0]?.name}</Typography>
                          <Typography className={Styles.brTitle4}>- {service.total_sqft}sqft </Typography>
                        </Box>
                      </li>
                    </ul>
                  }
                </>
              }
            </Box>
            {job.type === 'Automotive' ?
              <Box className={Styles.breakDownPrcingTitle} style={{ justifyContent: totals.hasDiscount ? 'space-between' : 'end' }}>
                {totals.hasDiscount &&
                  <>
                    <Typography className={Styles.brTitle2} >${formatPrice(service.price)}</Typography>
                    <Typography className={Styles.brTitle2}>${formatPrice(service.price - service.discount_price)}</Typography>
                  </>
                }
                <Typography className={Styles.brTitle2}>${formatPrice(service.discount ? service.discount_price : service.price)}</Typography>
              </Box>
              : <Box className={Styles.breakDownPrcingTitle} style={{ justifyContent: totals.hasDiscount ? 'space-between' : 'end' }}>
                {totals.hasDiscount &&
                  <>
                    <Typography className={Styles.brTitle2} >${formatPrice(service.price)}</Typography>
                    <Typography className={Styles.brTitle2}>${service.discount ? formatPrice(service.price - service.discount_price) : '0.00'}</Typography>
                  </>
                }
                <Typography className={Styles.brTitle2}>${formatPrice(service.discount ? service.discount_price : service.price)}</Typography>
              </Box>
            }
          </Box>
        )}

        {mobile_install === true &&
          <Box className={Styles.breakDownContent}>
            <Box>
              <Typography variant='subtitle2' fontWeight={600}>Mobile Installation Charge</Typography>
            </Box>
            <Box className={Styles.breakDownPrcingTitle} style={{ justifyContent: totals.hasDiscount ? 'space-between' : 'end' }}>
              {totals.hasDiscount &&
                <>
                  <Typography className={Styles.brTitle2} >${formatPrice(mobile_price)}</Typography>
                  <Typography className={Styles.brTitle2}>$0.00</Typography>
                </>
              }
              <Typography className={Styles.brTitle2}>${formatPrice(mobile_price)}</Typography>
            </Box>
          </Box>
        }

        {(totals.film_removal_option === true && job.type === 'Architectural') &&
          <Box className={Styles.breakDownContent}>
            <Box>
              <Typography variant='subtitle2' fontWeight={600}>Window Film Removal</Typography>
              <ul className={Styles.listItem}>
                <li>
                  <Box className={Styles.listArea}>
                    <Typography className={Styles.brTitle3}>{totals.removal_windows} Windows</Typography>
                    <Typography className={Styles.brTitle4}>- {totals.removal_sqft}sqft </Typography>
                  </Box>
                </li>
              </ul>
            </Box>
            <Box className={Styles.breakDownPrcingTitle} style={{ justifyContent: totals.hasDiscount ? 'space-between' : 'end' }}>
              {totals.hasDiscount &&
                <>
                  <Typography className={Styles.brTitle2} >${formatPrice(totals.removalPrice)}</Typography>
                  <Typography className={Styles.brTitle2}>${formatPrice(totals.removalDiscountPrice)}</Typography>
                </>
              }
              <Typography className={Styles.brTitle2}>${formatPrice(totals.removalPrice - totals.removalDiscountPrice)}</Typography>
            </Box>
          </Box>
        }

        <Box className={Styles.brContent}>
          <Box className={Styles.flexEnd}>
            <Box className={Styles.gridWrapper}>
              <Typography className={Styles.brTitle5}>Subtotal</Typography>
              <Typography className={Styles.brTitle6}>${formatPrice(totals.subTotal)}</Typography>
            </Box>
          </Box>
          <Box pt={2} className={Styles.flexEnd}>
            <Box className={Styles.gridWrapper}>
              <Typography className={Styles.brTitle5}>Sales Tax <small><br />({taxRate}%)</small></Typography>
              <Typography className={Styles.brTitle6}>${formatPrice(totals.salesTax)}</Typography>
            </Box>
          </Box>
          {totals.hasDiscount &&
            <Box pt={2} className={Styles.flexEnd}>
              <Box className={Styles.gridWrapper}>
                <Typography className={Styles.brTitle5}>Discount</Typography>
                <Typography className={Styles.brTitle6}>${formatPrice(totals.discountAmount)}</Typography>
              </Box>
            </Box>
          }
          <Box pt={2} className={`${Styles.totalContent} ${Styles.flexEnd}`}>
            <Typography className={Styles.totalTitle1}>Total</Typography>
            <Typography className={Styles.totalTitle2}>${formatPrice(totals.grandTotal)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Dialog>
};

export default PriceBreakdown;