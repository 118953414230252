import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PayingCustomers from "assets/contact/paying-customers.svg";
import NoSpendCustomers from "assets/contact/spend-customers.svg";
import AllCustomers from "assets/contact/all-customers.svg";
interface CounterState {
  entities: any;
  meta: any;
  refresh: any;
  activeTab: any;
  contactTab: any;
}

const initialState: CounterState = {
  entities: null,
  meta: null,
  refresh: false,
  activeTab: null,
  contactTab: [
    {
      name: "Paying Customers",
      key: "customers",
      image: PayingCustomers,
      selected: false,
    },
    {
      name: "No Spend Customers",
      key: "no-spend-contacts",
      image: NoSpendCustomers,
      selected: false,
    },
    {
      name: "All Customers",
      key: "contacts",
      image: AllCustomers,
      selected: false,
    },
  ],
};

export const contactReducer = createSlice({
  name: "contact",
  initialState,
  reducers: {
    contactRequest: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data;
      state.meta = action.payload.meta;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    updateTabCount: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      const tabs = [...state.contactTab];
      tabs[0].count = data.paying_customers_count;
      tabs[1].count = data.no_spend_customers_count;
      tabs[2].count = data.all_customers_count;
      state.contactTab = tabs;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      state.contactTab = [
        ...state.contactTab.map((item: any) => ({ ...item, selected: false })),
      ];
      const key = action.payload;
      const index = state.contactTab.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.contactTab[index];
        state.contactTab = state.contactTab.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.contactTab[0];
        state.contactTab[0].selected = true;
      }
    },
  },
});

export const { contactRequest, refreshRequest, selectTabs, updateTabCount } =
  contactReducer.actions;

export default contactReducer.reducer;
