import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  installAreas: [],
  loading: false,
  errors: null,
  success: false
};

export const optionReducer = createSlice({
  name: "options",
  initialState,
  reducers: {
    getOptionsRequest: (state, action: PayloadAction<any>) => {
      state.installAreas = action.payload.data;
      state.loading = false;
      state.errors = null;
      state.success = true;
    }
  },
});

export const { getOptionsRequest } = optionReducer.actions;

export default optionReducer.reducer;
