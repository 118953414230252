/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Typography } from "@mui/material";
import Style from "./TabBar.module.scss";
import { ReactSVG } from "react-svg";

const tabList = [
  {
    name: "Updates",
    img: "/assets/images/leadProfile/updates.svg",
    color: "fill",
  },
  {
    name: "Projects",
    img: "/assets/images/leadProfile/projects.svg",
    color: "stroke",
  },
  {
    name: "About Us",
    img: "/assets/images/leadProfile/aboutUs.svg",
    color: "fill",
  },
  {
    name: "Contact",
    img: "/assets/images/leadProfile/contact.svg",
    color: "fill",
  },
];

function TabBar({ setActiveTab, activeTab, color }: any) {
  return (
    <Box mt={3} position="relative">
      <Box className={Style.tabContainer}>
        {tabList.map((item, index) => (
          <Box
            className={`${Style.tabBox} ${item.name === activeTab && Style.select}`}
            key={index}
            onClick={() => setActiveTab(item.name)}
            sx={{ "& path": item.color === "fill" ? { fill: color } : { stroke: color } }}
          >
            <ReactSVG src={item.img} />
            <Typography variant="inputText">{item.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default TabBar;
