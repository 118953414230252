import apiClient from '../client';

export default class CarDetailsAPI {
  static listMakes() {
    return apiClient.get('/makes');
  }

  static listModels(makeId: any) {
    return apiClient.get(`/models/${makeId}`);
  }

  static listYears(modelId: any) {
    return apiClient.get(`/years/${modelId}`);
  }
}
