import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiClient from "api/client";
import Cookies from "universal-cookie";

const cookies = new Cookies();
interface CounterState {
  isAuth: any;
  loading: boolean;
  isLogin: boolean;
  message: string;
  entities: any;
}

const initialState: CounterState = {
  isAuth: null,
  isLogin: false,
  loading: false,
  message: "",
  entities: {},
};

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<any>) => {
      const token = action.payload.access_token;
      apiClient.defaults.headers.Authorization = `Bearer ${token}`;
      cookies.set("access_token", JSON.stringify(token));
      state.isAuth = true;
      state.isLogin = !state.isLogin;
    },
    logOut: (state) => {
      cookies.remove("access_token");
      cookies.remove("go_back");
      state.isAuth = false;
    },
    userSession: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data;
      state.isAuth = true;
    },
    userBusinessProfile: (state, action: PayloadAction<any>) => {
      console.log(action.payload)
      state.entities.organisation = action.payload.data;
      state.isAuth = true;
    },
    handleSession: (state) => {
      state.isLogin = !state.isLogin;
    },
    authSuccess: (state) => {
      state.loading = false;
    },
    authFailure: (state) => {
      state.loading = false;
    },
  },
});

export const {
  logIn,
  logOut,
  authSuccess,
  authFailure,
  userSession,
  userBusinessProfile,
  handleSession,
} = authReducer.actions;

export default authReducer.reducer;
