import moment from 'moment'
import Style from "./CustomTimeRangePicker.module.scss"
import { 
  Box,
  Menu,
  MenuItem
} from "@mui/material"
import React, { useState } from "react"
import downArrow from 'assets/icon/down-arrow.svg'
import { styled } from '@mui/material/styles'
import _ from 'lodash'

// Generate time options from 00:00 AM to 12:00 PM
const generateTimeOptions = () => {
  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      let time = (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute;
      timeOptions.push(moment(time, 'HH:mm').format('hh:mm A'));
    }
  }
  
  return [...timeOptions];
};

const timeOptions = generateTimeOptions();

const formatDateTimeAgo = (startTime: any, time: any) => {
  const today = moment(startTime, 'hh:mm A');
  const someday = moment(time, "hh:mm A");
  const dateDiffInMin = someday.diff(today, "minutes");

  const hours = Math.floor(dateDiffInMin / 60);
  const minutes = Math.abs(dateDiffInMin % 60);

  if(today.isAfter(someday) === true) {
    return ''
  }

  if (dateDiffInMin < 60) {
    return `(${Math.abs(minutes)} mins)`;
  } else {
    return `(${Math.abs(hours)}hr${minutes > 0 ? `${minutes}` : ""})`;
  }
};

const StyledMenu = styled((props: any) => (
  <Menu {...props} />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    '& .MuiList-root': {
      padding: '0'
    }
  },
}));

const CustomTimeRangePicker = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openStartDate, setOpenStartDate] = useState<any>(true);
  const [openEndDate, setOpenEndDate] = useState<any>(false);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpenStartDate(true)
    setOpenEndDate(false)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const [startTime, setStartTime] = useState<any>(moment(props.startTime).format('hh:mm A'))
  const [endTime, setEndTime] = useState<any>(moment(props.endTime).format('hh:mm A'))

  const handleStartTimeChange = (time: string) => {
    setOpenStartDate(false)
    setOpenEndDate(true)
    setStartTime(time)
    setEndTime(time)
    props.onStartTimeChange(time)
    if (props.hideEndTime === true) {
      handleClose()
    }
  }

  const handleEndTimeChange = (time: string) => {
    setOpenEndDate(false)
    setEndTime(time)
    props.onEndTimeChange(time)
    handleClose()
  }

  return (<>
      {props.hideEndTime === true ? 
        <Box className={Style.timeDisplay} onClick={handleClick} width={'110px'}>{startTime}</Box> :
        <Box className={Style.timeDisplay} onClick={handleClick} width={'200px'}>{startTime} - {endTime}</Box>}
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{zIndex: '9999', borderRadius: '10px'}}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box className={Style.popperDropdown} width={props.hideEndTime === true ? 'auto' : '242px'}>
          <Box className={Style.timePickerDropdown}>
            <Box 
              className={Style.timeText} 
              onClick={() => {
                setOpenStartDate(true)
                setOpenEndDate(false)
              }}
            >{moment(startTime, 'hh:mm A').format('h:mm A')} <img src={downArrow} /></Box>
            {openStartDate === true && 
              <Box className={`${Style.timeList} ${Style.startTimeList}`} component='ul'>
                {timeOptions.map((time, index) => (
                  <MenuItem 
                    key={index} 
                    value={time}
                    onClick={() => handleStartTimeChange(time)}
                    autoFocus={startTime === time}
                    className={startTime === time ? Style.selectedLink : ''}
                  >{time}</MenuItem>
                ))}
              </Box>
            }
          </Box>
          {props.hideEndTime !== true &&
            <Box className={Style.timePickerDropdown}>
              <Box 
                className={`${Style.timeText} ${Style.endTime}`} 
                onClick={() => {
                  setOpenStartDate(false)
                  setOpenEndDate(true)
                }}
              >{moment(endTime, 'hh:mm A').format('h:mm A')} <img src={downArrow} /></Box>
              {openEndDate === true && 
                <Box className={`${Style.timeList} ${Style.endTimeList}`}>
                  {timeOptions.map((time, index) => (
                    <MenuItem 
                      key={index} 
                      value={time}
                      onClick={() => handleEndTimeChange(time)}
                      autoFocus={endTime === time}
                      className={endTime === time ? Style.selectedLink : ''}
                      disabled={moment(startTime, 'hh:mm A').isAfter(moment(time, 'hh:mm A'))}
                    >{time} <span>{formatDateTimeAgo(startTime, time)}</span></MenuItem>
                  ))}
                </Box>
              }
            </Box>
          }
        </Box>
      </StyledMenu>
  </>);
};

export default CustomTimeRangePicker;