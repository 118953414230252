import { values } from 'lodash';
import { createSelector } from 'reselect';
// Select entities from state
export const getFilmsData = (state: any) => state.film.films.data;

//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getFilmsForQuickQuote = createSelector(
  getFilmsData,
  (data) => values(data).reduce((filtered, film) => {
    const formattedFilm = ({
      film_id: film.id,
      name: film.name,
      price: film.price,
      shades: film.filmOptions.reduce((acc: any, option: any) => {
        if (option.metaKey === 'shades') {
          acc[option.id] = option.metaValue;
        }
        return acc;
      }, {})
    });

    if (film.for_automotive) {
      filtered.automotive.push(formattedFilm);
    }

    if (film.for_architectural) {
      filtered.architectural.push(formattedFilm);
    }

    if (film.for_marine) {
      filtered.marine.push(formattedFilm);
    }

    if (film.for_custom) {
      filtered.custom.push(formattedFilm);
    }

    return filtered;
  }, {
    automotive: [],
    architectural: [],
    marine: [],
    custom: []
  }));

export const getFilms = createSelector(
  getFilmsData,
  (data) => values(data).reduce((filtered, film) => {
    if (film.is_kepler) {
      film.for_automotive && filtered.kepler.automotive.push(film);
      film.for_architectural && filtered.kepler.architectural.push(film);
      film.for_marine && filtered.kepler.marine.push(film);
      film.for_custom && filtered.kepler.custom.push(film);
    } else {
      film.for_automotive && filtered.others.automotive.push(film);
      film.for_architectural && filtered.others.architectural.push(film);
      film.for_marine && filtered.others.marine.push(film);
      film.for_custom && filtered.others.custom.push(film);
    }

    return filtered;
  }, {
    kepler: {
      automotive: [],
      architectural: [],
      marine: [],
      custom: []
    },
    others: {
      automotive: [],
      architectural: [],
      marine: [],
      custom: []
    }
  })
);