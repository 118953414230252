import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface CounterState {
  contactId: any;
  entities: any;
  meta: any;
  refresh: any;
  activeTab: any;
  contactTab: any;
  isAddProject: any;
  tabData: any;
  overviewData: any;
  project: any;
}

const initialState: CounterState = {
  contactId: null,
  entities: null,
  meta: null,
  refresh: false,
  isAddProject: false,
  activeTab: null,
  tabData: null,
  contactTab: [
    {
      name: "Overview",
      key: "overview",
      selected: false,
    },
    {
      name: "Projects",
      key: "projects",
      selected: false,
      isDots: false,
    },
    {
      name: "Contact",
      key: "contact",
      selected: false,
    },
    {
      name: "Campaigns",
      key: "campaigns",
      selected: false,
    },
    {
      name: "Invoices",
      key: "invoices",
      selected: false,
    },
    {
      name: "Timeline History",
      key: "timeline-history",
      selected: false,
    },
    {
      name: "Reviews",
      key: "reviews",
      selected: false,
    },
  ],
  project: null,
  overviewData: null,
};

export const viewContactReducer = createSlice({
  name: "viewContact",
  initialState,
  reducers: {
    contactRequest: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data;
      state.meta = action.payload.meta;
      state.contactId = action.payload.data.id;
      state.contactTab[1].isDots = false;
      if (
        action.payload.data.projects.some(
          (item: any) => item.status === "New" || item.status === "Draft"
        )
      ) {
        state.contactTab[1].isDots = true;
      }
    },
    getTabData: (state, action: PayloadAction<any>) => {
      let response = action.payload.data;
      if (!response) {
        response = action.payload;
      }
      state.tabData = response;
    },
    getOverviewData: (state, action: PayloadAction<any>) => {
      state.overviewData = action.payload.data;
    },
    handleAddProject: (state) => {
      state.isAddProject = true;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      const key = action.payload;
      const index = state.contactTab.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.contactTab[index];
        state.contactTab = state.contactTab.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.contactTab[0];
        state.contactTab[0].selected = true;
      }
    },
    projectView: (state, action: PayloadAction<any>) => {
      state.project = action.payload.data;
    },
    updateSelectedContact: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data;
    },
    resetContact: (state) => {
      state.entities = initialState.entities;
      state.meta = initialState.meta;
      state.contactId = initialState.contactId;
      state.contactTab[1].isDots = false;
    },
    resetOverviewData: (state) => {
      state.overviewData = initialState.overviewData;
    },
    resetProjectView: (state) => {
      state.project = initialState.project;
    },
  },
});

export const {
  contactRequest,
  refreshRequest,
  selectTabs,
  handleAddProject,
  getTabData,
  getOverviewData,
  projectView,
  updateSelectedContact,
  resetContact,
  resetOverviewData,
  resetProjectView
} = viewContactReducer.actions;

export default viewContactReducer.reducer;
