import { StyleOverrides } from "../styles/StyleOverrides";

export const compStyleOverride = () => ({
  MuiCssBaseline: {
    styleOverrides: StyleOverrides(),
  },
  MuiList: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        padding: 10,
        "& .MuiMenuItem-root": {
          borderRadius: 8,
          color: '#282828',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '138%'
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        "& .MuiFormControlLabel-label": {
          fontSize: "11px",
          fontWeight: 500,
          color: '#000'
        },
      },
    },
  },
});
