import { Box, CircularProgress } from "@mui/material";
const styles = {
  circularProgress: {
    position: "fixed",
    textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};

function Loading() {
  return (
    <Box sx={styles.circularProgress}>
      <CircularProgress
        size={90}
        thickness={1}
        color="primary"
      />
    </Box>
  );
}

export default Loading;
