import { Box } from "@mui/material";
import Style from "./InputTooltip.module.scss";
import ToolTipInfoIcon from "./ToolTipInfoIcon";

type OptionData = {
  text: any;
  color?: string;
  position?: string;
};

function InputTooltip({ text, color = '#292D32', position = 'left' }: OptionData) {
  return (
    <Box className={Style.tooltipIcon}>
      <ToolTipInfoIcon fill={color} height={'15'} width={'15'} />
      <Box className={Style.tooltipText} sx={{ right: position === 'right' ? 0 : 'inherit' }}>{text}</Box>
    </Box>
  );
}

export default InputTooltip;
