import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function AddContactDetailIcon(props) {
  return (
    // <SvgIcon {...props} viewBox="0 0 25 18">
    //   <path d="M16.5054 10.296H19.5968C19.8863 10.296 20.1639 10.181 20.3686 9.97635C20.5733 9.77166 20.6883 9.49405 20.6883 9.20458V6.11314C20.6883 5.82367 20.5733 5.54606 20.3686 5.34137C20.1639 5.13669 19.8863 5.0217 19.5968 5.0217H16.5054C16.2159 5.0217 15.9383 5.13669 15.7336 5.34137C15.5289 5.54606 15.4139 5.82367 15.4139 6.11314V9.20458C15.4139 9.49405 15.5289 9.77166 15.7336 9.97635C15.9383 10.181 16.2159 10.296 16.5054 10.296ZM23.2157 0.285156H1.38685C1.09738 0.285156 0.819771 0.400147 0.615086 0.604832C0.410401 0.809517 0.29541 1.08713 0.29541 1.3766V16.6568C0.29541 16.9463 0.410401 17.2239 0.615086 17.4285C0.819771 17.6332 1.09738 17.7482 1.38685 17.7482H23.2157C23.5052 17.7482 23.7828 17.6332 23.9875 17.4285C24.1921 17.2239 24.3071 16.9463 24.3071 16.6568V1.3766C24.3071 1.08713 24.1921 0.809517 23.9875 0.604832C23.7828 0.400147 23.5052 0.285156 23.2157 0.285156ZM23.0232 16.6568H1.71971V1.3766H23.2157L23.0232 16.6568Z" fill="#white" />
    //   <path d="M3.98792 5.2373H13.1637V6.69135H3.98792V5.2373Z" fill="#white" />
    //   <path d="M3.98792 9.43732H13.1637V10.8914H3.98792V9.43732Z" fill="#white" />
    // </SvgIcon>

    <SvgIcon {...props} viewBox="0 0 27 19">
        <rect x="16.7578" y="5" width="4.7546" height="5" rx="1" fill="#757575" strokeWidth="1.5" />
        <rect x="5.17969" y="5" width="8" height="1" rx="0.5" fill="#757575" strokeWidth="1.5" />
        <rect x="5.17969" y="9" width="8" height="1" rx="0.5" fill="#757575" strokeWidth="1.5" />
        <rect x="0.929688" y="0.75" width="24.5" height="17.5" rx="4.25" strokeWidth="2" />
    </SvgIcon>
  );
}

export default AddContactDetailIcon;
