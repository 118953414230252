import { Box, CircularProgress } from "@mui/material";

function InnerLoader({ height = '100vh' }) {
  const styles = {
    circularProgress: {
      position: "absolute",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#ffffffbf",
      zIndex: 8,
      minHeight: height,
      height: 'auto'
    }
  };
  return (
    <Box sx={styles.circularProgress}>
      <CircularProgress
        size={90}
        thickness={1}
        color="primary"
      />
    </Box>
  );
}

export default InnerLoader;
