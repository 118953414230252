import apiClient from "../client";

export default class LeadApi {
  static getLeads(params: any) {
    return apiClient.get("/leads", { params });
  }

  static getReadLeads(para: any) {
    return apiClient.get("/leads/read", {
      params: para,
    });
  }
  static getTabDetails() {
    return apiClient.get("/leads/get-tab-details");
  }

  static getUnreadLeads(params: any) {
    return apiClient.get("/leads/unread", params);
  }

  static getUsers() {
    return apiClient.get("/user", {
      params: {
        paginate: 0,
      },
    });
  }

  static markRead(id: any) {
    return apiClient.put(`/leads/${id}/lead-mask-as-read`);
  }
}
