import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AllAppointments from "assets/tabs/all-appointment.svg";
import AwaitingAppointment from "assets/tabs/awaiting-appointment.svg";
import CheckedInAppointment from "assets/tabs/checked-in-appointment.svg";
import CompletedAppointment from "assets/tabs/completed-appointment.svg";

interface IFWorkState {
  meta: any;
  refresh: any;
  workTabs: any;
  activeTab: any;
  taskAmounts: any;
  taskCounts: any;
}

const initialState: IFWorkState = {
  refresh: false,
  meta: null,
  activeTab: {
    name: "All Appointments",
    count: 0,
    key: "all",
    image: AllAppointments,
    selected: false,
  },
  workTabs: [
    {
      name: "All Appointments",
      count: 0,
      key: "all",
      image: AllAppointments,
      selected: false,
    },
    {
      name: "Awaiting",
      count: 0,
      key: "awaiting",
      image: AwaitingAppointment,
      selected: false,
    },
    {
      name: "Checked-In",
      count: 0,
      key: "checked_in",
      image: CheckedInAppointment,
      selected: false,
    },
    {
      name: "Completed",
      count: 0,
      key: "completed",
      image: CompletedAppointment,
      selected: false,
    }
  ],
  taskAmounts: {},
  taskCounts: {},
};

export const workReducer = createSlice({
  name: "works",
  initialState,
  reducers: {
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      const key = action.payload;
      const index = state.workTabs.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.workTabs[index];
        state.workTabs = state.workTabs.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.workTabs[0];
        state.workTabs[0].selected = true;
      }
    },
    setTaskAppointmentCount: (state, action: PayloadAction<any>) => {
      state.workTabs = state.workTabs.map((item: any, i: any) => ({
        ...item,
        count: action.payload[`${item.key}_appointments_count`]
      }));
      state.taskCounts = action.payload
    },
    setTaskAppointmentAmounts: (state, action: PayloadAction<any>) => {
      state.taskAmounts = action.payload;
    }
  },
});

export const {
  refreshRequest,
  selectTabs,
  setTaskAppointmentCount,
  setTaskAppointmentAmounts
} = workReducer.actions;

export default workReducer.reducer;
