import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import AuthLoading from "./AuthLoading";
const Application = () => {
  const isAuth: any = useAppSelector((state: any) => state.auth.isAuth);
  const isLoading: any = useAppSelector((state) => state.loading.isLoading);

  if (isAuth === null || isLoading) {
    return <AuthLoading />;
  }
  if (isAuth) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default Application;
