import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Header from "../components/Header/Header";
import SideMenu from "../components/SideMenu/SideMenu";
import { Outlet, useLocation } from "react-router-dom";
import { constant } from "utils/constant";
import { themeColor } from "theme/color";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PageHeader from "components/PageHeader/PageHeader";
import Loading from "components/Loading";
import InnerLoader from "components/Loading/InnerLoader";
import { changeBgColor } from "redux/reducers/uiReducer";

const PublicLayout = ({ children }: any) => {
  const location = useLocation();
  const ref: any = useRef(null);
  const isOpne = useAppSelector((state) => state.ui.isOpen);
  const isLoading = useAppSelector((state) => state.loading.isInnerLoading);
  const bgColor = useAppSelector((state) => state.ui.bgColor);
  const dispatch = useAppDispatch();
  const getWidth = () => {
    if (isOpne) {
      return constant.sideMenu + constant.menuWidth;
    }
    return constant.sideMenu;
  };

  return (
    <Box bgcolor={bgColor} className="bodyContainer">
      {isLoading && <InnerLoader />}
      <Outlet />
    </Box>
  );
};

export default PublicLayout;
