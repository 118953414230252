import { useEffect, useState } from 'react';
import {
  InputAdornment,
  Typography,
  FormGroup,
  FormControlLabel,
  Box,
  MenuItem
} from '@mui/material';
import TextInput from "components/Ui/TextInput/TextInput";
import styles from "./Application.module.scss";
import { useAppSelector } from 'redux/hooks';
import BpCheckbox from "components/Ui/CheckBox/BpCheckbox";
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import SquareIcon from 'assets/icon/add_square_blue.svg';
import ImageIcon from "components/ImageIcon/ImageIcon";
import iconClose from 'assets/icon/close_circle_dark.svg'
import iconSave from 'assets/icon/save_tick.svg';
import ScraperIcon from 'assets/icon/scraper.svg';
import { getFilmsForQuickQuote } from 'redux/selectors/filmSelectors';
import _ from 'lodash'
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import PriceBreakdown from "./PriceBreakdown";

interface ApplicationDetailProps {
  job: any,
  index: number,
  handleUpdateJob: any,
  handleUpdateService: any,
  validationError: any,
  messageForCustomer?: any
}

function ArchitecturalApplicationDetail({
  job, index, handleUpdateJob, handleUpdateService, validationError, messageForCustomer
}: ApplicationDetailProps) {
  const {
    services,
    tax_exempt,
    price_options,
    service_type
  } = job;

  const {
    measurements = [{
      number_of_windows: 1,
      width: 0,
      height: 0,
      removal_required: false,
      sqft: 0,
    }],
    type,
    price = 0.0,
    notes = {},
    service_title,
    discount,
    discount_price = 0,
    film_removal_option,
    add_notes,
    round_up_sqft,
    removal_sqft = 0,
    total_windows,
    total_sqft,
    price_per_sqft,
    discount_price_per_sqft = 0,
    film_options: selectedFilmOptionDetails,
    film_type
  } = services[index];

  const defaultMeasurement: any = {
    number_of_windows: 1,
    width: 0,
    height: 0,
    removal_required: false,
    sqft: 0,
  };
  const [selectedFilmType, setSelectedFilmType] = useState(film_type || '');
  const [selectedFilmOption, setSelectedFilmOption] = useState<any>(selectedFilmOptionDetails || []);
  const [priceBreakdown, setPriceBreakdown] = useState(false);
  const [editMeasurementRowIndex, setEditMeasurementRowIndex] = useState<any>(measurements.length === 1 ? 0 : -1);
  const films: any = useAppSelector(state => getFilmsForQuickQuote(state).architectural);

  const film_options = films.reduce((acc: any, film: any) => {
    const shades = Object.entries(film.shades).map(([key, value]) => {
      const fimOption = {
        name: `${film.name} ${value}`,
        film_id: film.film_id,
        film_shade_id: key
      };
      return fimOption;
    });
    return [...acc, ...shades];
  }, []);

  const getSqft = (width: any, height: any, number_of_windows = 1) => {
    const SQFT_TO_SQIN_RATIO = 144;
    const sqin = parseFloat(width) * parseFloat(height);
    let sqft = (sqin / SQFT_TO_SQIN_RATIO) * number_of_windows;
    sqft = _.isNaN(sqft) ? 0 : sqft
    return Number(sqft.toFixed(2));
  };

  const handleAddMeasurement = () => {
    const updatedMeasurements = [...services[index].measurements, cloneDeep(defaultMeasurement)];

    handleUpdateService(index, 'measurements', updatedMeasurements);
    setEditMeasurementRowIndex(updatedMeasurements.length - 1)
  }

  const handleUpdateMeasurement = (e: any, i: number, film_removal = false) => {
    if (e.target.value < 0) {
      return
    }
    const updatedMeasurements = cloneDeep(measurements);
    updatedMeasurements[i][e.target.name] = film_removal ? e.target.checked : e.target.value;
    updatedMeasurements[i].sqft = getSqft(updatedMeasurements[i].width, updatedMeasurements[i].height, updatedMeasurements[i].number_of_windows)

    handleUpdateService(index, 'measurements', updatedMeasurements);
  }

  const handleRemoveMeasurement = (i: number) => {
    const _measurements = cloneDeep(measurements);
    const updatedMeasurements = _measurements.filter((measurement: any, index: number) => index !== i)
    handleUpdateService(index, 'measurements', updatedMeasurements);
    setEditMeasurementRowIndex(-1)
  }

  const handleUpdateNote = (text: any) => {
    const note = { ...notes, text };
    handleUpdateService(index, 'notes', note);
  };

  useEffect(() => {
    if (service_type === 'Consultation') {
      handleUpdateService(index, 'add_notes', true);
    }
  }, [])

  const handleFilmOptionChange = (event: any, serviceIndex: number) => {
    const film_type = event.target.value;
    setSelectedFilmType(film_type);

    const updatedServices = services.map((service: any, i: number) => {
      return (i === index ? { ...service, film_type, film_options: [] } : service)
    });

    handleUpdateJob('services', updatedServices);
  };

  const handleSecondaryOptionChange = (event: any, serviceIndex: number) => {
    const film_shade_id = event.target.value;
    const option = film_options.filter((item: any) => item.film_shade_id === film_shade_id);
    setSelectedFilmOption(option);

    const updatedServices = services.map((service: any, i: number) => (i === serviceIndex ? { ...service, film_options: option } : service));
    handleUpdateJob('services', updatedServices);
  };

  const handleChangePrice = (name: string, value: any) => {
    const priceValue = (value < 0 || value === '') ? 0 : value
    const total_price = Number((Number(total_sqft || 0) * Number(priceValue))?.toFixed(2))

    const priceKey = name === 'discount_price_per_sqft' ? 'discount_price' : 'price'

    const updatedServices = services.map((service: any, i: number) => {
      if (i === index) {
        return {
          ...service,
          [name]: Number(priceValue),
          [priceKey]: total_price
        }
      }
      return service
    }).map((service: any) => {
      const discount = _.get(service, 'discount', false)
      const total_price_with_discount = discount === true ? _.get(service, 'discount_price', 0) : _.get(service, 'price', 0)
      const total_price_without_discount = _.get(service, 'price', 0)
      return {
        ...service,
        total_price_with_discount,
        total_price_without_discount
      }
    });

    handleUpdateJob('services', updatedServices);
  }

  const onPriceChange = (index: any, name: any, value: any) => {
    const priceValue = (value < 0 || value === '') ? 0 : value
    handleUpdateService(index, name, priceValue);
  }

  if (service_type === 'Consultation') {
    return (
      <Box px={0.8} py={2} >
        <Typography variant='lable'>{type} - Notes (Private)</Typography>
        <TextInput
          placeholder='Add Your Notes Here'
          value={notes?.text}
          name='notes'
          onChange={(e: any) => handleUpdateNote(e.target.value)}
          margin='dense'
          fullWidth
          multiline
          minRows={4}
        />
        <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].notes.text`], '')} />
      </Box>
    )
  }

  return (
    <div>
      {type === 'Architectural Window Tinting' &&
        <div className={styles.applicationAreaWrapper}>
          <div className={classNames(styles.serviceInputWrapper, styles.servicePadding)}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
              <Typography variant='subtitle1' fontWeight={600}>Window Measurements</Typography>
              <FormGroup row>
                <FormControlLabel
                  className={styles.BpCheckbox}
                  control={<BpCheckbox
                    checked={tax_exempt}
                    value={tax_exempt}
                    onChange={e => handleUpdateJob('tax_exempt', e.target.checked)} />}
                  label='Tax Exempt'
                />
                <FormControlLabel
                  className={styles.BpCheckbox}
                  control={<BpCheckbox
                    checked={price_options}
                    value={price_options}
                    onChange={e => handleUpdateJob('price_options', e.target.checked)} />}
                  label='Additional Options'
                />
              </FormGroup>
            </Box>
          </div>

          <Box>
            <Box className={styles.measurementTitle}>
              <Box className={styles.windows}>Windows</Box>
              <Box className={styles.measurement}>Width(in)</Box>
              <Box className={styles.measurement}> Height(in)</Box>
              <Box className={styles.measurement}>
                {film_removal_option &&
                  <span>Film Removal</span>
                }
              </Box>
              <Box className={styles.total_sqft}>Total SQFT</Box>
            </Box>
            {measurements.map((item: any, mi: number) =>
              <Box className={styles.measurementDetailsBorder}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} key={`measurements${mi}`}>
                  <Box className={styles.measurementDetails} onClick={() => setEditMeasurementRowIndex(mi)} flexGrow={1}>
                    <Box className={styles.windowsInput}>
                      {editMeasurementRowIndex === mi ?
                        <TextInput
                          type='number'
                          margin='dense'
                          name='number_of_windows'
                          value={item.number_of_windows}
                          className={styles.priceInput}
                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                          onChange={e => handleUpdateMeasurement(e, mi)}
                        /> :
                        <Typography className={styles.measurementList}>{item.number_of_windows}</Typography>}
                    </Box>
                    <Box className={styles.measurementInput}>
                      {editMeasurementRowIndex === mi ?
                        <TextInput
                          type='number'
                          margin='dense'
                          name='width'
                          value={item.width}
                          className={styles.priceInput}
                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                          onChange={e => handleUpdateMeasurement(e, mi)}
                        /> :
                        <Typography className={styles.measurementList}>{item.width}</Typography>}
                    </Box>
                    <Typography fontWeight={700}>x</Typography>
                    <Box className={styles.measurementInput}>
                      {editMeasurementRowIndex === mi ?
                        <TextInput
                          type='number'
                          margin='dense'
                          name='height'
                          value={item.height}
                          className={styles.priceInput}
                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                          onChange={e => handleUpdateMeasurement(e, mi)}
                        /> :
                        <Typography className={styles.measurementList}>{item.height}</Typography>}
                    </Box>
                    <Box className={styles.measurementInput}>
                      {film_removal_option &&
                        <FormControlLabel
                          className={styles.film_removal}
                          control={<BpCheckbox checked={item.removal_required}
                            defaultValue={item.removal_required}
                            name='removal_required'
                            onChange={e => handleUpdateMeasurement(e, mi, true)}
                          />}
                          label={<Box className={styles.flexWrapper}>
                            <img width='14px' src={ScraperIcon} alt="scraper" />
                            <span style={{ visibility: 'hidden' }}>dummy text</span>
                          </Box>
                          }
                        />
                      }
                    </Box>
                    <Box className={styles.totalSqftInput}>
                      <Typography className={styles.measurementList}>{item.sqft}</Typography>
                    </Box>
                  </Box>
                  {editMeasurementRowIndex === mi && <>
                    <ImageIcon style={{ cursor: 'pointer' }} src={iconSave} onClick={() => setEditMeasurementRowIndex(-1)} />
                    <ImageIcon style={{ cursor: 'pointer' }} src={iconClose} onClick={() => handleRemoveMeasurement(mi)} />
                  </>}
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].measurements[${mi}].height`], '')} />
                  <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].measurements[${mi}].width`], '')} />
                  <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].measurements[${mi}].number_of_windows`], '')} />
                  <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].measurements[${mi}].sqft`], '')} />
                </Box>
              </Box>
            )}
            <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].measurements`], '')} />
            <Box className={styles.addRow} onClick={handleAddMeasurement}>
              <img src={SquareIcon} alt='' className={styles.detailQuoteLink} />
              <Typography pl={2} className={styles.detailQuoteLink} >
                Add New Row
              </Typography>
            </Box>
          </Box>

          <Box pl={1}>
            <Box className={styles.measurementInfo}>
              <Typography variant='lable' fontWeight={590}>Windows</Typography>
              <Typography variant='lable' fontWeight={590}>Total SQFT</Typography>
              {film_removal_option &&
                <Typography variant='lable' fontWeight={590}>Removal SQFT</Typography>
              }
            </Box>
            <Box className={styles.measurementInfo}>
              <Typography variant='subTitle1' fontWeight={700}>{total_windows}</Typography>
              <Typography variant='subTitle1' fontWeight={700}>{total_sqft}</Typography>
              {film_removal_option &&
                <Typography variant='subTitle1' fontWeight={700}>{removal_sqft}</Typography>
              }
            </Box>
          </Box>

          <Box pt={5} pl={1} className={styles.filledSection}>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant='lable'>
                Assign Window Film
              </Typography>
              <TextInput
                onChange={(e) => handleFilmOptionChange(e, index)}
                name='selectedFilmType'
                size='small'
                value={selectedFilmType}
                margin='dense'
                select
                fullWidth>
                <MenuItem value="Kepler Window Film">Kepler Window Film</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextInput>
              <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].film_type`], '')} />
            </Box>
            {selectedFilmType === 'Kepler Window Film' && (
              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant='lable'>
                  Kepler Film Shades
                </Typography>
                <TextInput
                  onChange={(event) => handleSecondaryOptionChange(event, index)}
                  name='selectedFilmTypeOption'
                  size='small'
                  value={selectedFilmOption[0]?.film_shade_id || ''}
                  margin='dense'
                  select
                  fullWidth>
                  {film_options.map((option: any, fi: number) => (
                    <MenuItem key={fi} value={option.film_shade_id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextInput>
                <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].film_options`], '')} />
              </Box>
            )}

            {!discount && <>
              <Box className={styles.price_per_sqft}>
                <Typography variant='lable'>Price Per SQFT</Typography>
                <TextInput
                  type='number'
                  margin='dense'
                  name='price_per_sqft'
                  value={price_per_sqft === 0 ? '' : price_per_sqft}
                  className={styles.priceInput}
                  sx={{ '& .MuiInputBase-input': { paddingLeft: '0 !important' } }}
                  onChange={(e: any) => handleChangePrice('price_per_sqft', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>
                  }}
                />
                <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].price_per_sqft`], '')} />
              </Box>

              <Box className={styles.price_per_sqft}>
                <Typography variant='lable'>Total Price</Typography>
                <Box className={styles.arcPriceDisplay}>
                  <Typography variant='lable' fontWeight={700}>${price}</Typography>
                </Box>
              </Box>
            </>}
          </Box>

          {messageForCustomer && <Typography variant='subtitle1' mt={2}><strong>Customer message left on form: </strong>{messageForCustomer}</Typography>}
          {discount &&
            <Box pt={2} pl={1}>
              <Box className={styles.flexWrapper}>
                <Box className={styles.beforePrice}>
                  <Typography variant='lable'>Before Price Per SQFT</Typography>
                  <TextInput
                    type='number'
                    margin='dense'
                    name='price_per_sqft'
                    value={price_per_sqft === 0 ? '' : price_per_sqft}
                    className={styles.priceInput}
                    sx={{ paddingLeft: '0' }}
                    onChange={(e: any) => handleChangePrice('price_per_sqft', e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                  />
                  <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].price_per_sqft`], '')} />
                </Box>

                <Box ml={6} className={styles.beforePrice}>
                  <Typography variant='lable'>Before Total Price</Typography>
                  <Box className={styles.arcPriceDisplay}>
                    <Typography variant='lable' fontWeight={700}>${price}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box pt={1} className={styles.flexWrapper}>
                <Box className={styles.beforePrice} display={'flex'} flexDirection={'column'}>
                  <Typography variant='lable'>Discount Price Per SQFT</Typography>
                  <TextInput
                    type='number'
                    margin='dense'
                    name='discount_price_per_sqft'
                    value={discount_price_per_sqft === 0 ? '' : discount_price_per_sqft}
                    sx={{ paddingLeft: '0' }}
                    className={styles.discountPriceInput}
                    onChange={(e: any) => handleChangePrice('discount_price_per_sqft', e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                  />
                  <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].discount_price_per_sqft`], '')} />
                </Box>

                <Box ml={6} className={styles.beforePrice} display={'flex'} flexDirection={'column'}>
                  <Typography variant='lable'>Discount Total Price</Typography>
                  <Box className={styles.arcPriceDisplay}>
                    <Typography variant='lable' fontWeight={700}>${discount_price}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          }

          <Box className={styles.priceBreakdown} >
            <Typography pl={2} className={styles.detailQuoteLink} onClick={e => setPriceBreakdown(true)}>
              Price Breakdown
            </Typography>
          </Box>

          <div className={styles.applicationAreaWrapper}>

            {add_notes &&
              <Box pt={2}>
                <Typography variant='subtitle2'>Customer Note (Private)</Typography>
                <TextInput
                  placeholder='Add Your Notes Here'
                  value={notes?.text}
                  name='notes'
                  onChange={(e: any) => handleUpdateNote(e.target.value)}
                  margin='dense'
                  fullWidth
                  multiline
                  minRows={4}
                />
              </Box>
            }

            {price_options &&
              <Box py={4} pl={1}>
                <FormGroup row>
                  <FormControlLabel
                    control={<BpCheckbox
                      checked={add_notes}
                      value={add_notes}
                      onChange={e => handleUpdateService(index, 'add_notes', e.target.checked)} />}
                    label='Add Job Note'
                  />
                  <FormControlLabel
                    control={<BpCheckbox
                      checked={discount}
                      value={discount}
                      onChange={e => handleUpdateService(index, 'discount', e.target.checked)} />}
                    label='Discounted Price'
                  />
                  <FormControlLabel
                    control={<BpCheckbox
                      checked={film_removal_option}
                      value={film_removal_option}
                      onChange={e => handleUpdateService(index, 'film_removal_option', e.target.checked)} />}
                    label='Film Removal'
                  />
                  <FormControlLabel
                    control={<BpCheckbox
                      checked={round_up_sqft}
                      value={round_up_sqft}
                      onChange={e => handleUpdateService(index, 'round_up_sqft', e.target.checked)} />}
                    label='Round Up SQFT'
                  />
                </FormGroup>
              </Box>
            }

          </div>
        </div>
      }

      {
        type === 'Other' &&
        <Box mt={4}>
          <Typography variant='subtitle1' fontWeight={600}>Other Service </Typography>
          <div className={styles.applicationAreaWrapper}>
            <div className={styles.inputWrapper}>
              <Typography variant='subtitle2'>Service Title</Typography>
              <TextInput
                placeholder='Service Title'
                defaultValue={service_title}
                name='service_title'
                onChange={e => handleUpdateService(index, 'service_title', e.target.value)}
                margin='dense'
                fullWidth
              />
              <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].service_title`], '')} />
            </div>
            <div className={styles.inputWrapper} style={{ flexDirection: 'column', width: '30%' }}>
              <Typography variant='subtitle2'>Total Price</Typography>
              <TextInput
                type='number'
                margin='dense'
                name='price'
                value={price}
                onChange={e => { onPriceChange(index, 'price', e.target.value); }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>
                }}
              />
              <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].price`], '')} />
            </div>
            <div className={styles.inputWrapper}>
              <Typography variant='subtitle2'>Customer Note (Private)</Typography>
              <TextInput
                label='Customer Note (Private)'
                value={notes?.text}
                name='notes'
                onChange={(e: any) => handleUpdateNote(e.target.value)}
                margin='dense'
                fullWidth
                multiline
                minRows={4}
              />
              <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].notes`], '')} />
            </div>
          </div>
        </Box>
      }

      {priceBreakdown === true &&
        <PriceBreakdown
          priceBreakdown={priceBreakdown}
          setPriceBreakdown={setPriceBreakdown}
          mobile_install={false}
          film_removal_option={film_removal_option}
          job={job}
        />
      }
    </div>
  );
}

export default ArchitecturalApplicationDetail;
