import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Style from "./Tab.module.scss";
import { IoChevronDown } from "react-icons/io5";
import { themeColor } from "theme/color";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ContactAPI from "api/resources/contact";

const menuData = [
  {
    color: "#F8A94C",
    value: "vip",
    lable: "VIP",
  },
  {
    color: "#527cff",
    value: "loyal",
    lable: "Loyal",
  },
  {
    color: "#2A4496",
    value: "frequent",
    lable: "Frequent",
  },
  {
    color: "#FF6B00",
    value: "unreliable",
    lable: "Unreliable",
  },
  {
    color: "#CF3E00",
    value: "particular",
    lable: "Particular",
  },
  {
    color: "#EC2527",
    value: "complainer",
    lable: "Complainer",
  },
  {
    color: "#810000",
    value: "avoid",
    lable: "Avoid",
  },
];

function Behavior({ id, value }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [active, setActive] = useState<any>(value || '');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = async (item: any) => {
    const para = {
      behavior_tag: item.lable === active.lable ? "" : item.lable,
    };
    setActive(item.lable === active.lable ? "" : item);

    try {
      await ContactAPI.assignBehaviorTag(id, para);
    } catch (e) {
      console.log(e);
    }
    setAnchorEl(null);
  };
  useEffect(() => {
    if (value) {
      const index = menuData.findIndex((item) => item.lable === value);
      if (index !== -1) {
        setActive(menuData[index]);
      }
    }
  }, [value]);

  return (
    <>
      <Box
        className={Style.menu}
        bgcolor={active?.color || themeColor.blue}
        onClick={(e: any) => handleClick(e)}
      >
        {active?.lable && (
          <Typography variant="title4" fontWeight={600} color={"white"}>
            {active?.lable}
          </Typography>
        )}

        <IoChevronDown color="white" />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        autoFocus={false}
      >
        {menuData.map((item, i) => (
          <MenuItem
            selected={active?.lable === item.lable}
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: item.color,
                color: "#fff",
              },
              "&.Mui-selected": {
                backgroundColor: item.color,
                color: "#fff",
                "&:hover": {
                  backgroundColor: item.color,
                },
              },
            }}
            key={i}
            onClick={() => handleChange(item)}
          >
            {item.lable}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default Behavior;
