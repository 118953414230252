import React, { useEffect, useState } from "react";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Grid, Slider, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { MdOutlineClose } from "react-icons/md";
import Style from "./UpdateCover.module.scss";
import { ButtonBox, PrimaryButton } from "components/Ui/Button/Button";
import { FcMinus } from "react-icons/fc";
import { IoMdAdd } from "react-icons/io";
import { Stage, Layer, Image, Rect } from "react-konva";
import useImage from "use-image";
import Banner from "assets/banner.jpeg";
import LeadProfileApi from "api/resources/leadProfile";
import { toast } from "react-toastify";
import { httpService } from "helper/httpService";
import { refreshRequest } from "redux/reducers/leadProfileReducer";
function UpdateCover({ open, handleClose, type }: any) {
  const imgRef: any = React.useRef(null);
  const imageInput: any = React.useRef(null);
  const [coverImg, setCoverImg] = useState(Banner);
  const [canvas, setCanvas] = useState<any>(null);
  const [dragging, isDragging] = useState(false);
  const [coverList, setCoverList] = useState<any>([]);
  const [upload, isUpload] = useState(false);
  const [coverImage, setCoverImage] = useState<any>(null);
  const [axis, setAxis] = useState({
    x: 0,
    y: 0,
  });

  const [dimension, setDimension] = useState({
    width: 630,
  });
  const [value, setValue] = useState<number>(600);
  const [rectRatio, setRectRatio] = useState({
    width: 600,
    height: 260,
  });
  const [rect, setRect] = useState({
    width: 600,
    height: 260,
  });

  const [selected, isSelected] = useState(null);
  const [image]: any = useImage(coverImage);
  const handleImage = (e: any) => {
    setCoverImage(URL.createObjectURL(e.target.files[0]));
    isUpload(true);
  };

  useEffect(() => {
    if (image) {
      const containerWidth = dimension.width;
      const imgRation = image.width / image.height;

      const imgWidth = containerWidth;
      const imgHeight = containerWidth / imgRation;
      const reactRation = rectRatio.width / rectRatio.height;
      const reactWidth = value;
      const reactHeight = value / reactRation;

      const xOffset = imgWidth / 2 - reactWidth / 2;
      const yOffset = imgHeight / 2 - reactHeight / 2;

      setAxis({
        x: xOffset,
        y: yOffset,
      });
      setRect({
        width: reactWidth,
        height: reactHeight,
      });
      setCanvas({
        width: imgWidth,
        height: imgHeight,
      });
    }
  }, [image, value]);

  useEffect(() => {
    (async () => {
      const response = await LeadProfileApi.getImageList("Cover Picture");
      setCoverList(response.data.data);
    })();
  }, [upload]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const convertDataURLToFile = (dataURL: string): File => {
    const arr: any = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1] || "";
    const bstr: any = atob(arr[1]);
    let n: any = bstr.length;
    const u8arr: any = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([new Blob([u8arr], { type: mime })], "cover.png", {
      type: mime,
    });
  };

  const handleExport = async () => {
    if (imgRef) {
      // const uri = imgRef?.current?.toDataURL();
      const canvas = document.createElement("canvas");
      canvas.width = rect.width;
      canvas.height = rect.height;

      const context: any = canvas.getContext("2d");
      context.drawImage(
        image,
        axis.x,
        axis.y,
        rect.width,
        rect.height,
        0,
        0,
        rect.width,
        rect.height
      );
      const croppedImageData = canvas.toDataURL();

      const croppedFile = convertDataURLToFile(croppedImageData);
      console.log(croppedFile);
      const formData = new FormData();
      formData.append("uploads[0][file]", croppedFile);
      formData.append("uploads[0][type]", "Cover Picture");
      const response = await LeadProfileApi.postCoverImage(formData);
      isUpload(false);
    }
  };

  const handleCover = async () => {
    if (selected) {
      httpService(
        () => LeadProfileApi.uploadCoverPicture(type, selected),
        refreshRequest
      );
      handleClose();
    } else {
      toast.error("Select Cover Image");
    }
  };

  const handleDragEnd = (e: any) => {
    isDragging(false);

    const stageWidth = canvas.width;
    const stageHeight = canvas.height;

    const minX = 0;
    const minY = 0;
    const maxX = stageWidth - rect.width;
    const maxY = stageHeight - rect.height;

    let newX = e.target.x();
    let newY = e.target.y();

    if (newX < minX) {
      newX = minX;
    } else if (newX > maxX) {
      newX = maxX;
    }

    if (newY < minY) {
      newY = minY;
    } else if (newY > maxY) {
      newY = maxY;
    }
    setAxis({
      x: newX,
      y: newY,
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: "10px",
        },
      }}
    >
      <DialogContent>
        <Box>
          <Box onClick={() => handleClose()} className={Style.close}>
            <MdOutlineClose size={25} color="#A4A4A4" />
          </Box>
          <Box pb={2}>
            <Typography variant="title" className={Style.title}>
              Update Cover Picture
            </Typography>
            <Typography className={Style.subtitle}>
              Choose a cover picture or upload your own
            </Typography>
          </Box>
          {!upload && (
            <Box>
              <Grid container rowSpacing={2.5} columnSpacing={2.5}>
                {coverList &&
                  coverList.length > 0 &&
                  coverList.map((item: any, i: any) => (
                    <Grid item md={6} key={i}>
                      <Box
                        className={`${Style.ImageSelect} ${
                          item.id === selected && Style.slected
                        }`}
                        onClick={() => isSelected(item.id)}
                      >
                        <img src={item.url} className={Style.imageSize} />
                      </Box>
                    </Grid>
                  ))}
                <Grid item md={6}>
                  <ButtonBox
                    className="dark"
                    onClick={() => imageInput.current.click()}
                  >
                    Upload Image
                  </ButtonBox>
                  <input
                    style={{ display: "none" }}
                    ref={imageInput}
                    type="file"
                    accept="image/jpg"
                    onChange={handleImage}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {upload && (
            <Box>
              {canvas && (
                <Stage width={canvas.width} height={canvas.height}>
                  <Layer>
                    <Image
                      width={canvas.width}
                      height={canvas.height}
                      image={image}
                      x={0}
                      y={0}
                    />
                  </Layer>
                  <Layer ref={imgRef}>
                    <Image
                      width={canvas.width}
                      height={canvas.height}
                      image={image}
                      x={0}
                      y={0}
                    />
                    <Rect
                      width={rect.width}
                      height={rect.height}
                      x={axis.x}
                      y={axis.y}
                      fill="#fff"
                      globalCompositeOperation="destination-atop"
                    />
                  </Layer>
                  <Layer>
                    <Rect
                      width={rect.width}
                      height={rect.height}
                      x={axis.x}
                      y={axis.y}
                      strokeWidth={5}
                      stroke="#fff"
                      draggable
                      onDragStart={() => {
                        isDragging(true);
                      }}
                      onDragEnd={(e) => {
                        handleDragEnd(e);
                      }}
                    />
                  </Layer>
                </Stage>
              )}

              <Box pt={2}>
                <Stack spacing={2} direction="row" alignItems="center">
                  <FcMinus size={25} color="#000" />
                  {canvas && (
                    <Slider
                      aria-label="Zoom"
                      value={value}
                      max={canvas.width}
                      onChange={handleChange}
                    />
                  )}

                  <IoMdAdd size={25} color="#000" />
                </Stack>
              </Box>
            </Box>
          )}

          <Box pt={2.5}>
            <PrimaryButton
              type="submit"
              fullWidth
              onClick={() => (upload ? handleExport() : handleCover())}
            >
              Save Changes
            </PrimaryButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateCover;
