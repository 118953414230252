import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { themeColor } from "theme/color";
import { handleColor } from "./Tage";

export const PrimaryButton = styled(Button)(({ theme }) => ({
  color: themeColor.white,
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  background: themeColor.primaryGray,
  borderRadius: 7,
  padding: "10px 20px",
  "&:hover": {
    background: themeColor.primaryGray,
  },
  "&.outlined": {
    background: "transparent",
    color: themeColor.white,
    border: "1px solid #777777",
  },
}));

export const SecondryButton = styled(Button)(({ theme }) => ({
  color: themeColor.white,
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  background: themeColor.secondary,
  borderRadius: 7,
  padding: "10px 20px",
  "&:hover": {
    background: themeColor.secondary,
  },
  "&.outlined": {
    background: "transparent",
    color: "#000",
    border: "1px solid #777777",
  },
}));

export const ErrorButton = styled(Button)(({ theme }) => ({
  color: themeColor.white,
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  background: themeColor.delete,
  borderRadius: 7,
  padding: "10px 20px",
  "&:hover": {
    background: themeColor.delete,
  },
  "&.outlined": {
    background: "transparent",
    color: "#000",
    border: "1px solid #777777",
  },
}));

export const DarkButton = styled(Button)(({ theme }) => ({
  color: themeColor.white,
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  background: themeColor.black,
  borderRadius: 7,
  padding: "10px 20px",
  boxShadow: 'none',
  "&:hover": {
    background: '#642062',
    boxShadow: 'none'
  },
  "&.outlined": {
    background: "transparent",
    color: "#000",
    border: "1px solid #777777",
  },
}));

export const GreenButton = styled(Button)(({ theme }) => ({
  color: themeColor.white,
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  background: themeColor.paid,
  borderRadius: 7,
  padding: "12px 36px",
  "&:hover": {
    background: themeColor.paid,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  "&.outlined": {
    background: "transparent",
    color: "#000",
    border: "1px solid #777777",
  },
}));

export const ButtonBox = styled(Button)(({ theme, className }) => ({
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  borderRadius: 7,
  padding: "10px 20px",
  ...handleColor(className),
  "&:hover": {
    background: handleColor(className).background,
  },
  "&.outlined": {
    background: "transparent",
    color: "#000",
    border: "1px solid #777777",
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  color: themeColor.white,
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  borderRadius: 7,
  padding: "10px 36px",
  "&:hover": {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  "&.outlined": {
    color: "#000",
    border: "1px solid #777777",
  },
}));

export const CustomBox = styled(Button)(({
  theme, className
}) => {
  return {
    textTransform: "capitalize",
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "8px",
    padding: "10px 38px",
    ...handleColor(className),
    "&:hover": {
      ...handleColor(className),
    },
    "&.outlined": {
      ...handleColor(className),
      border: "1px solid #777777",
    }
  }
});

export const LinkButton = styled(Button)(({
  theme, className
}) => {
  return {
    textTransform: "capitalize",
    fontSize: "11px",
    fontWeight: 500,
    padding: 0,
    ...handleColor(className),
    "&:hover": {
      ...handleColor(className)
    },
    "&.outlined": {
      ...handleColor(className),
      border: "1px solid #777777",
    }
  }
});

export const TextButton = styled(Button)(({
  theme, className
}) => {
  return {
    textTransform: "capitalize",
    fontSize: "11px",
    fontWeight: 600,
    color: handleColor(className).background,
    background: "transparent",
    "&:hover": {
      color: handleColor(className).background,
      background: "transparent",
    },
    "&.outlined": {
      color: handleColor(className).background,
      background: "transparent",
      border: "1px solid #777777",
    }
  }
});

export const RoundButton = styled(Button)(({ theme, className }) => {
  return {
    borderRadius: '13px',
    padding: '13px',
    background: '#2DD5C4',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '15px',
    minWidth: '150px',
    textTransform: "capitalize",
    "&:hover": {
      color: '#FFF',
      background: "#2DD5C4",
    },
    "&.outlined": {
      color: '#FFF',
      background: "#2DD5C4",
    }
  }
});