// eslint-disable-next-line import/no-anonymous-default-export
export const constant = {
  height: 80,
  sideMenu: 90,
  menuWidth: 130,
};

export const currencies = [
  { name: 'US Dollar', code: 'USD', symbol: '$' },
  { name: 'Canadian Dollar', code: 'CAD', symbol: '$' },
  { name: 'Australian Dollar', code: 'AUD', symbol: '$' },
  { name: 'British Pound Sterling', code: 'GBP', symbol: '£' }
];

export const CountryCodes = [
  { label: 'USA', value: '1' }
];

export const Roles = {
  SuperAdmin: 'super_admin',
  GoldAdmin: 'gold_admin',
  Admin: 'admin',
  Consultant: 'consultant',
  Installer: 'installer'
};