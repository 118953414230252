import SvgIcon from '@mui/material/SvgIcon';

function AddContactIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 21">
      <path d="M7.49357 9.45379C7.39826 9.44426 7.28389 9.44426 7.17905 9.45379C4.91069 9.37755 3.10938 7.51905 3.10938 5.23166C3.10938 2.89662 4.99647 1 7.34108 1C9.67611 1 11.5727 2.89662 11.5727 5.23166C11.5632 7.51905 9.76189 9.37755 7.49357 9.45379Z
      M2.72983 12.9702C0.423388 14.5142 0.423388 17.0303 2.72983 18.5648C5.3508 20.3184 9.6492 20.3184 12.2702 18.5648C14.5766 17.0208 14.5766 14.5047 12.2702 12.9702C9.65873 11.2261 5.36033 11.2261 2.72983 12.9702Z"
        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default AddContactIcon;
