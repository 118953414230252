import { Box, Grid, Typography } from "@mui/material";
import { HiOutlinePlayCircle } from "react-icons/hi2";
import Style from "./Projects.module.scss";
import ModalVideo from "components/Modal/ModalVideo/ModalVideo";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";
const array = [
  {
    name: "Custom Cuts",
    img: "/assets/images/service/custom-cuts.jpeg",
  },
  {
    name: "Mobile Service",
    img: "/assets/images/service/mobile-service.jpeg",
  },
  {
    name: "Elite Service",
    img: "/assets/images/service/elite-service.jpeg",
  },
  {
    name: "Computer-Cut",
    img: "/assets/images/service/computer-cut.jpeg",
  },
];

function Highlights() {
  const [isView, setIsView] = useState(false);
  const [video, setVideo] = useState("");
  const handleModel = (item: any) => {
    // setVideo(item.link);
    setVideo("https://www.youtube.com/embed/9xwazD5SyVg");
    setIsView(true);
  };

  const data = useAppSelector((state: any) => state.leadProfile.data);


  return (
    <Box className={Style.container}>
      <Box>
        <Box className={Style.flex} pb={2}>
          <Typography className={Style.text1}>
            View Quick Our Highlights
          </Typography>
          <HiOutlinePlayCircle size={20} />
        </Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {data &&
            data?.standout_images.length > 0 &&
            data.standout_images.map((item: any, index: any) => (
              <Grid
                item
                md={3}
                key={index}
                onClick={() => handleModel(item)}
                className="cursor"
              >
                <Box className={Style.imgBox}>
                  <img src={item.thumbnail} alt={item.name} />
                  <ReactSVG
                    src="/assets/images/leadProfile/play.svg"
                    className={Style.playIcon}
                  />
                </Box>
                <Typography className={Style.imgTitle}>{item.title}</Typography>
              </Grid>
            ))}
        </Grid>
      </Box>
      <ModalVideo open={isView} close={setIsView} video={video} />
    </Box>
  );
}

export default Highlights;
