import {
  Box,
  FormControlLabel, IconButton, MenuItem, Typography
} from '@mui/material';
import CloseIcon from 'assets/icon/close_circle.svg';
import ScraperIcon from 'assets/icon/scraper.svg';
import { entries } from 'lodash';
import { HiOutlineChevronDown } from "react-icons/hi";
import { useAppSelector } from 'redux/hooks';
import { getFilmsForQuickQuote } from 'redux/selectors/filmSelectors';
import uuid4 from 'uuid4';
import BpCheckbox from "../../../components/Ui/CheckBox/BpCheckbox";
import TextInput from "../../../components/Ui/TextInput/TextInput";
import styles from './Film.module.scss';

interface SelectFilmProps {
  application: any,
  handleRemove: any,
  handleChange: any,
  handleUpdate: any,
  index: any,
  service: any,
  serviceIndex?: any
  className?: string
}

function SelectFilm({
  application,
  handleRemove,
  handleChange,
  index,
  service,
  serviceIndex = 0,
  className = ''
}: SelectFilmProps) {
  const films: any = useAppSelector(state => getFilmsForQuickQuote(state).automotive);
  const film_id = application.films[0]?.film_id;
  let shades = application.films[0]?.shades;
  if (film_id && shades !== '') {
    const filmData = films.filter((film: any) => film.film_id === film_id);
    shades = filmData[0]?.shades;
  }

  const { film_removal_option = false } = service;

  const handleUpdateApplication = (filmIndex: number, event: any) => {
    event.stopPropagation();
    const film = { ...application.films[filmIndex] };
    film[event.target.name] = event.target.value;
    const updatedFilms = application.films.map((item: any, i: number) => (i === filmIndex ? film : item));

    handleChange(index, { ...application, films: updatedFilms }, serviceIndex);
  };

  const handleFilmRemoval = (value: any) => {
    const updatedApplication = { ...application, film_removal: value, removal_required: value };
    handleChange(index, updatedApplication, serviceIndex);
  };

  const handleRemoveApplication = (filmIndex: number) => {
    console.log('here remove application', filmIndex, application.films)
    if (application.films.length > 1) {
      const films = [...application.films];
      films.splice(filmIndex, 1);
      handleChange(index, { ...application, films }, serviceIndex);
    } else {

      handleRemove(application, index, serviceIndex);
    }
  };

  const handleChangeFilm = (event: any, filmIndex: number) => {
    event.stopPropagation();
    console.log(event.target.value)
    const updatedFilms = application.films.map((item: any, i: number) => (i === filmIndex ? event.target.value : item));
    handleChange(index, { ...application, films: updatedFilms }, serviceIndex);
  };

  return (<Box className={styles[className]}>
      <div className={styles.wrapper}>
        <Box className={styles.flexWrapper}>
          <Typography variant='subtitle2' fontWeight={600}>{application.area}</Typography>
          {film_removal_option &&
            <FormControlLabel
              className={styles.film_removal}
              control={<BpCheckbox checked={application.film_removal}
                value={application.film_removal}
                onChange={(e: any) => handleFilmRemoval(e.target.checked)} />}
                label={<Box className={styles.flexWrapper}>
                <img width='14px' src={ScraperIcon} alt="scraper" />
                <span style={{ visibility: 'hidden' }}>dummy text</span>
              </Box>
              }
            />
          }
        </Box>
        <div className={styles.areaWrapper}>
          {application.films?.map((film: any, i: number) =>
            <div key={uuid4()} className={styles.filmWrapper}>
              <TextInput
                size='small'
                select
                name='name'
                className={styles.selectField}
                sx={{
                  '& .MuiInputBase-inputSizeSmall': {
                    color: className === 'modifyProject' ? '#1F1F1F' : '#969696',
                    fontSize: '11px',
                    fontWeight: 600,
                    padding: className !== '' ? '7px 13px' : undefined
                  },
                  '& .MuiSelect-icon': {
                    color: className === 'modifyProject' ? '#1F1F1F' : '#969696',
                  }
                }}
                value={film}
                SelectProps={{
                  renderValue: (item: any) => item.name === 'Unassigned' ? <span className={styles.selectFilmText}>Select film</span> : item.name,
                  IconComponent: HiOutlineChevronDown
                }}
                onChange={(e: any) => handleChangeFilm(e, i)}
              >
                {films.map((film: any) => (
                  <MenuItem value={film} key={uuid4()}>
                    {film.name}
                  </MenuItem>
                ))}
              </TextInput>
              {film.name !== 'Unassigned' &&
                <TextInput
                  select
                  size='small'
                  name='film_shade_id'
                  className={styles.selectField}
                  sx={{
                    '& .MuiInputBase-inputSizeSmall': {
                      color: className === 'modifyProject' ? '#1F1F1F' : '#969696',
                      fontSize: '11px',
                      fontWeight: 600,
                      padding: className !== '' ? '7px 13px' : undefined
                    },
                    '& .MuiSelect-icon': {
                      color: className === 'modifyProject' ? '#1F1F1F' : '#969696',
                    }
                  }}
                  value={film.film_shade_id || 'Unassigned'}
                  SelectProps={{
                    renderValue: (item: any) => {
                      const shade = entries(shades).find((fl) => Number(fl[0]) === Number(item));
                      return shade ? String(shade[1]) : <span className={styles.selectFilmText}>Select film Shade</span>;
                    },
                    IconComponent: HiOutlineChevronDown
                  }}
                  onChange={(e: any) => handleUpdateApplication(i, e)}
                >
                  {entries(shades)?.map((shade: any, i) => (
                    <MenuItem value={shade[0]} key={uuid4()}>
                      {shade[1]}
                    </MenuItem>
                  ))}
                </TextInput>
              }

              <IconButton onClick={() => handleRemoveApplication(i)} className={styles.cancelIcon}>
                <img src={CloseIcon} alt='' />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
}

export default SelectFilm;
