export const validateData = async (schema: any, data: any) => {
    try {
      const isFormValid = await schema.isValid(data, { abortEarly: false });
      if(isFormValid === true) {
        return { isValidate: true }
      } 
      await schema.validate(data, { abortEarly: false })
    } catch (err: any) {
      const errors = err.inner.reduce((acc: any, error: any) => {
        return {
          ...acc,
          [error.path]: error.errors[0]
        };
      }, {});
      return { isValidate: false, errors }
    }
  }