import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Combined from "assets/createForm/combined.svg";
import Automotive from "assets/createForm/automotive.svg";
import Architectural from "assets/createForm/architectural.svg";
import Message from "assets/createForm/mail.svg";
interface CounterState {
  entities: any;
  meta: any;
  refresh: any;
  activeTab: any;
  formsTabs: any;
  publicData: any;
}

const initialState: CounterState = {
  entities: null,
  meta: null,
  refresh: false,
  activeTab: null,
  publicData: false,
  formsTabs: [
    {
      name: "Automotive",
      key: "automotive",
      image: Automotive,
      selected: false,
    },
    {
      name: "Architectural",
      key: "architectural",
      image: Architectural,
      selected: false,
    },
    {
      name: "Combined",
      key: "combined",
      image: Combined,
      selected: false,
    },
    {
      name: "Message Only",
      key: "message",
      image: Message,
      selected: false,
    },
  ],
};

export const createFormReducer = createSlice({
  name: "createForm",
  initialState,
  reducers: {
    contactRequest: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data;
      state.meta = action.payload.meta;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    handlePublic: (state, action: PayloadAction<any>) => {
      state.publicData = action.payload;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      const key = action.payload;
      const index = state.formsTabs.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.formsTabs[index];
        state.formsTabs = state.formsTabs.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.formsTabs[0];
        state.formsTabs[0].selected = true;
      }
    },
  },
});

export const { contactRequest, refreshRequest, selectTabs, handlePublic } =
  createFormReducer.actions;

export default createFormReducer.reducer;
