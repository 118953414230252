import { createTheme } from "@mui/material/styles";

import { compStyleOverride } from "./compStyleOverride";
import { themeTypography } from "./typography";
import { themePalette } from "./palette";

// A custom theme for this app
const theme = createTheme({
  typography: themeTypography(),
  components: compStyleOverride(),
  palette: themePalette(),
});

export default theme;
