import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface CounterState {
  isLoading: boolean;
  isInnerLoading: boolean;
}

const initialState: CounterState = {
  isLoading: false,
  isInnerLoading: false,
};

export const loaderReducer = createSlice({
  name: "loading",
  initialState,
  reducers: {
    handleLoading: (state, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
    },
    handleInnerLoading: (state, action: PayloadAction<any>) => {
      state.isInnerLoading = action.payload;
    },
  },
});

export const { handleLoading, handleInnerLoading } = loaderReducer.actions;

export default loaderReducer.reducer;
