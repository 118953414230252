import { Avatar, Box, ListItemIcon, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import Menu, { MenuProps } from "@mui/material/Menu";
import styles from "./Header.module.scss";
import { themeColor } from "theme/color";
import Inbox from "assets/icon/inbox.svg";
import Profile from "assets/icon/profile.svg";
import Logout from "assets/icon/logout.svg";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { styled, alpha } from "@mui/material/styles";
import { handleLogOut } from "helper/AuthMethod";
import { useNavigate } from "react-router-dom";
import { HasPermission } from "components/HasPermission/HasPermission";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 8,
    minWidth: 150,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1.5),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

    "& .MuiMenu-list": {
      padding: "0px",
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 20,
      width: 16,
      height: 16,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
      backgroundColor: "#fff",
    },
    "& .MuiMenuItem-root": {
      padding: "8px 15px",
      backgroundColor: "#fff",
      "& img": {
        width: "22px",
        height: "22px",
        filter: "opacity(0.6)",
        objectFit: "contain",
      },
      "&:active": {
        borderRadius: 6,
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&:hover": {
        borderRadius: 6,
        backgroundColor: "#F3F3F3",
      },
      "&.Mui-selected": {
        borderRadius: 6,
        backgroundColor: "#F3F3F3",
      },
    },
  },
}));

function UserMenu() {
  const userData: any = useAppSelector((state) => state.auth.entities);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const theme = useTheme();
  // const media = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };
  const handleMenu = (menu: any) => (event: any) => {
    setOpenMenu(openMenu === menu ? null : menu);
    setAnchorEl(event.currentTarget);
  };

  const signOut = () => {
    handleLogOut();
  };
  return (
    <Box className={styles.userBox}>
      <Box
        textAlign={"right"}
        lineHeight={1.3}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Typography fontWeight={600} color="white" variant="title1">
          {userData?.first_name} {userData?.last_name}
        </Typography>
        <Typography color="white" variant="title3" sx={{ fontSize: "13px" }}>
          {userData?.organisation?.type}
        </Typography>
      </Box>
      <Avatar
        onClick={handleMenu("user-setting")}
        sx={{ bgcolor: themeColor.white, width: 50, height: 50 }}
        alt={`${userData?.first_name} ${userData?.last_name}`}
        // src="/assets/images/user/user.svg"
        src={userData?.profile_image}
      />
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={openMenu === "user-setting"}
        onClose={handleClose}
      >
        <HasPermission permission={['enable_personal_profile_modification']}>
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/settings/profile");
            }}
          >
            <ListItemIcon>
              <img src={Profile} alt="Profile" />
            </ListItemIcon>
            <Typography variant="userMenu">Profile</Typography>
          </MenuItem>
        </HasPermission>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/schedule/tasks");
          }}
        >
          <ListItemIcon>
            <img src={Inbox} alt="Inbox" />
          </ListItemIcon>
          <Typography variant="userMenu">Task</Typography>
        </MenuItem>
        <MenuItem onClick={() => signOut()}>
          <ListItemIcon>
            <img src={Logout} alt="log out" />
          </ListItemIcon>
          <Typography variant="userMenu">Log Out</Typography>
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}

export default UserMenu;
