import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import store from "redux/reducers";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const cache = createCache({
  key: "css",
  prepend: true,
});
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <CacheProvider value={cache}>
        <App />
      </CacheProvider>
    </Provider>
  </ThemeProvider>
);

reportWebVitals();
