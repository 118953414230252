import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Awaiting from "assets/tabs/awaiting.svg";
import Tick from "assets/tabs/profile-tick.svg";
import Delete from "assets/tabs/profile-delete.svg";
import Expired from "assets/tabs/profile-expired.svg";
import People from "assets/tabs/people.svg";

interface CounterState {
  entities: any;
  meta: any;
  refresh: any;
  leadTabs: any;
  activeTab: any;
}

const initialState: CounterState = {
  refresh: false,
  entities: null,
  meta: null,
  activeTab: null,
  leadTabs: [
    {
      name: "Awaiting Quotes",
      filterValue: "Awaiting Quote",
      key: "awaiting",
      headText: "Quote",
      isPrice: false,
      isMessage: true,
      image: Awaiting,
      selected: false,
    },
    {
      name: "Approved Leads",
      filterValue: "Approved",
      key: "approved",
      headText: "Approved",
      isPrice: true,
      isMessage: false,
      image: Tick,
      selected: false,
    },
    {
      name: "Rejected Leads",
      filterValue: "Rejected",
      key: "rejected",
      headText: "Rejected",
      isPrice: true,
      isMessage: false,
      image: Delete,
      selected: false,
    },
    {
      name: "Expired",
      filterValue: "Expired",
      key: "expired",
      headText: "Expired",
      isPrice: true,
      isMessage: false,
      image: Expired,
      selected: false,
      
    },
    {
      name: "All Leads",
      filterValue: "All",
      key: "all",
      headText: "",
      isPrice: true,
      isMessage: false,
      image: People,
      selected: false,
    },
  ],
};

export const leadsReducer = createSlice({
  name: "leads",
  initialState,
  reducers: {
    leadRequest: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data;
      state.meta = action.payload.meta;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    updateTabCount: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      const tabs = [...state.leadTabs];
      tabs[0].count = data.awaiting_leads_count;
      tabs[1].count = data.approved_leads_count;
      tabs[2].count = data.rejected_leads_count;
      tabs[3].count = data.expired_leads_count;
      tabs[4].count = data.all_leads_count;
      state.leadTabs = tabs;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      state.leadTabs = [
        ...state.leadTabs.map((item: any) => ({ ...item, selected: false })),
      ];
      const key = action.payload;
      const index = state.leadTabs.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.leadTabs[index];
        state.leadTabs = state.leadTabs.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.leadTabs[0];
        state.leadTabs[0].selected = true;
      }
    },
    resetLeadsPage: (state) => {
      state.meta = {
        ...state.meta,
        current_page: 1
      };
    },
  },
});

export const { leadRequest, refreshRequest, selectTabs, updateTabCount, resetLeadsPage } =
  leadsReducer.actions;

export default leadsReducer.reducer;
