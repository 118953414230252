/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import LeadProfileApi from "api/resources/leadProfile";
import Edit from "assets/icon/edit_icon_square.svg";
import UpdateCover from "components/Modal/UpdateCover/UpdateCover";
import UpdateProfile from "components/Modal/UpdateProfile/UpdateProfile";
import { RoundButton } from "components/Ui/Button/Button";
import { httpService } from "helper/httpService";
import { useEffect, useState } from "react";
import { HiCamera } from "react-icons/hi";
import { LuSave } from "react-icons/lu";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { refreshRequest, selectTabs } from "redux/reducers/leadProfileReducer";
import AboutUs from "./AboutUs";
import Style from "./AutomotiveProfile.module.scss";
import Contact from "./Contact";
import Projects from "./Projects";
import TabBar from "./TabBar/TabBar";
import Updates from "./Updates";

function AutomotiveProfile({ isPublic }: any) {
  const [activeTab, setActiveTab] = useState<any>("Updates");
  const dispatch = useAppDispatch();
  const [openProfile, setOpenProfile] = useState(false);
  const [openCover, setOpenCover] = useState(false);
  const [openCustomizeProfile, setCustomizeProfile] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const data = useAppSelector((state: any) => state.leadProfile.data);
  const select = useAppSelector((state: any) => state.leadProfile.select);
  const color = useAppSelector((state: any) => state.leadProfile.primaryColor);

  const [companyName, setCompanyName] = useState("");
  const [companyEditName, setCompanyEditName] = useState("");
  const [companyLink, setCompanyLink] = useState("");
  const [companyEditLink, setCompanyEditLink] = useState("");
  const handleClose = () => {
    setOpenProfile(false);
    setOpenCover(false);
    setCustomizeProfile(false);
  };

  useEffect(() => {
    dispatch(selectTabs("automotive"));
  }, []);

  useEffect(() => {
    if (data) {
      setCompanyName(data.company_name);
      setCompanyLink(data.company_link);
    }
  }, [data]);

  const handleSave = () => {
    if (isEdit) {
      const para = {
        ...data,
        company_name: companyEditName ? companyEditName : companyName,
        company_link: companyEditLink ? companyEditLink : companyLink,
      };
      delete para.alias_image;
      delete para.cover_picture;
      delete para.logo;
      httpService(
        () => LeadProfileApi.updateProfile(select, para),
        refreshRequest
      );
      setCompanyName(para.company_name);
      setCompanyLink(para.company_link);
    }
    setIsEdit(!isEdit);
  };
  return (
    <Box>
      <Box className={Style.header}>
        <Box
          className={Style.banner}
          sx={{
            backgroundImage: `url(${data?.cover_picture ? data.cover_picture : "/assets/images/leadProfile/banner.png"})`,
          }}
        >
          {!isPublic && (
            <Box className={Style.bannerAdd} onClick={() => setOpenCover(true)}>
              <HiCamera color={"#b3b3b3"} size={25} />
              <Typography className={Style.bannerAddText} color={"#b3b3b3"}>
                Change Cover Photo
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={Style.bannerContent}>
          <Box className={Style.containerLogo}>
            <Box className={Style.logoMain}>
              <Box className={Style.logoContainer}>
                <Box className={Style.logoBox}>
                  {data && <img src={data?.logo} alt="logo" />}
                </Box>
                {!isPublic && (
                  <Box
                    className={Style.addlogo}
                    onClick={() => setOpenProfile(true)}
                  >
                    <HiCamera color={"#000"} size={20} />
                  </Box>
                )}
              </Box>
              <Box>
                <Typography
                  className={`${Style.text1} ${isEdit && Style.editInput}`}
                  contentEditable={isEdit}
                  onInput={(e: any) => setCompanyEditName(e.currentTarget.textContent)}
                >
                  {companyName || "Add Company Name"}
                </Typography>
                <Box className={Style.flexBox}>
                  <Typography
                    className={`${Style.text2} ${isEdit && Style.editInput}`}
                    contentEditable={isEdit}
                    onInput={(e: any) => setCompanyEditLink(e.currentTarget.textContent)}
                  >
                    {companyLink || "Add Company Link"}
                  </Typography>

                  {!isPublic && (
                    <Box className="cursor" onClick={() => handleSave()}>
                      {!isEdit ? (<img src={Edit} alt="edit" />) : (<LuSave size={20} />)}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <RoundButton onClick={() => { }}>Get New Quote</RoundButton>
          </Box>
          <TabBar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            color={color}
          />
        </Box>
      </Box>
      <Box pt={2}>
        {activeTab === "Updates" && (
          <Updates isPublic={isPublic} color={color} />
        )}
        {activeTab === "Projects" && (
          <Projects isPublic={isPublic} color={color} />
        )}
        {activeTab === "About Us" && (
          <AboutUs isPublic={isPublic} color={color} />
        )}
        {activeTab === "Contact" && (
          <Contact isPublic={isPublic} color={color} />
        )}
      </Box>

      {!isPublic && (<>
        <UpdateProfile open={openProfile} handleClose={handleClose} />
        <UpdateCover open={openCover} handleClose={handleClose} type={select} />
      </>)}
    </Box>
  );
}

export default AutomotiveProfile;
