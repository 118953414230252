import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  permissions: []
};

export const permissionReducer = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<any>) => {
      state.permissions = action.payload.data
    },
  },
});

export const { setPermissions } = permissionReducer.actions;

export default permissionReducer.reducer;
