/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import DashboardIcon from "assets/dashboard.svg";
import LeadsIcon from "assets/leads.svg";
import ScheduleIcon from "assets/scheduling.svg";
import GrowthToolsIcon from "assets/growth-tools.svg";
import SolutionsIcon from "assets/solutions.svg";

export default [
  {
    key: "my_business",
    name: "My Business",
    icon: DashboardIcon,
    background: "linear-gradient(270deg, #F8A94C 0%, #EF6036 100%)",
    path: "/business",
    link: "/",
    child: [
      {
        key: "dashboard",
        name: "Dashboard",
        link: "/",
      },
      {
        key: "analytics",
        name: "Analytics",
        link: "/business/analytics",
      },
      // {
      //   key: "reports",
      //   name: "Reports",
      //   link: "/business/reports",
      // },
      // {
      //   key: "inventory",
      //   name: "Inventory",
      //   link: "/business/inventory",
      // },
      {
        key: "invoices",
        name: "Invoices",
        link: "/business/invoices",
      },
    ],
  },
  {
    key: "schedule",
    name: "Schedule",
    icon: ScheduleIcon,
    background: "linear-gradient(90deg, #2B4291 0%, #32A5DB 100%)",
    path: "/schedule",
    link: "/schedule/calendar",
    child: [
      // {
      //   key: 'schedule',
      //   name: 'Schedule',
      //   title: true,
      // },
      {
        key: "calendar",
        name: "Calendar",
        link: "/schedule/calendar",
      },
      {
        key: "todays_work",
        name: "Today's Work",
        link: "/schedule/work",
      },
      {
        key: "tasks",
        name: "Tasks",
        link: "/schedule/tasks",
      },
    ],
  },
  {
    key: "leads",
    name: "Leads",
    icon: LeadsIcon,
    background: "linear-gradient(270deg, #912F8C 0%, #67348D 100%)",
    path: "/leads",
    link: "/leads/list",
    child: [
      {
        key: "leads",
        name: "Leads",
        link: "/leads/list",
      },
      {
        key: "contacts",
        name: "Contacts",
        link: "/leads/contacts",
      },
      {                                              
        key: "forms",
        name: "Forms",
        link: "/leads/forms",
      },
      {
        key: "campaigns",
        name: "Campaigns",
        link: "/leads/campaigns",
      },
      {
        key: "profile",
        name: "K-Profile",
        link: "/leads/k-profile",
      },
    ],
  },
  // {
  //   key: 'growth_tools',
  //   name: 'Growth Tools',
  //   icon: GrowthToolsIcon,
  //   background:
  //     'linear-gradient(90deg, hsla(70, 89%, 44%, 1) 0%, hsla(99, 100%, 50%, 1) 100%)',
  //   child: [
  //     {
  //       key: 'email_blasts',
  //       name: 'Email Blasts',
  //       link: '/app/pages/email-blasts'
  //     },
  //     {
  //       key: 'website',
  //       name: 'Website',
  //       link: '/app/pages/website'
  //     },
  //     {
  //       key: 'seo',
  //       name: 'SEO',
  //       link: '/app/pages/seo'
  //     },
  //     {
  //       key: 'payments',
  //       name: 'Google Ads',
  //       link: '/app/pages/google-ads'
  //     },
  //     {
  //       key: 'socialMedia',
  //       name: 'Social Media',
  //       link: '/app/pages/social-media'
  //     },
  //     {
  //       key: 'keplerLeads',
  //       name: 'Kepler Leads',
  //       link: '/app/pages/kepler-leads'
  //     }
  //   ]
  // },
  // {
  //   key: "solutions",
  //   name: "Solutions",
  //   icon: SolutionsIcon,
  //   background: "linear-gradient(270deg, #FC34B3 0%, #FC2337 100%)",
  //   path: "/solutions",
  //   link: "/solutions/services",
  //   child: [
  //     {
  //       key: "services",
  //       name: "Services",
  //       link: "/solutions/services",
  //     },
  //     {
  //       key: "products",
  //       name: "Products",
  //       link: "/solutions/products",
  //     },
  //     {
  //       key: "databases",
  //       name: "Databases",
  //       link: "/solutions/database",
  //     },
  //     {
  //       key: "payments",
  //       name: "Payments",
  //       link: "/solutions/payments",
  //     },
  //     {
  //       key: "reviews",
  //       name: "Reviews",
  //       link: "/solutions/reviews",
  //     },
  //   ],
  // },
];
