import { Icon } from "@mui/material";
import React from "react";

const imageIcon = {
  display: "flex",
  height: "inherit",
  width: "inherit",
};
function ImageIcon(props: any) {
  return (
    <Icon {...props} className={props.className}>
      <img src={props.src} style={imageIcon} alt="Icon" />
    </Icon>
  );
}

export default ImageIcon;
