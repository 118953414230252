import { useAppSelector } from "redux/hooks";
import _ from 'lodash'
import { Box, Typography } from "@mui/material";
import Style from './HasPermission.module.scss'
import { createSelector } from 'reselect';

// Memoized selector for role
const selectRole = (state: any) => state.auth.entities.role;

// Memoized selector for permissions
const selectPermissions = (state: any) => state.permission.permissions;

// Combined memoized selector
const selectRoleAndPermissions = createSelector(
  [selectRole, selectPermissions],
  (role, permissions) => ({
    role,
    permissions
  })
);

export const CheckUserHasPermission = (permission: any) => {
  const { role, permissions } = useAppSelector(selectRoleAndPermissions);

  const rolePermission = permissions.find((permission: any) => permission.role === role)
  const userPermission = _.get(rolePermission, 'permissions', [])

  return permission?.some((permission: string) => userPermission.includes(permission));
}


export const HasPermission = ({ permission, children, showMessage }: any) => {
  const couldShow = CheckUserHasPermission(permission);
  const { permissions } = useAppSelector(selectRoleAndPermissions)
  
  return <>{couldShow === true ? children : (showMessage === true && _.defaultTo(permissions, []).length > 0) ? (<>
    <Box className={Style.notfoundMain}>
      <Box className={Style.notfound}>
        <Box className={Style['notfound-404']}>
          <Typography component={'h3'}>Access Denied</Typography>
          <h1><span>4</span><span>0</span><span>3</span></h1>
        </Box>
        <h2>You do not have permission to view this page.</h2>
      </Box>
    </Box>
  </>) : null}</>
}

export const HideInformation = ({ text, type }: any) => {
  if (type === 'price') {
    const couldHide = CheckUserHasPermission(['hide_all_prices']);
    return couldHide ? '$xx.xx' : text

  }
  if (type === 'contact_name') {
    const couldHide = CheckUserHasPermission(['hide_all_contact_details', 'hide_all_contact_information']);
    return couldHide ? '****' : text
  }

  if (type === 'contact_info') {
    const couldHide = CheckUserHasPermission(['hide_all_contact_information']);
    return couldHide ? '****' : text
  }

  if (type === 'measurement') {
    const couldShow = CheckUserHasPermission(['hide_all_measurements']);
    return couldShow ? '**' : text

  }
  return text
}