import apiClient from "../client";

export default class AuthAPI {
  static csrf() {
    return apiClient.get(
      `${process.env.REACT_APP_SERVER_URL}/sanctum/csrf-cookie`
    );
  }

  static login(payload: any) {
    return apiClient.post("/auth/login", payload);
  }

  static getSessionUser() {
    return apiClient.get("/session-user");
  }

  static logout() {
    return apiClient.post("/logout");
  }

  static register(payload: any) {
    return apiClient.post("register", payload);
  }

  static setup(payload: any) {
    return apiClient.post("/setup", payload);
  }

  static resendVerificationEmail() {
    return apiClient.get("/email/resend");
  }

  static resetPassword(payload: any) {
    return apiClient.post("/reset-password", payload);
  }

  static forgotPassword(payload: any) {
    return apiClient.post("/forgot-password", payload);
  }

  static getNotifications() {
    return apiClient.get("/notification");
  }

  static setNotificationRead(data: any = {}) {
    return apiClient.put("/notification/" + data.id, data);
  }

  static createToken(payload: any) {
    return apiClient.post("/tokens", payload);
  }

  static deleteToken(token: any) {
    return apiClient.delete(`/tokens/${token}`);
  }
  static verifyEmail(token: any) {
    return apiClient.get(`/public/users/${token}/change-email`);
  }
  static cancelEmailChange(token: any) {
    return apiClient.get(`/public/users/${token}/cancel-email-change`);
  }

  static clearNotifications() {
    return apiClient.get("/notifications/clear-all-notifications");
  }
}
