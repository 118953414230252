const DefaultPaginationState = () => ({
  entities: {},
  results: [],
  page_count: 1,
  current_page: 1,
  per_page: 10,
  total: 0,
  loading: false,
  errors: null,
  success: {},
  data: {},
  calenderEvent: [],
  mapEvent: [],
  value: {}
});

export default DefaultPaginationState;