import apiClient from "../client";

export default class LeadProfileApi {
  static allProfile() {
    return apiClient.get("/organisation-profiles");
  }

  static viewProfile(type: any) {
    return apiClient.get(`/organisation-profiles/${type}`);
  }

  static updateHeroImage(id: any, data: any) {
    return apiClient.post(
      `/organisation-profiles/${id}/upload-hero-image`,
      data
    );
  }
  static getImageList(type: any) {
    return apiClient.get(`/uploads?filter[type]=${type}`);
  }

  static postCoverImage(data: any) {
    return apiClient.post(`/uploads`, data);
  }
  static updateLogo(id: any, data: any) {
    return apiClient.post(`/organisation-profiles/${id}/upload-logo`, data);
  }

  static updateProfile(id: any, data: any) {
    return apiClient.put(`/organisation-profiles/${id}`, data);
  }

  static publicProfile(id: any) {
    return apiClient.get(`/public/organisation-profile/${id}`);
  }
  static publicProfileInfo(id: any) {
    return apiClient.get(`/public/lead-campaign-emails/${id}`);
  }

  static profileApprove(id: any) {
    return apiClient.get(`/public/project/approve/${id}`);
  }

  static profileReject(id: any) {
    return apiClient.get(`/public/project/reject/${id}`);
  }

  static standoutImages(value: any) {
    return apiClient.get(`/standout-images?filter[media_type]=${value}`);
  }

  static uploadCoverPicture(type: any, id: any) {
    return apiClient.post(
      `/organisation-profiles/${type}/upload/${id}/upload-cover-picture`
    );
  }
}
