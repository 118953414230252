import axios from "axios";
import { stringify } from "qs";

// eslint-disable-next-line import/no-cycle
import store from "redux/reducers";
import { logOut } from "redux/reducers/authReducer";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + "/api",
  paramsSerializer: (params) =>
    stringify(params, {
      arrayFormat: "brackets",
      encodeValuesOnly: true,
    }),
});
const token =
  cookies.get("access_token") !== undefined ? cookies.get("access_token") : "";

apiClient.defaults.withCredentials = true;

apiClient.defaults.headers.common = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
};

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Response failed
    if (error.response && error.response.status === 401) {
      store.dispatch(logOut());
    }

    // Return rejection so we can catch it later if we want
    return Promise.reject(error);
  }
);

export default apiClient;
