import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Dashboard from "../layout/Dashboard";
import { useAppSelector } from "redux/hooks";
import { LoadScript } from "@react-google-maps/api";
import Loading from "components/Loading";
import AuthLoading from "./AuthLoading";

const MapLibraries: any = ["places"];
const key: any = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const Auth = () => {
  const isAuth: any = useAppSelector((state) => state.auth.isAuth);
  const isLoading: any = useAppSelector((state) => state.loading.isLoading);
  if (isAuth === null || isLoading) {
    return <AuthLoading />;
  }
  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  return (
    <LoadScript
      googleMapsApiKey={key}
      loadingElement={<Loading />}
      libraries={MapLibraries}
    >
      <Dashboard>
        <Outlet />
      </Dashboard>
    </LoadScript>
  );
};

export default Auth;
