import { createTheme } from "@mui/material";
import { themeColor } from "./color";

const theme = createTheme();

export const themeTypography = () => {
  return {
    fontFamily: "'Inter', sans-serif",
    color: themeColor.black,
    p: {
      fontFamily: "'Inter', sans-serif",
    },
    title: {
      fontSize: "24px",
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    title1: {
      fontSize: "18px",
      display: "block",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
    title2: {
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    title3: {
      fontSize: "14px",
    },
    title4: {
      fontSize: "12px",
    },
    title5: {
      fontSize: "10px",
    },
    menuText: {
      fontSize: "12px",
      display: "block",
      fontWeight: 600,
    },
    menuLink: {
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    userMenu: {
      fontSize: "17px",
      display: "block",
      fontWeight: 700,
      color: "#000",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    lable: {
      fontSize: "14px",
      fontWeight: 600,
      paddingBottom: "5px",
    },
    lable2: {
      fontSize: "16px",
      fontWeight: 500,
      paddingBottom: "5px",
      display: "block",
    },
    lable3: {
      fontSize: "14px",
      fontWeight: 500,
      paddingBottom: "5px",
    },
    tableText: {
      fontSize: "14px",
      display: "block",
    },
    titleNormal: {
      fontSize: "24px",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
    subTitle1: {
      fontSize: "18px",
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
    heading1: {
      /* Heading 1 */
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 'normal'
    },
    heading2: {
      /* Heading 2 */
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    heading3: {
      /* Heading 3 */
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    heading4: {
      /* Heading 4 */
      fontSize: '19px',
      fontWeight: 500,
      lineHeight: 'normal'
    },
    heading5: {
      /* Heading 5 */
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    heading6: {
      /* Heading 6 */
      fontSize: '17px',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    bodyTextLarge: {
      /* body text large */
      fontSize: '17.07px',
      lineHeight: '25.5px',
      fontWeight: 500
    },
    bodyTextNormal: {
      /* body text Normal */
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500
    },
    bodyTextSmall: {
      /* body text Small */
      fontSize: '13px',
      lineHeight: '14px',
      fontWeight: 500
    },
    bodyText11px: {
      /* body text 11px */
      fontSize: '11px',
      lineHeight: '15px',
      fontWeight: 500
    },
    linkText: {
      /* Link text */
      color: '#06C',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    labelTitle: {
      /* Label Title */
      fontSize: '18.22px',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    inputText: {
      /* Input text */
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    tooltipText: {
      /* Tooltip text */
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '138%'
    },
    dropdownOptionText: {
      /* Drop Down <option> Items with dropdowns */
      color: '#282828',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '138%'
    },
    bodyTextSemi: {
      /* Body Text Semi. */
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: 'normal'
    }
  };
};
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    title: true;
    title1: true;
    title2: true;
    title3: true;
    title4: true;
    title5: true;
    menuText: true;
    menuLink: true;
    userMenu: true;
    lable: true;
    lable2: true;
    lable3: true;
    tableText: true;
    subTitle1: true;
    heading1: true;
    heading2: true;
    heading3: true;
    heading4: true;
    heading5: true;
    heading6: true;
    bodyTextLarge: true;
    bodyTextNormal: true;
    bodyTextSmall: true;
    bodyText11px: true;
    linkText: true;
    labelTitle: true;
    inputText: true;
    tooltipText: true;
    dropdownOptionText: true;
    bodyTextSemi: true;
  }
}
