import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSafeValue } from "utils";

interface CounterState {
  entitiesToday: any;
  entitiesMonth: any;
  meta: any;
  refresh: any;
  leadMovement: any;
}

const initialState: CounterState = {
  refresh: false,
  entitiesToday: null,
  entitiesMonth: null,
  meta: null,
  leadMovement: null,
};

export const analyticsReducer = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    getTodaysAnalyticsRequest: (state, action: PayloadAction<any>) => {
      const [revenueData, leadsData, upSellData, jobCompletedData, noShowAppointmentData, comebackData] = action.payload;

      const { data: revenue } = revenueData.data
      const { data: leads } = leadsData.data
      const { data: upSell } = upSellData.data
      const { data: jobCompleted } = jobCompletedData.data
      const { data: noShow } = noShowAppointmentData.data
      const { data: comeback } = comebackData.data

      const dailyData = [
        {
          text: 'Revenue',
          value: Number(getSafeValue(revenue, 'value', 0))?.toFixed(2),
          change: getSafeValue(revenue, 'change', 0),
          is_currency: true
        },
        {
          text: 'Revenue from upsells',
          value: Number(getSafeValue(upSell, 'value', 0))?.toFixed(2),
          change: getSafeValue(upSell, 'change', 0),
          is_currency: true
        },
        {
          text: 'Jobs Completed ($)',
          value: Number(getSafeValue(jobCompleted, 'value', 0))?.toFixed(2),
          change: getSafeValue(jobCompleted, 'change', 0),
          is_currency: true,
          count: getSafeValue(jobCompleted, 'count', 0)
        },
        {
          text: 'Leads gained ($)',
          value: Number(getSafeValue(leads, 'value', 0))?.toFixed(2),
          change: getSafeValue(leads, 'change', 0),
          is_currency: true,
          count: getSafeValue(leads, 'count', 0)
        },
        {
          text: 'No show appointments ($)',
          value: Number(getSafeValue(noShow, 'value', 0))?.toFixed(2),
          change: getSafeValue(noShow, 'change', 0),
          is_currency: true,
          count: getSafeValue(noShow, 'count', 0)
        },
        {
          text: 'Comebacks',
          value: getSafeValue(comeback, 'value', 0),
          change: getSafeValue(comeback, 'change', 0),
          is_currency: false
        }
      ]

      state.entitiesToday = dailyData;
    },
    getThisMonthAnalyticRequest: (state, action: PayloadAction<any>) => {
      const [revenueData, leadsData, upSellData, jobCompletedData, noShowAppointmentData, comebackData] = action.payload;
      const { data: revenue } = revenueData.data
      const { data: leads } = leadsData.data
      const { data: upSell } = upSellData.data
      const { data: jobCompleted } = jobCompletedData.data
      const { data: noShow } = noShowAppointmentData.data
      const { data: comeback } = comebackData.data

      const analyticsData = [
        {
          text: 'Revenue',
          value: Number(getSafeValue(revenue, 'value', 0))?.toFixed(2),
          change: getSafeValue(revenue, 'change', 0),
          is_currency: true
        },
        {
          text: 'Revenue from upsells',
          value: Number(getSafeValue(upSell, 'value', 0))?.toFixed(2),
          change: getSafeValue(upSell, 'change', 0),
          is_currency: true
        },
        {
          text: 'Jobs Completed ($)',
          value: Number(getSafeValue(jobCompleted, 'value', 0))?.toFixed(2),
          change: getSafeValue(jobCompleted, 'change', 0),
          is_currency: true,
          count: getSafeValue(jobCompleted, 'count', 0)
        },
        {
          text: 'Leads gained ($)',
          value: Number(getSafeValue(leads, 'value', 0))?.toFixed(2),
          change: getSafeValue(leads, 'change', 0),
          is_currency: true,
          count: getSafeValue(leads, 'count', 0)
        },
        {
          text: 'No show appointments ($)',
          value: Number(getSafeValue(noShow, 'value', 0))?.toFixed(2),
          change: getSafeValue(noShow, 'change', 0),
          is_currency: true,
          count: getSafeValue(noShow, 'count', 0)
        },
        {
          text: 'Comebacks',
          value: getSafeValue(comeback, 'value', 0),
          change: getSafeValue(comeback, 'change', 0),
          is_currency: false
        }
      ]
      state.entitiesMonth = analyticsData;
    },
    getLeadMovement: (state, action: PayloadAction<any>) => {
      state.leadMovement = action.payload;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const {
  getTodaysAnalyticsRequest,
  getThisMonthAnalyticRequest,
  refreshRequest,
  getLeadMovement,
} = analyticsReducer.actions;

export default analyticsReducer.reducer;
