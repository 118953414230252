import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Profile from "assets/profile-circle.svg";
interface CounterState {
  entities: any;
  meta: any;
  refresh: any;
  activeTab: any;
  formsTabs: any;
  select: any;
  publicData: any;
  data: any;
  primaryColor: string;
}

const initialState: CounterState = {
  entities: null,
  data: null,
  select: "",
  meta: null,
  refresh: false,
  activeTab: null,
  publicData: null,
  primaryColor: "#000",
  formsTabs: [
    {
      name: "Automotive Profile",
      key: "automotive",
      image: Profile,
      selected: false,
    },
    {
      name: "Architectural Profile",
      key: "architectural",
      image: Profile,
      selected: false,
    },
  ],
};

export const leadProfileReducer = createSlice({
  name: "leadProfile",
  initialState,
  reducers: {
    getRequest: (state, action: PayloadAction<any>) => {
      if (action.payload.data.length > 0) {
        const data = action.payload.data.map((item: any) => ({
          name: item.type.replaceAll("_", " "),
          value: item.type,
        }));
        state.select = data[0].value;

        state.entities = data;
      }
    },
    getViewRequest: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.primaryColor = action.payload.profile_primary_color;
    },
    getPublicRequest: (state, action: PayloadAction<any>) => {
      state.publicData = action.payload.data;
      state.data = action.payload.data.lead_campaign.organisation_profile;
      state.primaryColor = action.payload.data.lead_campaign.organisation_profile.profile_primary_color;
    },
    selectLeadProfile: (state, action: PayloadAction<any>) => {
      state.select = action.payload;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      const key = action.payload;
      const index = state.formsTabs.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.formsTabs[index];
        state.formsTabs = state.formsTabs.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.formsTabs[0];
        state.formsTabs[0].selected = true;
      }
    },
    changePrimaryColor: (state, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    updateProjectInfo: (state, action: PayloadAction<any>) => {
      state.publicData.project = action.payload.data;
    },
  },
});

export const {
  getRequest,
  refreshRequest,
  selectLeadProfile,
  selectTabs,
  getViewRequest,
  getPublicRequest,
  changePrimaryColor,
  updateProjectInfo
} = leadProfileReducer.actions;

export default leadProfileReducer.reducer;
