import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { alpha, styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { themeColor } from "theme/color";

const TextInput = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableunderline: 'true' } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: 7,
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#000",
  },
  "& .MuiOutlinedInput-input ": {
    fontSize: "16px",
    padding: "10px 10px 10px 12px",
    fontWeight: 500,
    color: themeColor.inputColor,
  },
  "& .MuiInputBase-multiline.MuiInputBase-root": {
    padding: 0,
  },
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    backgroundColor: "#fff",
    border: "1px solid",
    borderColor: "#C5C5C5",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default TextInput;
