import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function AddJobIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M6.76864 20.4384H14.5434C18.4503 20.4384 19.15 18.8737 19.3541 16.9688L20.083 9.194C20.3454 6.82267 19.6651 4.88867 15.5153 4.88867H5.79679C1.64696 4.88867 0.96666 6.82267 1.22906 9.194L1.95795 16.9688C2.16204 18.8737 2.86178 20.4384 6.76864 20.4384Z
    M6.76562 4.88743V4.10994C6.76562 2.38975 6.76562 1 9.87556 1H11.4305C14.5405 1 14.5405 2.38975 14.5405 4.10994V4.88743
    M12.5984 11.6906V12.6625V12.6819C12.5984 13.7412 12.5886 14.6062 10.6547 14.6062C8.73037 14.6062 8.71094 13.7509 8.71094 12.6916V11.6906C8.71094 10.7188 8.71094 10.7188 9.68279 10.7188H11.6265C12.5984 10.7188 12.5984 10.7188 12.5984 11.6906Z
    M20.0363 9.74609C17.7913 11.3788 15.2256 12.3507 12.6016 12.6811
    M1.53906 10.0078C3.72574 11.5045 6.19425 12.4083 8.71136 12.6901" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default AddJobIcon;
