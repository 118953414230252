import { SvgIcon } from "@mui/material";

function ToolTipInfoIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15" fill="none" sx={{ height: '15px', width: '15px'}}>
        <path 
            d="M7.5 15C11.6325 15 15 11.6325 15 7.5C15 3.3675 11.6325 -6.59278e-07 7.5 -2.98004e-07C3.3675 6.32711e-08 -6.32712e-08 3.3675 2.98003e-07 7.5C6.59278e-07 11.6325 3.3675 15 7.5 15ZM8.0625 10.5C8.0625 10.8075 7.8075 11.0625 7.5 11.0625C7.1925 11.0625 6.9375 10.8075 6.9375 10.5L6.9375 6.75C6.9375 6.4425 7.1925 6.1875 7.5 6.1875C7.8075 6.1875 8.0625 6.4425 8.0625 6.75L8.0625 10.5ZM6.81 4.215C6.8475 4.1175 6.9 4.0425 6.9675 3.9675C7.0425 3.9 7.125 3.8475 7.215 3.81C7.305 3.7725 7.4025 3.75 7.5 3.75C7.5975 3.75 7.695 3.7725 7.785 3.81C7.875 3.8475 7.9575 3.9 8.0325 3.9675C8.1 4.0425 8.1525 4.1175 8.19 4.215C8.2275 4.305 8.25 4.4025 8.25 4.5C8.25 4.5975 8.2275 4.695 8.19 4.785C8.1525 4.875 8.1 4.9575 8.0325 5.0325C7.9575 5.1 7.875 5.1525 7.785 5.19C7.605 5.265 7.395 5.265 7.215 5.19C7.125 5.1525 7.0425 5.1 6.9675 5.0325C6.9 4.9575 6.8475 4.875 6.81 4.785C6.7725 4.695 6.75 4.5975 6.75 4.5C6.75 4.4025 6.7725 4.305 6.81 4.215Z"
            fill={props?.fill || "#292D32"}
        />
    </SvgIcon>
  );
}

export default ToolTipInfoIcon;
