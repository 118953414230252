import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CounterState {
  entities: any;
  meta: any;
  refresh: any;
  entitiesNew: any;
  chartData: any
}

const initialState: CounterState = {
  refresh: false,
  entities: null,
  meta: null,
  entitiesNew: {
    job_completed: {
      value: 0,
      targets: 0
    },
    job_scheduled: {
      value: 0,
      targets: 0
    },
    leads_gained: {
      value: 0,
      targets: 0
    }
  },
  chartData: {
    job_scheduled_data: [],
    lead_gained_data: [],
    job_completed_data: [],
  }
};

export const dashboardReducer = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardRequest: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    dashboardRequestNew: (state, action: PayloadAction<any>) => {
      state.entitiesNew = action.payload;
    },
    dashboardChartData: (state, action: PayloadAction<any>) => {
      state.chartData = action.payload;
    },
  },
});

export const { dashboardRequest, refreshRequest, dashboardRequestNew, dashboardChartData } = dashboardReducer.actions;

export default dashboardReducer.reducer;
