/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import SettingsIcon from "assets/icon/settings.svg";
export default {
  key: "settings",
  name: "",
  icon: SettingsIcon,
  background: "#FFFFFF",
  path: "/settings",
  link: "/settings/profile",
  child: [
    {
      key: "profile",
      name: "Profile",
      link: "/settings/profile",
    },
    {
      key: "business",
      name: "Business",
      link: "/settings/business-profile",
    },
    {
      key: "team",
      name: "Team",
      link: "/settings/team",
    },
    {
      key: "calendar",
      name: "Calendar",
      link: "/settings/calendar",
    },
    {
      key: "targets",
      name: "Targets",
      link: "/settings/targets",
    },
    {
      key: "invoicing",
      name: "Invoicing",
      link: "/settings/invoicing",
    },
    {
      key: "notifications",
      name: "Notifications",
      link: "/settings/notifications",
    }
  ]
};
