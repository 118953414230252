import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IconActiveForm from "assets/icon/lead_active_form.svg";
import IconInActiveForm from "assets/icon/lead_inactive_form.svg";
import IconAllForm from "assets/icon/lead_all_form.svg";

interface CounterState {
  entities: any;
  meta: any;
  refresh: any;
  activeTab: any;
  formsTabs: any;
  data: any;
  list: any;
}

const initialState: CounterState = {
  entities: null,
  meta: null,
  refresh: false,
  data: null,
  list: null,
  activeTab: null,
  formsTabs: [
    {
      name: "Active Forms",
      label: "Active Forms",
      key: "Active",
      image: IconActiveForm,
      selected: false,
    },
    {
      name: "Inactive Forms",
      label: "Inactive Forms",
      key: "Inactive",
      image: IconInActiveForm,
      selected: false,
    },
    {
      name: "All Forms",
      label: "All Forms",
      key: "All",
      image: IconAllForm,
      selected: false,
    }
  ],
};

export const formsReducer = createSlice({
  name: "forms",
  initialState,
  reducers: {
    formRequest: (state, action: PayloadAction<any>) => {
      state.entities = action.payload.data;
      state.meta = action.payload.meta;
    },
    submissionsRequest: (state, action: PayloadAction<any>) => {
      state.list = action.payload.data;
    },
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    updateTabCount: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      const tabs = [...state.formsTabs];
      tabs[0].itemCount = data.all_forms;
      tabs[1].itemCount = data.automotive_forms;
      tabs[2].itemCount = data.architectural_forms;
      tabs[3].itemCount = data.custom_forms;
      state.formsTabs = tabs;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      state.formsTabs = [
        ...state.formsTabs.map((item: any) => ({ ...item, selected: false })),
      ];
      const key = action.payload;
      const index = state.formsTabs.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.formsTabs[index];
        state.formsTabs = state.formsTabs.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.formsTabs[0];
        state.formsTabs[0].selected = true;
      }
    },
    viewLeadFrom: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const {
  formRequest,
  refreshRequest,
  selectTabs,
  submissionsRequest,
  updateTabCount,
  viewLeadFrom,
} = formsReducer.actions;

export default formsReducer.reducer;
