import { FormControl, InputLabel, TextField } from "@mui/material";
import { ChangeEventHandler } from "react";
import classes from "./TextInput.module.scss";

interface IFTextField {
  label?: string;
  value?: string;
  name: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  placeholder?: string;
  multiline?: boolean;
  rows?: number
  inputProps?: any
}

const TextInput = ({ label = '', value, name, onChange, placeholder, multiline = false, rows = 0, inputProps }: IFTextField) => {
  return <FormControl fullWidth variant="outlined">
    {label !== '' && <label className={classes.inputLabel} htmlFor={name}>{label}</label>}
    <TextField
      id={name}
      className={classes.TextField}
      variant="outlined"
      defaultValue={value}
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      InputProps={inputProps}
    />
  </FormControl>;
};

export default TextInput;
