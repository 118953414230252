import * as Yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const ContactFormSchema = Yup.object({
  contact: Yup.object({
    first_name: Yup.string().required("First Name is Required").trim(),
    last_name: Yup.string().nullable().trim(),
    is_business: Yup.boolean(),
    company: Yup.string().trim().when('is_business', {
      is: true,
      then: (schema) => schema.required("Business Name Is Required").trim(),
      otherwise: (schema) => schema.notRequired(),
    }),
    mobile_install: Yup.boolean().default(false)
  }),
  jobs: Yup.array().of(
    Yup.object().shape({
      address: Yup.object().when(['mobile_install', 'full_address'], ([mobile_install, full_address], schema) => {
        if(full_address) {
          return schema.shape({
            city: Yup.string().trim().required('City is required when contact has full address'),
            state_province: Yup.string().trim().required('State is required when contact has full address'),
            street_address: Yup.string().trim().required('Street Address is required when contact has full address'),
            second_line: Yup.string().trim().nullable(),
            zipcode: Yup.string().trim().required('Zip is required when contact has full address'),
          })
        } else if(mobile_install) {
          return schema.shape({
            city: Yup.string().trim().required('City is required when contact is mobile'),
            state_province: Yup.string().trim().required('State is required when contact is mobile')
          })
        }
        return schema.notRequired()
      }),
    }),
  ),
});

export const JobDetailFormSchema = Yup.object({
  jobs: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().trim().required('Job Type is Required'),
      make: Yup.string().trim().when(['type'], ([type], schema) => {
        return type === 'Automotive' ? schema.required('Make is required') : schema.notRequired()
      }),
      model: Yup.string().trim().when(['type'], ([type], schema) => {
        return type === 'Automotive' ? schema.required('Model is required') : schema.notRequired()
      }),
      car_type: Yup.string().trim().when(['type'], ([type], schema) => {
        return type === 'Automotive' ? schema.required('Car Type is required') : schema.notRequired()
      }),
      year: Yup.string().trim().when(['type'], ([type], schema) => {
        return type === 'Automotive' ? schema.required('Year is required') : schema.notRequired()
      }),
      building_type: Yup.string().trim().when(['type'], ([type], schema) => {
        return type === 'Architectural' ? schema.required('Building Type is required') : schema.notRequired()
      }),
      service_type: Yup.string().trim().when(['type'], ([type], schema) => {
        return type === 'Architectural' ? schema.required('Service Type is required') : schema.notRequired()
      }),
    }),
  ),
});

export const ContactDetailsSchema = Yup.object({
  contact: Yup.object().shape({
    email: Yup.string().trim().email('Please enter valid email address').nullable(),
    phone_number: Yup.string().trim().matches(phoneRegExp, { message: 'Phone number is not valid', excludeEmptyString: true }).nullable(),
   }).test('email', 'Either email or contact number is required', function (value) {
    const { email, phone_number } = value;
    return email !== null || phone_number !== null;
   })
})

const commonServiceFields = {
  type: Yup.string().trim().required('Service Type is required'),
  price: Yup.number().typeError('Price must be a number').positive('Price must be greater than zero').required('Price is required'),
  discount_price: Yup.number().typeError('Discount Price must be a number').when(['discount', 'type'], ([discount, type], schema) => {
    return (discount === true && type !== 'Other') ? schema.positive('Discount Price must be greater than zero').required('Discount Price is required') : schema.notRequired();
  }),
  service_title: Yup.string().trim().when(['type'], ([type], schema) => {
    return type === 'Other' ? schema.required('Service Title is required') : schema.notRequired();
  }),
  notes: Yup.object().when(['type'], ([type], schema) => {
    return type === 'Other' ? schema.required('Service Notes are required') : schema.notRequired();
  }),
};

const automotiveServiceSchema = Yup.object().shape({
  // mobile_price: Yup.number().typeError('Mobile Price must be a number').when(['mobile_install'], ([mobile_install], schema) => {
  //   return mobile_install === true ? schema.positive('Mobile Price must be greater than zero').required('Mobile Price is required') : schema.notRequired();
  // }),
  services: Yup.array().of(
    Yup.object().shape({
      ...commonServiceFields,
      applications: Yup.array().when(['type'], ([type], schema) => {
        return type !== 'Other' ? schema.min(1, 'Please Select at least one Install Area').required('Please Select at least one Install Area') : schema.notRequired();
      }).of(
        Yup.object().shape({
          area: Yup.string().trim().required('Area is required'),
          // films: Yup.array().min(1).required("Please Select at least one Film").of(
          //   Yup.object().shape({
          //     filmId: Yup.number().required('Film is required'),
          //     filmShadeId: Yup.string().trim().required('Film Shade is required')
          //   })
          // )
        })
      ),
    })
  ).min(1, 'Please Select at least one service').required("Please Select at least one service"),
});

const architecturalServiceSchema = Yup.object().shape({
  // mobile_price: Yup.number().typeError('Mobile Price must be a number').when(['mobile_install'], ([mobile_install], schema) => {
  //   return mobile_install === true ? schema.positive('Mobile Price must be greater than zero').required('Mobile Price is required') : schema.notRequired();
  // }),
  services: Yup.array().of(
    Yup.object().shape({
      ...commonServiceFields,
      film_type: Yup.string().trim().when(['type'], ([type], schema) => {
        return type === 'Other' ? schema.notRequired() : schema.required('Film Type is required');
      }),
      price_per_sqft: Yup.number().when(['type'], ([type], schema) => {
        return type === 'Other' ? schema.notRequired() : schema.typeError('Price must be a number').positive('Price must be greater than zero').required('Price is required');
      }),
      total_sqft: Yup.number().when(['type'], ([type], schema) => {
        return type === 'Other' ? schema.notRequired() : schema.typeError('Total Sqft must be a number').positive('Total Sqft must be greater than zero').required('Total Sqft is required');
      }),
      total_windows: Yup.number().when(['type'], ([type], schema) => {
        return type === 'Other' ? schema.notRequired() : schema.typeError('At least One window is required must be a number').positive('At least One window is required must be a number').required('At least One window is required must be a number');
      }),
      measurements: Yup.array().when(['type'], ([type], schema) => {
        return type === 'Other' ? schema.notRequired() : schema.min(1, 'Please add at least one measurement').required("Please add at least one measurement");
      }).of(
        Yup.object().shape({
          number_of_windows: Yup.number().typeError('Number Of Window be a number').positive('Number Of Window must be greater than zero').required('Number Of Window is required'),
          width: Yup.number().typeError('width be a number').positive('width must be greater than zero').required('width is required'),
          height: Yup.number().typeError('height be a number').positive('height must be greater than zero').required('height is required'),
          sqft: Yup.number().typeError('sqft be a number').positive('sqft must be greater than zero').required('sqft is required')
        })
      ),
      // film_options: Yup.array().when(['film_type'], ([film_type], schema) => {
      //   return film_type === 'Kepler Window Film' ? schema.min(1, 'Please Select Film Shade').required('Please Select Film Shade') : schema.notRequired();
      // }).of(
      //   Yup.object().shape({
      //     name: Yup.string().trim().required('Film Shade name is required'),
      //     filmId: Yup.number().required('Film Id is required'),
      //     filmShadeId: Yup.string().trim().required('Film Shade Id is required')
      //   })
      // ),
    })
  ).min(1, 'Please Select at least one service').required("Please Select at least one service"),
});

const architecturalConsultantSchema = Yup.object().shape({
  mobile_price: Yup.number().typeError('Mobile Price must be a number').when(['mobile_install'], ([mobile_install], schema) => {
    return mobile_install === true ? schema.positive('Mobile Price must be greater than zero').required('Mobile Price is required') : schema.notRequired();
  }),
  services: Yup.array().of(
    Yup.object().shape({
      notes: Yup.object().shape({
        text: Yup.string().nullable().trim()
      }),
    })
  ),
});     

export const ApplicationFormSchema = Yup.object({
  jobs: Yup.array().of(
    Yup.lazy((item: any) => {
      const { type, service_type } = item;

      if (type === 'Automotive') {
        return automotiveServiceSchema;
      }

      if (type === 'Architectural') {
        if(service_type === 'Consultation') {
          return architecturalConsultantSchema
        } else {
          return architecturalServiceSchema
        }
      }

      return Yup.object();
    })
  ),
});

export const AppointmentSchema = Yup.object().shape({
  allDay: Yup.boolean(),
  startsAt: Yup.string().trim().required('Start Date is required'),
  endsAt: Yup.string().trim().required('End Date is required'),
  status: Yup.string(),
  type: Yup.string(),
  is_sharp_time: Yup.boolean(),
  assignees: Yup.array().min(1, 'Please Select assignees').required("Please Select assignees")
})
