import store from "redux/reducers";
import { toast } from "react-toastify";
import { handleLoading } from "redux/reducers/loaderReducer";
import { logOut } from "redux/reducers/authReducer";
export const authService = async (apiMethod: any, action: any) => {
  store.dispatch(handleLoading(true));
  try {
    const response = await apiMethod();
    store.dispatch(action(response.data));
    store.dispatch(handleLoading(false));
  } catch (e: any) {
    toast.error(e.response.data.error);
    store.dispatch(handleLoading(false));
  }
};

export const handleLogOut = () => {
  store.dispatch(handleLoading(true));
  store.dispatch(logOut());
  store.dispatch(handleLoading(false));
};
