import apiClient from "../client";

export default class ProjectAPI {
  static list(page = 1, filter = {}, sort = {}) {
    return apiClient.get("/projects", {
      params: {
        page,
        filter,
        sort,
      },
    });
  }

  static listAll() {
    return apiClient.get("/projects", {
      params: {
        paginate: 0,
      },
    });
  }

  static view(id: any) {
    return apiClient.get(`/projects/${id}`);
  }

  static getProjects(data: any) {
    return apiClient.get(`/projects?${data}`);
  }

  static create(data: any) {
    return apiClient.post("/projects/create-project", data);
  }

  static update(id: any, data: any) {
    return apiClient.put(`/projects/${id}/update-project`, data);
  }

  static delete(id: any) {
    return apiClient.delete(`/projects/${id}`);
  }
  static cancel(id: any, data: any) {
    return apiClient.post(`/projects/${id}/cancel`, data);
  }

  static duplicate(id: any) {
    return apiClient.post(`/projects/${id}/duplicate`);
  }

  // static upload(id, file, tags = []) {
  //   return apiClient.put(`/projects/${id}`, serialize({
  //     uploads: [file],
  //     upload_tags: tags
  //   }))
  // }

  static addServices(id: any, data: any) {
    return apiClient.post(`/projects/${id}/project-services`, data);
  }

  static updatePrice(id: any, data: any) {
    return apiClient.put(`/projects/${id}/set-price`, data);
  }

  static addTintApplications(id: any, data: any) {
    return apiClient.post(`/projects/${id}/tint-applications`, data);
  }

  static updateTintApplication(
    projectId: any,
    tintApplicationId: any,
    data: any
  ) {
    return apiClient.put(
      `/projects/${projectId}/tint-applications/${tintApplicationId}`,
      data
    );
  }

  static getTodayProjectApi(data: any) {
    return apiClient.get("/calendar/all-events", { params: { filter: data } });
  }

  static updateTodayProjectStatsApi(payload: { id: any; data: any }) {
    const { id, data } = payload;
    return apiClient.put(`/events/${id}/update-status`, data);
  }

  static ignore(id: any) {
    return apiClient.get(`/project/ignore/${id}`);
  }

  static getTaskAppointmentDetails(data: any) {
    return apiClient.get(`/calendar/appointment-stats`, {
      params: { filter: data },
    });
  }

  static getTaskAppointmentAmountDetails(data: any) {
    return apiClient.get(`/calendar/appointment-amount-stats`, {
      params: { filter: data },
    });
  }

  static searchProject(filter: any) {
    return apiClient.get(`/search-projects`, { params: { filter: filter } });
  }

  static scheduleProjectAppointment(project_id: string, data: any) {
    return apiClient.post(`/projects/${project_id}/appointment`, data);
  }

  static updateProjectWithComebackNotes(project_id: string, data: any) {
    return apiClient.put(`/projects/${project_id}/update-project`, data);
  }

  static addressSearch(search: string) {
    return apiClient.get("/addresses", { params: { filter: { search } } });
  }

  static timeLineHistory(project_id: any) {
    return apiClient.get("/get-timeline-histories", {
      params: { filter: { link_id: project_id, link_type: "project" }, paginate: 0 },
    });
  }

  static projectNotes(project_id: any) {
    return apiClient.get("/notes", {
      params: {
        filter: { belongable_id: project_id, belongable_type: "project" },
      },
    });
  }

  static projectInvoice(project_id: any) {
    return apiClient.get(`/projects/${project_id}/invoice`);
  }

  static projectCampaigns(project_id: any) {
    return apiClient.get("/lead-campaign-emails", {
      params: { filter: { project_id: project_id } },
    });
  }

  static getProjectCampaigns(id: any, project_id: any) {
    return apiClient.get(`/lead-campaign-emails/${id}/get-reminders`, {
      params: { filter: { project_id: project_id } },
    });
  }

  static addProjectNotes(data: any) {
    return apiClient.post("/notes", data);
  }

  static editProjectNotes(note_id: any, data: any) {
    return apiClient.put(`/notes/${note_id}`, data);
  }

  static modifyProject(project_id: any, data: any) {
    return apiClient.put(`/projects/${project_id}/project-update`, data);
  }

  static sendProposal(project_id: any) {
    return apiClient.post(`/projects/${project_id}/send-proposal`);
  }

  static projectInvoicePdf(invoice_id: any) {
    return apiClient.get(`/public/project-invoices/${invoice_id}/preview-pdf`, { responseType: 'blob' });
  }

  static getProjectStatusList() {
    return apiClient.get(`/get-project-status-listing`);
  }
}
