/* eslint-disable object-curly-newline */
import React from "react";
import { Box, Button, Typography, Fade, Modal } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import classes from "./ModalVideo.module.scss";
import { ErrorButton } from "components/Ui/Button/Button";

export default function ModalVideo({ open, close, video }: any) {
  const handleClose = () => {
    close(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box className={classes.paperModal}>
          <Box onClick={() => handleClose()} className={classes.close}>
            <MdOutlineClose size={20} />
          </Box>
          <iframe
            width="500"
            height="300"
            src={video}
            style={{ border: 0 }}
          ></iframe>
        </Box>
      </Fade>
    </Modal>
  );
}
