import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import toggleSwitchIcon from 'assets/toggle_switch.svg';
import userIcon from 'assets/user_icon.svg';

interface IFWorkState {
  meta: any;
  metaAdmin: any;
  metaConsultant: any;
  metaInstaller: any;
  metaStoreOwner: any;
  refresh: any;
  teamTabs: any;
  activeTab: any;
  members: any,
  userType: any
}

const initialState: IFWorkState = {
  refresh: false,
  meta: null,
  metaAdmin: null,
  metaConsultant: null,
  metaInstaller: null,
  metaStoreOwner: null,
  activeTab: { name: "Team Members", key: 0, selected: false, image: null },
  teamTabs: [
    { name: "Team Members", key: 0, selected: false, image: userIcon },
    { name: "Team Permissions", key: 1, selected: false, image: toggleSwitchIcon },
  ],
  members: {},
  userType: []
};

export const teamReducer = createSlice({
  name: "works",
  initialState,
  reducers: {
    refreshRequest: (state) => {
      state.refresh = !state.refresh;
    },
    selectTabs: (state, action: PayloadAction<any>) => {
      const key = action.payload;
      const index = state.teamTabs.findIndex((item: any) => item.key === key);
      if (index >= 0) {
        state.activeTab = state.teamTabs[index];
        state.teamTabs = state.teamTabs.map((item: any, i: any) => ({
          ...item,
          selected: item.key === key ? true : false,
        }));
      } else {
        state.activeTab = state.teamTabs[0];
        state.teamTabs[0].selected = true;
      }
    },
    setTeamMembers: (state, action: PayloadAction<any>) => {
      const members: any = {}
      const memberData = action.payload.data.map((data: any) => {
        return {
          ...data,
          name: `${data.first_name} ${data.last_name}`,
          joined: data.email_verified
        }
      })
      for (const member of memberData) {
        if (!state.userType.includes(member.role)) {
          state.userType.push(member.role)
        }
        if (members[member.role]) {
          members[member.role].push(member)
        } else {
          members[member.role] = [member]
        }
      }
      state.members = members
    },
    setAdminMember: (state, action: PayloadAction<any>) => {
      const memberData = action.payload.data.map((data: any) => {
        return {
          ...data,
          name: `${data.first_name} ${data.last_name}`,
          joined: data.email_verified
        }
      })

      state.members.admin = memberData
      state.metaAdmin = action.payload.meta
    },
    setConsultantMember: (state, action: PayloadAction<any>) => {
      const memberData = action.payload.data.map((data: any) => {
        return {
          ...data,
          name: `${data.first_name} ${data.last_name}`,
          joined: data.email_verified
        }
      })
      state.members.consultant = memberData
      state.metaConsultant = action.payload.meta
    },
    setInstallerMember: (state, action: PayloadAction<any>) => {
      const memberData = action.payload.data.map((data: any) => {
        return {
          ...data,
          name: `${data.first_name} ${data.last_name}`,
          joined: data.email_verified
        }
      })

      state.members.installer = memberData
      state.metaInstaller = action.payload.meta
    },
    setStoreOwnerMember: (state, action: PayloadAction<any>) => {
      const memberData = action.payload.data.map((data: any) => {
        return {
          ...data,
          name: `${data.first_name} ${data.last_name}`,
          joined: data.email_verified
        }
      })

      state.members.store_owner = memberData
      state.metaStoreOwner = action.payload.meta
    }
  },
});

export const { 
  refreshRequest, 
  selectTabs, 
  setTeamMembers,
  setAdminMember,
  setConsultantMember,
  setInstallerMember,
  setStoreOwnerMember
} = teamReducer.actions;

export default teamReducer.reducer;
