import { Box, Typography } from "@mui/material";
import Highlights from "../Projects/Highlights";
import Style from "./Aboutus.module.scss";
// import EditIcon from "assets/leadProfile/edit.svg";

function AboutUs({ isPublic }: any) {
  return (
    <Box>
      <Highlights />
      <Box mt={2} className={Style.container}>
        <Box>
          <Typography variant='heading4' fontWeight={'600'} component={'h5'}>Blacked Out Tinting</Typography>
          <Typography variant='heading6' fontWeight={'500'}>
            Dedicated To Excellence
          </Typography>
        </Box>
        <Typography variant='bodyTextNormal' fontWeight={'500'} mt={'15px'} component={'p'}>
          At [Your Company Name], we're more than just a window tinting service.
          With a dedication to precision and a passion for quality, we've been
          transforming vehicle aesthetics and enhancing driver comfort for over
          a decade. Our expert team utilizes state-of-the-art materials and
          cutting-edge techniques to ensure flawless results every time. Whether
          you're looking to protect your interiors, boost privacy, or simply
          elevate your ride's style, trust in us to bring your vision to life."
        </Typography>
      </Box>
    </Box>
  );
}

export default AboutUs;
