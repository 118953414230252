import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getSafeValue } from "utils";
import InputTooltip from "../InputTooltip/InputTooltip";

function PriceTooltip({ position }: any) {
  const userData = useAppSelector((state) => state.auth.entities);
  const organizationTaxType = getSafeValue(userData, 'organisation.configuration.tax_type', '');
  const organisationName = getSafeValue(userData, 'organisation.name', '');
  const GetTextForTaxablePrice = () => {
    if (organizationTaxType !== 'no_tax_business') {
      return '(Inc. Tax)'
    }
    return '';
  };
  return (<Box display={'inline-block'}>
    <Box display={'flex'} alignItems={'center'} gap={'2px'} marginLeft={'2px'} marginRight={'2px'}>
      {GetTextForTaxablePrice()}
      <InputTooltip 
        position={position}
        text={<>All projects are set to add tax, default tax setting for {organisationName} are found in Settings - <Link to={'/settings/invoicing'} target="_blank">Invoices</Link></>} />
    </Box>
  </Box>
  );
}

export default PriceTooltip;
