import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
const styles = {
  circularProgress: {
    position: "fixed",
    textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  text: {
    marginTop: 8,
    opacity: 0.75,
  },
};

function Loading() {
  return (
    <Box sx={styles.circularProgress}>
      <CircularProgress size={90} thickness={1} color="secondary" />
      <Typography variant="subtitle1" sx={styles.text} gutterBottom>
        Authenticating...
      </Typography>
    </Box>
  );
}

export default Loading;
