import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { closeMenu } from "redux/reducers/uiReducer";
import { themeColor } from "theme/color";
import { constant } from "utils/constant";
import MenuContent from "utils/menu";
import SettingsContent from "utils/settings";
import style from "./SideMenu.module.scss";

const SideMenu = () => {
  const isOpen = useAppSelector((state) => state.ui.isOpen);
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;
  const [subMenu, setSubMenu] = useState<any>([]);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("lg"));
  const handleClose = () => {
    if (media) {
      dispatch(closeMenu());
    }
  };
  const handleMenuItem = () => {
    let index;
    if (pathName === "/") {
      index = 0;
    } else {
      index = MenuContent.findIndex((item) => pathName.includes(item.path));
    }
    if (index >= 0) {
      setSubMenu(MenuContent[index].child);
    }

    if (pathName.indexOf("settings") > 0) {
      setSubMenu(SettingsContent.child);
    }
  };
  useEffect(() => {
    handleMenuItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  const checkActiveHelper = (pathName: any, itemPath: any) => {
    return itemPath.replace("/", "") === pathName.split("/")[1];
  };

  const checkActive = (item: any) => {
    if (item.path === "/business" && pathName === "/") {
      return true;
    } else if (checkActiveHelper(pathName, item.path)) {
      return true;
    }
    return false;
  };

  const handleClick = (item: any) => {
    navigate(item.link);
  };

  return (
    <Box position={"relative"}>
      <Box
        className={style.sideBar}
        width={constant.sideMenu}
        bgcolor={themeColor.dark}
        top={`${constant.height}px`}
        left={media ? (isOpen ? 0 : -constant.sideMenu) : 0}
      >
        {MenuContent.map((item, index) => (
          <Box
            className={style.menuList}
            key={index}
            sx={{
              background: checkActive(item) ? item.background : "transparent",
            }}
            onClick={() => handleClick(item)}
          >
            <Box display={'flex'} gap={'5px'} flexDirection={'column'} alignItems={'center'}>
              <img src={item.icon} width={30} alt={item.name} />
              <Typography
                variant="bodyText11px"
                fontWeight={600}
                color={themeColor.white}
              >{item.name}</Typography>
            </Box>
          </Box>
        ))}
        <Box
          className={style.settingsMenu}
          sx={{
            background: checkActive(SettingsContent)
              ? SettingsContent.background
              : "transparent",
          }}
          onClick={() => handleClick(SettingsContent)}
        >
          <Box display={'flex'} justifyContent={'center'}>
            <img
              src={SettingsContent.icon}
              width={30}
              alt={SettingsContent.name}
              className={checkActive(SettingsContent) ? style.invert : ""}
            />
          </Box>
        </Box>
      </Box>
      <Box
        className={style.sideMenuBar}
        top={`${constant.height}px`}
        left={`${media ? (isOpen ? constant.sideMenu : 0) : constant.sideMenu
          }px`}
        // media ? (isOpen ? 0 : -constant.sideMenu) : 0
        width={isOpen ? constant.menuWidth : 0}
        bgcolor={themeColor.bodyBg}
      >
        <Box className={style.sideMenuList}>
          {subMenu.length > 0 ? (
            subMenu.map((item: any, index: any) => (
              <NavLink
                to={item.link}
                className={({ isActive }) => isActive ? style.linkActive : style.link}
                key={index}
                onClick={() => handleClose()}
              >
                {item.name}
              </NavLink>
            ))
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default SideMenu;
